import React, { ReactElement } from "react";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { EvolTooltip } from "components";
import * as S from "./style";

type Data = [{ [key: string]: string | number }];

const margin = 200;

const Chart = ({
  data,
  width,
}: {
  data: Data;
  width: number;
}): ReactElement => {
  function setRadius(
    type: "left" | "right"
  ): number | [number, number, number, number] {
    const data01 = data[0]["PERCENT-1"];
    const data02 = data[0]["PERCENT-2"];

    if (!data01 || !data02) return 16;
    if (type === "left") return [16, 0, 0, 16];
    return [0, 16, 16, 0];
  }

  function setScaleBox(number: number): ReactElement[] {
    const repeater = new Array(number).fill(undefined);

    return repeater.map(() => (
      <S.ScaleBox width={width === 0 ? 300 : width - margin} />
    ));
  }

  return (
    <div>
      <ResponsiveContainer
        height={100}
        width={width === 0 ? 300 : width - margin}
      >
        <BarChart data={data} layout="vertical">
          <XAxis type="number" hide />
          <YAxis dataKey="NAME" type="category" hide />
          <Tooltip
            cursor={false}
            contentStyle={{
              fontWeight: "bold",
              borderRadius: 3,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            labelStyle={{ marginRight: 5 }}
            formatter={(value: any, name: any) => [
              `${name}: ${Math.round(value)} %`,
              parseFloat(name),
            ]}
            content={<EvolTooltip />}
            viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
          />
          <Bar
            barSize={16}
            dataKey="PERCENT-1"
            stackId="a"
            fill="#f67e00"
            radius={setRadius("left")}
          />
          <Bar
            barSize={16}
            dataKey="PERCENT-2"
            stackId="a"
            fill="#d92231"
            radius={setRadius("right")}
          />
        </BarChart>
      </ResponsiveContainer>
      <S.Scale width={width === 0 ? 300 : width - margin}>
        {setScaleBox(6)}
      </S.Scale>
      <S.Percentage width={width === 0 ? 300 : width - margin}>
        <S.Value>0%</S.Value>
        <S.Value>100%</S.Value>
      </S.Percentage>
    </div>
  );
};

export default Chart;
