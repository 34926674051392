import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { KpiProps } from "types/kpi";
import { AppContext } from "providers";
import { lightTheme } from "theme/theme";
import * as S from "../style";

const ConnectivityPercent = () => {
  const { kpi }: KpiProps = useContext(AppContext);
  const { t } = useTranslation();
  const [connectivityPercent, setConnectivityPercent] = useState<number>(0);
  const [unConnectedPercent, setUnConnectedPercent] = useState<number>(0);

  useEffect(() => {
    if (kpi?.connectivityPercent?.data) {
      setConnectivityPercent(
        Math.round(kpi?.connectivityPercent?.data?.PERCENT * 10) / 10
      );
      setUnConnectedPercent(
        Math.round((100 - kpi?.connectivityPercent?.data?.PERCENT) * 10) / 10
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpi?.connectivityPercent?.data]);

  return (
    <S.ProgressContainer>
      <S.ProgressContent>
        <S.ProgressValue>
          <b>{connectivityPercent || 0}% </b>
          {t("kpis.connectivity.percent")}
        </S.ProgressValue>
        <S.ProgressBar
          percent={connectivityPercent || 0}
          strokeColor={lightTheme.color.success}
        />
      </S.ProgressContent>
      <S.ProgressContent>
        <S.ProgressValue warningColor>
          <b>{unConnectedPercent || 0}% </b>
          {t("kpis.connectivity.percentNotConnected")}
        </S.ProgressValue>
        <S.ProgressBar
          percent={unConnectedPercent || 0}
          strokeColor={lightTheme.color.danger}
        />
      </S.ProgressContent>
    </S.ProgressContainer>
  );
};

export default ConnectivityPercent;
