import React, { FC, useContext, useEffect, useState } from "react";
import moment from "moment";
import { Col, Row, Skeleton } from "antd";
import { useTranslation } from "react-i18next";

import { useFormatter, useFlagger } from "hooks";
import { KpiProps } from "types/kpi";
import { AppContext } from "providers";
import { CustomTable, Toner } from "components";
import A4 from "assets/icons/a4.svg";
import A3 from "assets/icons/a3.svg";
import * as S from "./style";

const Product: FC = () => {
  const { flagger, countryCode } = useFlagger();
  const { t } = useTranslation();
  const { formatValue } = useFormatter();
  const { kpi }: KpiProps = useContext(AppContext);
  const { config, partner } = useContext(AppContext);
  const [paper, setPaper] = useState("a3");
  const [toner, setToner] = useState("kpis.mix.toner.status.in");
  const [data, setData] = useState<
    Array<never | { [key: string]: string | number | string[] }>
  >([]);

  const purchasedProductsCol = [
    {
      title: t("kpis.mix.top.col.product"),
      dataIndex: "PRODUCT_LINE",
      render: (value: string[] | undefined) => {
        return (
          <div>
            {value &&
              value.map((u: string, i: number) => (
                <p key={i.toString()} style={{ margin: 0 }}>
                  {u}
                </p>
              ))}
          </div>
        );
      },
    },
    {
      title: t("kpis.mix.top.col.units"),
      dataIndex: "UNIT",
    },
    {
      title: t("kpis.mix.top.col.purchases"),
      dataIndex: "PURCHASE",
    },
    {
      title: t("kpis.mix.top.col.share"),
      dataIndex: "PERCENT",
    },
  ];

  const handleSelectPaper = () => {
    setPaper(paper === "a4" ? "a3" : "a4");
  };
  const handleSelectToner = () => {
    setToner(
      toner === "kpis.mix.toner.status.out"
        ? "kpis.mix.toner.status.in"
        : "kpis.mix.toner.status.out"
    );
  };

  useEffect(() => {
    if (kpi && kpi.product && kpi.product.data) {
      const formattedData: { [key: string]: string | number | string[] }[] = [];
      kpi.product.data.forEach((el: any, i: number) => {
        if (Math.round(parseFloat(el.UNIT) + parseFloat(el.PURCHASE)) > 0) {
          formattedData.push({
            PRODUCT_TOP: el.PRODUCT_TOP || i.toString(),
            PRODUCT_LINE: [el.PRODUCT_LINE_1, el.PRODUCT_LINE_2],
            UNIT: Math.round(parseFloat(el.UNIT)),
            PURCHASE: formatValue(
              parseFloat(el.PURCHASE) > 999
                ? parseFloat(`${Math.round(Math.round(el.PURCHASE) / 1000)}000`)
                : el.PURCHASE
            ),
            PERCENT: `${Math.round(parseFloat(el.PERCENT))}%`,
          });
        }
      });
      setData(formattedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpi]);

  return (
    <Row gutter={[16, 16]}>
      <Col lg={8} xs={24}>
        <S.Category>{t("kpis.mix.product.title")}</S.Category>
        <S.SelectGroup>
          <S.SelectPaper
            active={paper === "a3"}
            onClick={() => handleSelectPaper()}
          >
            {t("kpis.mix.a3")}
          </S.SelectPaper>
          <S.SelectPaper
            secondary
            active={paper === "a4"}
            onClick={() => handleSelectPaper()}
          >
            {t("kpis.mix.a4")}
          </S.SelectPaper>
        </S.SelectGroup>
        {!kpi?.family && (
          <Skeleton.Input
            style={{ width: 500, height: 280 }}
            active
            size="large"
          />
        )}
        {kpi?.family?.data && kpi?.total && (
          <CustomTable
            icon={paper === "a4" ? A4 : A3}
            color={paper === "a4" ? "#f67e00" : "#27800e"}
            data={
              paper === "a3"
                ? [
                    kpi?.family?.data.find(
                      (el: any) => el.FAMILY === "a3_color"
                    ),
                    kpi?.family?.data.find(
                      (el: any) => el.FAMILY === "a3_mono"
                    ),
                    kpi?.total?.data.find((el: any) => el.FAMILY === "A3"),
                  ]
                : [
                    kpi?.family?.data?.find(
                      (el: any) => el.FAMILY === "a4_color"
                    ),
                    kpi?.family?.data?.find(
                      (el: any) => el.FAMILY === "a4_mono"
                    ),
                    kpi?.total?.data?.find((el: any) => el.FAMILY === "A4"),
                  ]
            }
          />
        )}
        <S.Country small>
          <S.Flag
            alt={countryCode || "flag"}
            src={flagger(partner?.config?.country || config?.user?.country)}
          />
          <S.Info>
            {kpi?.countryProduct?.data[0].JOB === "CCSS"
              ? t("kpis.mix.country.ccss.family", {
                  year:
                    kpi?.countryProduct?.data[0]?.YEAR || moment().year() - 1,
                  percentA4:
                    Math.round(
                      kpi?.countryProduct?.data[0]?.COUNTRY_PERCENT_A4
                    ) || 0,
                  percentA3:
                    Math.round(
                      kpi?.countryProduct?.data[0]?.COUNTRY_PERCENT_A3
                    ) || 0,
                })
              : t("kpis.mix.country.dtp.family", {
                  year:
                    kpi?.countryProduct?.data[0]?.YEAR || moment().year() - 1,
                  percentA4:
                    Math.round(
                      kpi?.countryProduct?.data[0]?.COUNTRY_PERCENT_A4
                    ) || 0,
                  percentA3:
                    Math.round(
                      kpi?.countryProduct?.data[0]?.COUNTRY_PERCENT_A3
                    ) || 0,
                })}
          </S.Info>
        </S.Country>
      </Col>
      <Col lg={8} xs={24}>
        <S.Category>{t("kpis.mix.top.title")}</S.Category>
        <S.CompactTable
          size="small"
          pagination={false}
          rowKey="PRODUCT_TOP"
          columns={purchasedProductsCol}
          dataSource={data}
        />
      </Col>
      <S.CustomSection lg={8} xs={24}>
        <S.Category>{t("kpis.mix.toner.title")}</S.Category>
        <S.SelectGroup>
          <S.SelectToner
            active={toner === "kpis.mix.toner.status.in"}
            onClick={() => handleSelectToner()}
          >
            {t("kpis.mix.in")}
          </S.SelectToner>
          <S.SelectToner
            secondary
            active={toner === "kpis.mix.toner.status.out"}
            onClick={() => handleSelectToner()}
          >
            {t("kpis.mix.out")}
          </S.SelectToner>
        </S.SelectGroup>
        {!kpi?.toner && (
          <Skeleton.Input
            style={{
              width: 500,
              height: 280,
            }}
            active
            size="large"
          />
        )}
        {kpi?.toner?.data && (
          <Toner
            data={
              toner === "kpis.mix.toner.status.in"
                ? kpi?.toner?.data[0]
                : kpi?.toner?.data[1]
            }
            color={toner === "kpis.mix.toner.status.in" ? "#9b2583" : "#1a6fc5"}
          />
        )}
        <S.Country small>
          <S.Flag
            alt={countryCode || "flag"}
            src={flagger(partner?.config?.country || config?.user?.country)}
          />
          <S.Info>
            {kpi?.countryToner?.data[0].JOB === "CCSS"
              ? t("kpis.mix.country.ccss.toner", {
                  year: kpi?.countryToner?.data[0]?.YEAR || moment().year() - 1,
                  percentIn:
                    Math.round(
                      kpi?.countryToner?.data[0]?.COUNTRY_PERCENT_IN
                    ) || 0,
                  percentOut:
                    Math.round(
                      kpi?.countryToner?.data[0]?.COUNTRY_PERCENT_OUT
                    ) || 0,
                })
              : t("kpis.mix.country.dtp.toner", {
                  year: kpi?.countryToner?.data[0]?.YEAR || moment().year() - 1,
                  percentIn:
                    Math.round(
                      kpi?.countryToner?.data[0]?.COUNTRY_PERCENT_IN
                    ) || 0,
                  percentOut:
                    Math.round(
                      kpi?.countryToner?.data[0]?.COUNTRY_PERCENT_OUT
                    ) || 0,
                })}
          </S.Info>
        </S.Country>
      </S.CustomSection>
    </Row>
  );
};

export default Product;
