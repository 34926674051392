import React, { FC } from "react";
import { Spin } from "antd";
import "antd/dist/antd.css";

import { styled } from "theme/theme";

interface LoaderProps {
  indicator: React.ReactNode;
}

export const Loader: FC<LoaderProps> = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #d92231;
`;

export const Wrapper = styled.div`
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: #fff;
`;
