import { FC } from "react";
import { Layout, Typography, Input, Checkbox, Button, Modal } from "antd";
import { TitleProps } from "antd/lib/typography/Title";
import { LayoutProps } from "antd/lib/layout";
import { InputProps } from "antd/lib/input";
import { TextProps } from "antd/lib/typography/Text";
import { ButtonProps } from "antd/lib/button";
import { CheckboxProps } from "antd/lib/checkbox";
import { ModalProps } from "antd/lib/modal";

import { styled } from "theme/theme";

interface Props {
  isOpen: boolean;
}

interface LinkProps extends TextProps {
  onClick?: () => void;
}

export const Centered: FC<LayoutProps> = styled(Layout)`
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 100vh;
  background: ${(props) => props.theme.color.lightAccent};
`;

export const Container: FC<LayoutProps> = styled(Layout)`
  background: ${(props) => props.theme.color.lightAccent};
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 90px 105px;
  transition: 300ms;
  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
  }
`;

export const Brand = styled.img`
  object-fit: contain;
  width: 235px;
  height: 40px;
`;

const { Title, Text } = Typography;

export const Subtitle: FC<TitleProps> = styled(Title)`
  color: ${(props) => props.theme.color.alternative} !important;
  font-weight: 200 !important;
  text-align: center;
`;

export const Email: FC<InputProps> = styled(Input)`
  border: none;
  padding: 12px 20px;
  max-width: 480px;
  box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.16);
  margin: 30px 0;
  outline: none;
  background-color: ${(props) => props.theme.color.lightShade};
  color: ${(props) => props.theme.color.info};
`;

export const Recover: FC<InputProps> = styled(Input)`
  padding: 12px 20px;
  max-width: 480px;
  margin: 30px 0;
  outline: none;
  border: 1px solid ${(props) => props.theme.color.lightShade};
  background-color: ${(props) => props.theme.color.lightAccent};
  color: ${(props) => props.theme.color.info};
  :hover {
    border: 1px solid ${(props) => props.theme.color.info};
  }
  :active {
    outline: none;
  }
`;

export const Password: FC<InputProps> = styled(Input.Password)`
  border: none;
  padding: 12px 20px;
  max-width: 480px;
  box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.16);
  background-color: ${(props) => props.theme.color.lightShade};
  & input {
    background-color: ${(props) => props.theme.color.lightShade};
    color: ${(props) => props.theme.color.info};
  }
`;

export const More = styled.div`
  margin: 40px 0;
  display: flex;
  max-width: 480px;
  width: 90%;
  justify-content: space-around;
  align-items: center;
`;

export const Action: FC<LinkProps> = styled(Text)`
  font-weight: bold;
  text-decoration-line: underline;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.color.info};
`;

export const Recovery: FC<ModalProps> = styled(Modal)`
  & .ant-btn {
    :hover {
      color: ${(props) => props.theme.color.info};
      border-color: ${(props) => props.theme.color.info};
    }
  }
  & .ant-btn-primary {
    background: ${(props) => props.theme.color.mainBrand};
    border-color: ${(props) => props.theme.color.mainBrand}!important;
    color: ${(props) => props.theme.color.lightAccent}!important;
  }
`;

export const Check: FC<CheckboxProps> = styled(Checkbox)`
  color: ${(props) => props.theme.color.info};
`;

export const Submit: FC<ButtonProps> = styled(Button)<Props>`
  border: none;
  background: ${(props) => props.theme.color.primary};
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin-top: 30px;
  max-width: 380px;
  width: 100%;
  transition: 300ms;
  :hover {
    background: ${(props) => props.theme.color.mainBrand};
    border: none;
    color: white;
  }
  :focus {
    background: ${(props) => props.theme.color.primary};
    color: white;
  }
`;

export const PanName: FC<TitleProps> = styled(Title)`
  font-size: 40px;
  margin-top: 0;
  font-weight: 300 !important;
  text-align: center;
  color: ${(props) => props.theme.color.info} !important;
`;

export const Filters: FC = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  width: 100%;
  margin: 50px 0;
`;

export const Reset: FC<ButtonProps> = styled(Button)`
  text-decoration-line: underline;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.color.info};
  text-transform: uppercase;
  :hover {
    color: ${(props) => props.theme.color.info};
    border-color: ${(props) => props.theme.color.info};
  }
  :active {
    color: ${(props) => props.theme.color.info} !important;
    border-color: ${(props) => props.theme.color.info};
  }
  :focus {
    color: ${(props) => props.theme.color.info} !important;
    border-color: ${(props) => props.theme.color.info};
  }
`;

export const Spacer = styled.div`
  height: 35px;
  width: 100%;
`;

export const Rules = styled.p`
  color: ${(props) => props.theme.color.info};
  font-style: italic;
  padding: 10px 0;
  max-width: 472px;
  text-align: center;
`;
