import { proc } from "./api-common";

type Partner = { queries: string; name: string; config?: any } | null;

const procList = (partner: Partner) => [
  { ...proc.performance.sales, partner },
  { ...proc.performance.equipments, partner },
  { ...proc.performance.country.sales, partner },
  { ...proc.performance.country.equipments, partner },
  { ...proc.digitalPerformance.revenue, partner },
  { ...proc.digitalPerformance.licenses, partner },
  { ...proc.digitalPerformance.topsApps, partner },
  { ...proc.digitalPerformance.topsSoftwares, partner },
  { ...proc.support.standard, partner },
  { ...proc.support.promotion, partner },
  { ...proc.support.bids, partner },
  { ...proc.support.top, partner },
  { ...proc.mix.family, partner },
  { ...proc.mix.total, partner },
  { ...proc.mix.top, partner },
  { ...proc.mix.toner, partner },
  { ...proc.mix.country.product, partner },
  { ...proc.mix.country.toner, partner },
  { ...proc.mif.base, partner },
  { ...proc.mif.xpps, partner },
  { ...proc.mif.asp, partner },
  { ...proc.mif.trends, partner },
  { ...proc.eos.values, partner },
  { ...proc.eos.percent, partner },
  { ...proc.mif.versus, partner },
  { ...proc.market.color, partner },
  { ...proc.market.prod, partner },
  { ...proc.market.segment, partner },
  { ...proc.connectivity.values, partner },
  { ...proc.connectivity.percent, partner },
];

export default procList;
