import React from "react";
import i18n from "i18next";

import DownloadButton from "./style";

const PDF = { DOWNLOAD: "DOWNLOAD", SELECTION: "SELECTION", VIEW: "VIEW" };

type PropsType = { id: number; state: any };

const Export = ({ id, state }: PropsType) => {
  return (
    <div>
      <DownloadButton onClick={() => state([id, PDF.VIEW])}>
        {i18n.t("business.preview")}
      </DownloadButton>
      <DownloadButton onClick={() => state([id, PDF.DOWNLOAD])}>
        {i18n.t("business.download")}
      </DownloadButton>
    </div>
  );
};

export default Export;
