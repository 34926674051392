import React, { FC, useContext, useEffect } from "react";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";

import { Hero, Dashboard } from "components";
import { AppContext } from "providers";
import Global from "theme/global";

const Home: FC = () => {
  const history = useHistory();
  const { mounted, mounting, state } = useContext(AppContext);

  useEffect(() => {
    if (!mounted) mounting(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  React.useEffect(() => {
    localStorage.setItem("from", history.location.pathname);
  }, [history]);

  if (state.loading) return <div>loading...</div>;
  return (
    <Global.Container>
      <Global.Wallpaper />
      <Global.Render>
        <Hero />
        <Global.Wrapper transparent>
          <Row gutter={[36, 36]}>
            <Col xl={24} lg={24}>
              <Dashboard />
            </Col>
          </Row>
        </Global.Wrapper>
      </Global.Render>
    </Global.Container>
  );
};

export default Home;
