import React from "react";
import { Col } from "antd";
import { useTranslation } from "react-i18next";
import * as S from "../style";
import { getColumns } from "../utils";

type TTableProps = {
  loading: boolean;
  dataSource: any;
  vt: any;
};

const scrollConfig = {
  y: 450,
};

const Table = ({ loading, dataSource, vt }: TTableProps) => {
  const { t } = useTranslation();

  const columns = getColumns(t);

  return (
    <div>
      <Col xs={24}>
        <S.Results
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          scroll={{
            y: scrollConfig.y,
            scrollToFirstRowOnChange: true,
          }}
          components={vt}
          pagination={false}
        />
      </Col>
    </div>
  );
};

export default Table;
