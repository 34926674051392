import fr from "assets/flags/fr.png";
import at from "assets/flags/at.png";
import uk from "assets/flags/uk.png";
import it from "assets/flags/it.png";
import nl from "assets/flags/nl.png";
import dk from "assets/flags/dk.png";
import be from "assets/flags/be.png";
import no from "assets/flags/no.png";
import gr from "assets/flags/gr.png";
import ch from "assets/flags/ch.png";
import fi from "assets/flags/fi.png";
import ge from "assets/flags/ge.png";
import pt from "assets/flags/pt.png";
import sw from "assets/flags/sw.png";
import es from "assets/flags/es.png";

function renderFlag(country: string | null) {
  if (country === "FRANCE") return fr;
  if (country === "SPAIN") return es;
  if (country === "en") return uk;
  if (country === "AUSTRIA") return at;
  if (country === "ITALY") return it;
  if (country === "NETHERLANDS") return nl;
  if (country === "DENMARK") return dk;
  if (country === "BELGIUM") return be;
  if (country === "NORWAY") return no;
  if (country === "GREECE") return gr;
  if (country === "SWITZERLAND") return ch;
  if (country === "FINLAND") return fi;
  if (country === "GERMANY") return ge;
  if (country === "PORTUGAL") return pt;
  if (country === "SWEDEN") return sw;
  if (country === "GREENLAND") return dk;
  if (country === "JERSEY") return uk;
  if (country === "SAN_MARINO" || country === "SAN MARINO") return it;
  if (country === "LUXEMBOURG") return be;
  return uk;
}

export default function useFlagger(): any {
  const countryCode: string | null = localStorage.getItem("i18nextLng");
  const flagger = (country: string | undefined | null = countryCode) => {
    return renderFlag(country);
  };
  return { flagger, countryCode } as const;
}
