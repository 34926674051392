import { FC } from "react";
import { Button, Checkbox, Progress, Select, Table, Typography } from "antd";
import { TableProps } from "antd/lib/table";
import { ButtonProps } from "antd/lib/button";
import { CheckboxGroupProps } from "antd/lib/checkbox";
import { SelectProps } from "antd/lib/select";
import { IconBaseProps } from "react-icons";
import { BiCaretDownCircle, BiX } from "react-icons/bi";
import { ProgressProps } from "antd/lib/progress";
import { TextProps } from "antd/lib/typography/Text";

import { styled } from "theme/theme";

const { Text } = Typography;

interface Props {
  isOpen: boolean;
}

interface LinkProps extends TextProps {
  onClick?: () => void;
}

interface SelectorProps extends SelectProps<any> {
  spaced?: number;
}

interface ResultsProps extends TableProps<any> {
  dataSource: any;
}

interface SubmitProps extends ButtonProps {
  ref?: any;
}

interface CheckProps extends CheckboxGroupProps {
  value: any;
}

export const Results: FC<ResultsProps> = styled(Table)`
  margin-top: 20px;
  & .ant-table-thead > tr > th {
    font-weight: 600;
  }
  & .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 2px 16px;
  }
  & .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: rgba(0, 0, 0, 0.03);
  }
  & .ant-checkbox:hover {
    outline: ${(props) => props.theme.color.info} !important;
    border-color: ${(props) => props.theme.color.info} !important;
  }
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.color.info};
    border-color: ${(props) => props.theme.color.info};
  }
  & .ant-pagination-item-active {
    border-color: ${(props) => props.theme.color.info};
    a,
    a:hover {
      color: ${(props) => props.theme.color.info};
    }
  }
  & .ant-pagination-item:focus,
  .ant-pagination-item:hover,
  .ant-pagination-item a:hover {
    border-color: ${(props) => props.theme.color.info};
    color: ${(props) => props.theme.color.info};
  }
  & .ant-spin-dot-item {
    background-color: ${(props) => props.theme.color.mainBrand};
  }
`;

export const Pagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const Submit: FC<SubmitProps> = styled(Button)<Props>`
  border: none;
  background: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.lightAccent}!important;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  :hover {
    background: ${(props) => props.theme.color.mainBrand};
    border: none;
    color: white;
  }
  :focus {
    background: ${(props) => props.theme.color.primary};
    color: white;
  }
`;

export const CheckWrapper: FC = styled.div`
  padding: 8px;
`;

export const CheckboxGroup: FC<CheckProps> = styled(Checkbox.Group)`
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const Selector: FC<SelectorProps> = styled(Select)`
  min-width: 138px;
  margin: 0 ${(custom: SelectorProps) => custom.spaced || 15}px;
  color: ${(props) => props.theme.color.info};
  & * {
    width: auto !important;
    text-transform: capitalize;
  }
  & span {
    font-weight: 700;
  }
  & *:hover {
    color: ${(props) => props.theme.color.info};
    border-color: ${(props) => props.theme.color.info}!important;
  }
  & *:focus {
    outline: none !important;
  }
`;

export const DropdownIcon: FC<IconBaseProps> = styled(BiCaretDownCircle)`
  margin-top: -4px;
  color: ${(props) => props.theme.color.info};
  font-size: 20px;
`;

export const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ProgressContent = styled.div`
  width: 100%;
  margin-top: -48px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ProgressValue = styled.h3`
  width: 100%;
  margin-bottom: -10px;
  text-align: center;
  color: ${(props) => props.theme.color.primary};
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  & b {
    font-size: 20px;
  }
`;

export const ProgressBar: FC<ProgressProps> = styled(Progress)`
  width: 380px;
  margin: 0 auto;
  & .ant-progress-text {
    display: none;
  }
  .ant-progress-outer {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
`;

export const Selected: FC<LinkProps> = styled(Text)`
  font-size: 16px;
  margin-left: 15px;
  font-weight: 300 !important;
  text-align: center;
  color: ${(props) => props.theme.color.mainBrand} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize !important;
`;

export const CloseIcon: FC<IconBaseProps> = styled(BiX)`
  color: ${(props) => props.theme.color.mainBrand};
  margin-right: 5px;
`;

export const Collapse = styled.div`
  display: flex;
  align-items: center;
`;

export const TabIcon = styled.img`
  margin-right: 8px;
`;

export const TabCustom = styled.div`
  display: flex;
  align-items: center;
  & p {
    margin: 1rem 0;
  }
`;
