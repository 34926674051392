import React, { FC, useContext, useEffect } from "react";

import { AppContext } from "providers";
import { AuthForm } from "components";

const Auth: FC = () => {
  const { token } = useContext(AppContext);

  useEffect(() => {
    localStorage.getItem("token");
  }, [token]);

  return <AuthForm />;
};

export default Auth;
