import { FC } from "react";
import { Col, Table, Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import { TitleProps } from "antd/lib/typography/Title";

import { styled } from "theme/theme";

const { Text } = Typography;

interface CountryProps extends TitleProps {
  small?: boolean;
}

type SelectProps = {
  active: boolean;
  secondary?: boolean;
};

export const SelectGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  & div {
    border: 1px solid ${(props) => props.theme.color.lightShade};
    :first-of-type {
      border-right-width: 0 !important;
      background-color: #27800e;
      color: ${(props) => props.theme.color.white};
    }
    :last-of-type {
      border-left-width: 0 !important;
      color: ${(props) => props.theme.color.lightShade};
      background-color: ${(props) => props.theme.color.lightAccent};
    }
  }
`;

export const Select = styled.div`
  width: 110px;
  padding: 8px 0;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
`;

export const SelectPaper = styled(Select)`
  background-color: ${(is: SelectProps) =>
    // eslint-disable-next-line no-nested-ternary
    is.active
      ? is.secondary
        ? "#f67e00"
        : "#27800e"
      : (props) => props.theme.color.lightAccent}!important;
  color: ${(is: SelectProps) =>
    is.active
      ? (props) => props.theme.color.white
      : (props) => props.theme.color.info}!important;
  border-width: ${(is: SelectProps) => (is.active ? 0 : "1px")}!important;
`;

export const SelectToner = styled(Select)`
  background-color: ${(is: SelectProps) =>
    // eslint-disable-next-line no-nested-ternary
    is.active
      ? is.secondary
        ? "#1a6fc5"
        : "#9b2583"
      : (props) => props.theme.color.lightAccent}!important;
  color: ${(is: SelectProps) =>
    is.active
      ? (props) => props.theme.color.white
      : (props) => props.theme.color.info}!important;
  border-width: ${(is: SelectProps) => (is.active ? 0 : "1px")}!important;
`;

export const CompactTable: FC<any> = styled(Table)`
  margin-top: 10px;
  & tr:first-child {
    background: ${(props) => props.theme.color.lightShade};
  }
  & tr:nth-child(2n + 3) {
    background: ${(props) => props.theme.color.lightShade};
  }
  & th {
    font-weight: bold !important;
    text-align: left !important;
    padding: 8px 4px !important;
  }
`;

export const Category: FC<TextProps> = styled(Text)`
  font-size: 14px;
  margin-left: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.info};
`;

export const Country: FC<CountryProps> = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.color.lightShade};
  margin: 20px 15px;
  border-radius: 60px 0 0 60px;
  height: ${(is: CountryProps) => (is.small ? "50px" : "60px")};
  justify-content: ${(is: CountryProps) =>
    is.small ? "flex-start" : "space-between"};
  & .ant-typography {
    font-size: ${(is: CountryProps) => (is.small ? "11px" : "18px")};
  }
  & img {
    width: ${(is: CountryProps) => (is.small ? "50px" : "60px")};
    height: ${(is: CountryProps) => (is.small ? "50px" : "60px")};
  }
`;

export const Flag = styled.img`
  object-fit: fill;
  border-radius: 60px;
  background-color: ${(props) => props.theme.color.lightShade};
  color: ${(props) => props.theme.color.mainBrand};
  text-align: center;
`;

export const Info: FC<TextProps> = styled(Text)`
  font-weight: 300;
  margin-left: 10px;
  color: ${(props) => props.theme.color.info};
`;

export const CustomSection = styled(Col)`
  padding-top: 12px;
  margin-top: -12px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;
