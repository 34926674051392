import React, { FC, useState, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import routes, { navigation } from "router/routes";
import { AppContext } from "providers";
import logo from "assets/xerox_logo.svg";
import * as S from "./style";

const Header: FC = () => {
  const { config, revokeAccess } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { t } = useTranslation();

  const switchView = () => {
    if (location.pathname !== routes.ADMIN) history.push(routes.ADMIN);
    else history.push(routes.REDIRECT);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };
  if (
    location.pathname === routes.AUTH ||
    location.pathname === routes.REDIRECT
  )
    return null;

  return (
    <div
      style={
        location.pathname === routes.RESET
          ? { display: "none" }
          : { display: "block" }
      }
    >
      <S.Wrapper>
        <S.Nav>
          <Link to={routes.REDIRECT}>
            <S.Brand src={logo} />
          </Link>
          {location.pathname !== routes.ADMIN &&
            navigation.map(
              ({ name, root, authorization }) =>
                config?.authorizations?.pages[authorization] &&
                root !== routes.ADMIN && (
                  <Link key={name} to={root}>
                    <S.NavItem
                      style={location.pathname === root ? S.active : {}}
                      strong
                    >
                      {t(name)}
                    </S.NavItem>
                  </Link>
                )
            )}
        </S.Nav>
        <S.Button
          type="text"
          onClick={() => {
            showDrawer();
          }}
        >
          <S.BurgerIcon size={24} />
        </S.Button>
        <S.Account>
          <S.UserIcon size={24} />
          <S.Details>
            {config?.user ? (
              <>
                <S.UserName strong>
                  {config?.user?.firstName} {config?.user?.lastName}
                </S.UserName>
                <S.Row>
                  <S.ActionText onClick={() => revokeAccess()}>
                    {t("authentication.logout")}{" "}
                  </S.ActionText>
                  {config?.authorizations?.pages?.admin && (
                    <S.ActionText onClick={() => switchView()}>
                      <S.Switch>{t("global.switch")}</S.Switch>
                    </S.ActionText>
                  )}
                </S.Row>
              </>
            ) : (
              <S.ActionText onClick={() => revokeAccess()}>
                {t("authentication.login")}
              </S.ActionText>
            )}
          </S.Details>
        </S.Account>
      </S.Wrapper>
      <S.Drawer
        bodyStyle={{ padding: "20px 0" }}
        placement="left"
        closable={false}
        onClose={onClose}
        visible={drawerVisible}
        key="left"
      >
        <S.DrawerContainer>
          <S.DrawerNav>
            {navigation.map(
              ({ name, root, authorization }) =>
                config?.authorizations?.pages[authorization] && (
                  <Link key={name} to={root} onClick={() => onClose()}>
                    <S.DrawerItem strong>{t(name)}</S.DrawerItem>
                  </Link>
                )
            )}
          </S.DrawerNav>
          <S.DrawerAccount>
            <S.UserIcon size={24} />
            <S.Details>
              {config?.user ? (
                <>
                  <S.UserName strong>
                    {config?.user.firstName} {config?.user.lastName}
                  </S.UserName>
                  <S.ActionText onClick={() => revokeAccess()}>
                    {t("authentication.logout")}
                  </S.ActionText>
                </>
              ) : (
                <S.ActionText onClick={() => history.push(routes.AUTH)}>
                  {t("authentication.login")}
                </S.ActionText>
              )}
            </S.Details>
          </S.DrawerAccount>
        </S.DrawerContainer>
      </S.Drawer>
    </div>
  );
};

export default Header;
