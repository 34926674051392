import { TFunction } from "i18next";

export const getColumns = (t: TFunction) => [
  {
    title: t("kpis.connectivity.tab.customer"),
    dataIndex: "company",
  },
  {
    title: t("kpis.connectivity.tab.product"),
    dataIndex: "product",
  },
  {
    title: t("kpis.connectivity.tab.serial"),
    dataIndex: "serial_number",
  },
  {
    title: t("kpis.connectivity.tab.connected"),
    dataIndex: "connected",
  },
  {
    title: t("kpis.connectivity.tab.managed"),
    dataIndex: "managed",
  },
  {
    title: t("kpis.connectivity.tab.segment"),
    dataIndex: "segment",
  },
];

type TableData = { [key: string]: string | number }[];

export const concatenateData = (newData: TableData, source: TableData) => {
  const obj = source.concat(newData);
  const withoutDuplicatedValues = obj.filter(
    (data: { [key: string]: string | number }, index: number, self: any) =>
      self.findIndex((v: any) => v.id === data.id) === index
  );
  return withoutDuplicatedValues;
};
