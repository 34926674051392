import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { Hero, Tabs as TabsContainer, Pane, KpisManager } from "components";
import { PanesProps } from "types/panes";
import Global from "theme/global";
import { AppContext } from "providers";

const panes: PanesProps = [
  {
    title: "kpis.performance.title_short",
    component: <Pane content="perform" title="kpis.performance.title" />,
  },
  {
    title: "kpis.support.title_short",
    component: <Pane content="support" title="kpis.support.title" />,
  },
  {
    title: "kpis.mix.title",
    component: <Pane content="product" title="kpis.mix.title" />,
  },
  {
    title: "kpis.digitalPerformance.title_short",
    component: (
      <Pane
        content="digitalPerformance"
        title="kpis.digitalPerformance.title"
      />
    ),
  },
  {
    title: "kpis.market.title",
    component: <Pane content="market" title="kpis.market.title" />,
  },
  {
    title: "kpis.mif.title",
    component: <Pane content="mif" title="kpis.mif.title" />,
  },
  {
    title: "kpis.asp.title",
    component: <Pane content="asp" title="kpis.asp.title" />,
  },
  {
    title: "kpis.eos.title",
    component: <Pane content="eos" title="kpis.eos.title" />,
  },
  {
    title: "kpis.connectivity.title",
    component: <Pane content="connectivity" title="kpis.connectivity.title" />,
  },
];

const mif = "kpis.mif.title";
const asp = "kpis.asp.title";
const eos = "kpis.eos.title";
const connectivity = "kpis.connectivity.title";

const Performance: FC = () => {
  const {
    mounted,
    mounting,
    config,
    updatePartner,
    setKpi,
    partner,
    setEosFilters,
  } = useContext(AppContext);
  const [table, setTable] = useState<
    { title: string; component: ReactElement | never }[]
  >([]);
  const [select, setSelect] = useState(
    config?.authorizations?.buttons?.switchCompany
  );
  const [switchAllowed, setSwitchAllowed] = useState(
    config?.authorizations?.buttons?.switchCompany
  );

  const filterTabs = (tabTitles: string[]) => {
    setTable(panes.filter((item) => !tabTitles.includes(item.title)));
  };

  useEffect(() => {
    if (!mounted) mounting(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  const toggleManager = () => {
    setSelect(true);
    updatePartner(null);
    setEosFilters(null);
    setKpi({});
  };
  const history = useHistory();

  useEffect(() => {
    if (partner?.config && partner?.from === "dashboard") {
      setKpi({});
      setSelect(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner]);

  // Dynamically setting ASP or MIF enabled
  useEffect(() => {
    localStorage.setItem("from", history.location.pathname);
    setSwitchAllowed(config?.authorizations?.buttons?.switchCompany);
    if (partner?.config) {
      const keys = partner?.config?.asp ? [mif, connectivity] : [asp];
      filterTabs(keys);
    } else {
      const keys = config?.user?.asp ? [mif, connectivity] : [asp];
      filterTabs(keys);
    }
  }, [history, config, partner]);

  // Dynamically remove EOS tab if ASP is enabled
  useEffect(() => {
    if (table.some((e) => e.title === eos)) {
      if (table.some((e) => e.title === asp)) {
        setTable(
          table.filter((item) => {
            return item.title !== eos;
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table]);

  const onSubmit = () => {
    setKpi({});
    setSelect(!select);
  };

  return (
    <Global.Container>
      <Global.Cover />
      <Global.Render>
        <Hero switchAllowed={switchAllowed} kpiManager={toggleManager} />
        <Global.Wrapper>
          {select ? (
            <KpisManager
              submitPartner={() => onSubmit()}
              allowed={config?.authorizations?.filters?.kpis}
            />
          ) : (
            <TabsContainer panes={table} />
          )}
        </Global.Wrapper>
      </Global.Render>
    </Global.Container>
  );
};

export default Performance;
