const getTextWidth = (text: string, font: string): any => {
  const canvas: any = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
};

export default function useTextWidth(): any {
  const textWidth = (text: string, font: string) => {
    return text && font && getTextWidth(text, font);
  };
  return { textWidth } as const;
}
