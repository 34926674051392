import { FC } from "react";
import { Table, Button, Checkbox, Typography, Input } from "antd";
import { TitleProps } from "antd/lib/typography/Title";
import { SearchProps } from "antd/lib/input";
import { ButtonProps } from "antd/lib/button";
import { TableProps } from "antd/lib/table";

import { styled } from "theme/theme";

const { Title } = Typography;
const { Search } = Input;

interface Props {
  isOpen: boolean;
}

interface FieldProps extends SearchProps {
  ref?: any;
}

interface SubmitProps extends ButtonProps {
  ref?: any;
}

interface ResultsProps extends TableProps<any> {
  dataSource: any;
}

export const Card = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  padding: 0 64px 54px 64px;
`;

export const ContentTitle: FC<TitleProps> = styled(Title)`
  margin: 54px 0 34px 0 !important;
  font-weight: 300 !important;
`;

export const Sort = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
`;

export const DownloadButton = styled(Button)`
  text-transform: uppercase;
  :hover {
    background: ${(props) => props.theme.color.mainBrand};
    border-color: ${(props) => props.theme.color.mainBrand};
    color: #fff;
  }
`;

export const Filters: FC = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  transition: 300ms;
  margin-top: 4px;
  width: 100%;
`;

export const SearchContainer = styled.div`
  display: flex;
  margin-top: 6px;
  margin-left: 4px;
  justify-content: flex-end;
  align-items: center;
  transition: 300ms;
  //box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.16);
  max-width: calc(100% - 6px);
  @media (max-width: 768px) {
    width: calc(100% - 6px) !important;
    min-width: auto !important;
  }
`;

export const Field: FC<FieldProps> = styled(Search)`
  & .ant-input-affix-wrapper:hover {
    border-color: ${(props) => props.theme.color.lightShade}!important;
  }
  & .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: ${(props) => props.theme.color.lightShade}!important;
    outline: none !important;
    webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0) !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0) !important;
  }
`;

export const Submit: FC<SubmitProps> = styled(Button)<Props>`
  border: none;
  background: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.lightAccent}!important;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  border-radius: 0;
  :hover {
    background: ${(props) => props.theme.color.mainBrand};
    border: none;
    color: white;
  }
  :focus {
    background: ${(props) => props.theme.color.primary};
    color: white;
  }
`;

export const Reset: FC<ButtonProps> = styled(Button)<Props>`
  border: 1px solid ${(props) => props.theme.color.info};
  background: ${(props) => props.theme.color.lightAccent};
  color: ${(props) => props.theme.color.info};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 19px 24px;
  width: 100px;
  transition: 300ms;
  :hover {
    background: ${(props) => props.theme.color.info};
    border: none;
    color: white;
  }
  :focus {
    background: ${(props) => props.theme.color.info};
    color: white;
  }
`;

export const Results: FC<ResultsProps> = styled(Table)`
  margin-top: 20px;
  & .ant-table-thead > tr > th {
    font-weight: 600;
  }
  & .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: rgba(0, 0, 0, 0.03);
  }
  & .ant-checkbox:hover {
    outline: ${(props) => props.theme.color.info} !important;
    border-color: ${(props) => props.theme.color.info} !important;
  }
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.color.info};
    border-color: ${(props) => props.theme.color.info};
  }
  & .ant-pagination-item-active {
    border-color: ${(props) => props.theme.color.info};
    a,
    a:hover {
      color: ${(props) => props.theme.color.info};
    }
  }
  & .ant-pagination-item:focus,
  .ant-pagination-item:hover,
  .ant-pagination-item a:hover {
    border-color: ${(props) => props.theme.color.info};
    color: ${(props) => props.theme.color.info};
  }
  & .ant-spin-dot-item {
    background-color: ${(props) => props.theme.color.mainBrand};
  }
`;

export const Checker = styled(Checkbox)`
  *:hover {
    border-color: ${(props) => props.theme.color.info} !important;
    outline: ${(props) => props.theme.color.info} !important;
  }
  & .ant-checkbox:hover {
    outline: ${(props) => props.theme.color.info} !important;
    border-color: ${(props) => props.theme.color.info} !important;
  }

  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.color.info};
    border-color: ${(props) => props.theme.color.info};
  }
`;

export const Pagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
