import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Skeleton } from "antd";
import { Cell, Tooltip, PieChart, Pie } from "recharts";

import { useFlagger, useFormatter } from "hooks";
import { AppContext } from "providers";
import { KpiProps } from "types/kpi";
import * as S from "./style";

const COLORS: string[] = ["#c34400", "#9b2583", "#1a6fc5"];

type ChartProps = {
  UNIT?: string;
  DIRECTION: string;
  PERIOD: string | null;
  EVOLUTION?: number;
  DETAILS: { [key: string]: string | number }[];
  YEAR: number;
  TOTAL?: React.ReactText;
  lost?: React.ReactText;
  data?: Record<string, unknown>[];
};

const Performance: FC = () => {
  const { flagger, countryCode } = useFlagger();
  const { formatValue } = useFormatter();
  const { t } = useTranslation();
  const { kpi }: KpiProps = useContext(AppContext);
  const { config, partner } = useContext(AppContext);
  const [period, setPeriod] = useState<[number, number]>([0, 0]);

  const legendFormater = (type: string | undefined) => {
    if (type === "high" || type === "mid" || type === "entry")
      return `${t(`kpis.mif.legend.${type.toLowerCase()}`)}: `;
    return type;
  };
  const countryEquipment = (value: number | unknown): boolean =>
    typeof value === "number" || false;

  useEffect(() => {
    setPeriod([kpi.sales?.data[0].YEAR, kpi.sales?.data[1].YEAR]);
  }, [kpi]);

  return (
    <>
      <S.Tier level={3}>{t("kpis.performance.tier")}</S.Tier>
      <Row justify="space-between">
        <S.Column lg={12} xs={24}>
          <S.Category>{t("kpis.performance.sale.title")}</S.Category>
          <S.DotLegend>
            <S.Dot fill="#c34400" />
            <S.LegendTitle>{t("kpis.mif.legend.high")}</S.LegendTitle>
            <S.Dot fill="#9b2583" />
            <S.LegendTitle>{t("kpis.mif.legend.mid")}</S.LegendTitle>
            <S.Dot fill="#1a6fc5" />
            <S.LegendTitle>{t("kpis.mif.legend.entry")}</S.LegendTitle>
          </S.DotLegend>
          <S.Reverse>
            {!kpi.sales && (
              <>
                <S.RowChart>
                  <Skeleton.Input
                    style={{ width: 300, height: 80, margin: 15 }}
                    active
                    size="large"
                  />
                  <Skeleton.Input
                    style={{ width: 300, height: 150 }}
                    active
                    size="large"
                  />
                </S.RowChart>
                <S.RowChart>
                  <Skeleton.Input
                    style={{ width: 300, height: 80, margin: 15 }}
                    active
                    size="large"
                  />
                  <Skeleton.Input
                    style={{ width: 300, height: 150 }}
                    active
                    size="large"
                  />
                </S.RowChart>
              </>
            )}
            {kpi.sales &&
              kpi.sales.data instanceof Array &&
              kpi.sales.data.map((sale: ChartProps, i: number) => {
                if (i < 2) {
                  return (
                    <S.RowChart
                      key={i.toString()}
                      data-kpi={kpi.sales?.meta.endpoint}
                    >
                      <S.Square>
                        <S.Year>{period[i]}</S.Year>
                        <S.Value>
                          <S.Total>
                            {typeof sale?.TOTAL === "number" &&
                              formatValue(sale.TOTAL)}{" "}
                            {sale.UNIT}
                          </S.Total>
                          {sale?.EVOLUTION && (
                            <S.Percentage>
                              {/* {sale?.DIRECTION === "DOWN" && "-"} */}
                              {/* {sale?.DIRECTION === "UP" && "+"} */}
                              {Math.round(sale?.EVOLUTION) > 0 && "+"}
                              {Math.round(sale?.EVOLUTION)}%
                            </S.Percentage>
                          )}
                        </S.Value>
                      </S.Square>
                      {sale?.DETAILS && sale.DETAILS.length <= 0 && (
                        <S.Empty>No Data To Display</S.Empty>
                      )}
                      {sale?.DETAILS && sale.DETAILS.length > 0 && (
                        <PieChart width={300} height={150}>
                          <Pie
                            data={sale.DETAILS}
                            cx="50%"
                            cy="75%"
                            startAngle={180}
                            endAngle={0}
                            innerRadius={55}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={0}
                            dataKey="VALUE"
                            nameKey="NAME"
                          >
                            {sale?.DETAILS.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip
                            cursor={false}
                            contentStyle={{
                              fontWeight: "bold",
                              borderRadius: 3,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            labelStyle={{ marginRight: 5 }}
                            labelFormatter={(name: any) =>
                              legendFormater(name.toLowerCase())
                            }
                            formatter={(value: any, name: any) => [
                              `${name}: ${Math.round(value)}%`,
                              parseFloat(name),
                            ]}
                            viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
                          />
                        </PieChart>
                      )}
                    </S.RowChart>
                  );
                }
                return null;
              })}
          </S.Reverse>
          {kpi?.countrySales?.data ? (
            <S.Country>
              <S.Flag
                alt={countryCode || "flag"}
                src={flagger(partner?.config?.country || config?.user?.country)}
              />
              <S.Info>
                {kpi?.countrySales?.data?.JOB === "CCSS"
                  ? t("kpis.performance.sale.country.ccss")
                  : t("kpis.performance.sale.country.dtp")}
              </S.Info>
              <S.Perf>
                {kpi?.countrySales?.data?.PERCENT > 0 && "+"}
                {`${Math.round(kpi?.countrySales?.data?.PERCENT)}%`}
              </S.Perf>
            </S.Country>
          ) : (
            <Skeleton.Input
              style={{ width: 665, height: 60, marginLeft: 60, marginTop: 15 }}
              active
              size="large"
            />
          )}
        </S.Column>
        <S.Column lg={12} xs={24}>
          <S.Category>{t("kpis.performance.equipments.title")}</S.Category>
          <S.DotLegend>
            <S.Dot fill="#c34400" />
            <S.LegendTitle>{t("kpis.mif.legend.high")}</S.LegendTitle>
            <S.Dot fill="#9b2583" />
            <S.LegendTitle>{t("kpis.mif.legend.mid")}</S.LegendTitle>
            <S.Dot fill="#1a6fc5" />
            <S.LegendTitle>{t("kpis.mif.legend.entry")}</S.LegendTitle>
          </S.DotLegend>
          <S.Reverse>
            {!kpi.equipments && (
              <>
                <S.RowChart>
                  <Skeleton.Input
                    style={{ width: 300, height: 80, margin: 15 }}
                    active
                    size="large"
                  />
                  <Skeleton.Input
                    style={{ width: 300, height: 150 }}
                    active
                    size="large"
                  />
                </S.RowChart>
                <S.RowChart>
                  <Skeleton.Input
                    style={{ width: 300, height: 80, margin: 15 }}
                    active
                    size="large"
                  />
                  <Skeleton.Input
                    style={{ width: 300, height: 150 }}
                    active
                    size="large"
                  />
                </S.RowChart>
              </>
            )}
            {kpi.equipments &&
              kpi.equipments.data instanceof Array &&
              kpi.equipments.data.map((equipment: ChartProps, i: number) => {
                if (i < 2) {
                  return (
                    <S.RowChart
                      key={i.toString()}
                      data-kpi={kpi.equipments?.meta.endpoint}
                    >
                      <S.Square>
                        <S.Year>{period[i]}</S.Year>
                        <S.Value>
                          <S.Total>
                            {typeof equipment?.TOTAL === "number" &&
                              formatValue(equipment.TOTAL)}{" "}
                            {t("kpis.performance.equipments.units")}
                          </S.Total>
                          {equipment?.EVOLUTION && (
                            <S.Percentage>
                              {Math.round(equipment?.EVOLUTION) > 0 && "+"}
                              {Math.round(equipment?.EVOLUTION)}%
                            </S.Percentage>
                          )}
                        </S.Value>
                      </S.Square>
                      {equipment?.DETAILS && equipment.DETAILS.length <= 0 && (
                        <S.Empty>No Data To Display</S.Empty>
                      )}
                      {equipment?.DETAILS && equipment.DETAILS.length > 0 && (
                        <PieChart width={300} height={150}>
                          <Pie
                            data={equipment.DETAILS}
                            cx="50%"
                            cy="75%"
                            startAngle={180}
                            endAngle={0}
                            innerRadius={55}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={0}
                            dataKey="VALUE"
                            nameKey="NAME"
                          >
                            {equipment?.DETAILS.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip
                            cursor={false}
                            contentStyle={{
                              fontWeight: "bold",
                              borderRadius: 3,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            labelStyle={{ marginRight: 5 }}
                            labelFormatter={(name: any) =>
                              legendFormater(name.toLowerCase())
                            }
                            formatter={(value: any, name: any) => [
                              `${name}: ${Math.round(value)}%`,
                              parseFloat(name),
                            ]}
                            viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
                          />
                        </PieChart>
                      )}
                    </S.RowChart>
                  );
                }
                return null;
              })}
          </S.Reverse>
          {countryEquipment(kpi?.countryEquipments?.data?.PERCENT) ? (
            <S.Country>
              <S.Flag
                alt={countryCode || "flag"}
                src={flagger(partner?.config?.country || config?.user?.country)}
              />
              <S.Info>
                {kpi?.countryEquipments?.data?.JOB === "CCSS"
                  ? t("kpis.performance.equipments.country.ccss")
                  : t("kpis.performance.equipments.country.dtp")}
              </S.Info>
              <S.Perf>
                {kpi?.countryEquipments?.data?.PERCENT > 0 && "+"}
                {`${Math.round(kpi?.countryEquipments?.data?.PERCENT)}%`}
              </S.Perf>
            </S.Country>
          ) : (
            <Skeleton.Input
              style={{ width: 665, height: 60, marginLeft: 60, marginTop: 15 }}
              active
              size="large"
            />
          )}
        </S.Column>
        {kpi?.countrySales?.data && (
          <S.PercentLegend>{t("kpis.performance.legend")}</S.PercentLegend>
        )}
      </Row>
    </>
  );
};

export default Performance;
