import React from "react";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";

import routes from "router/routes";
import ApiService from "services/request";
import { error, info } from "services/notify";
import { KpiResponse, KpiFormatted, BRFormatted } from "types/kpi";
import AppContext from "./context";

interface ProviderProps {
  children: React.ReactNode;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Provider: React.FC<ProviderProps> = ({ children }: ProviderProps) => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [config, setConfig] = React.useState<any>(null);
  const [token, setToken] = React.useState<string>("");
  const [kpi, setKpi] = React.useState({});
  const [currentPeriod, setCurrentPeriod] = React.useState<number | null>(null);
  const [filters, setFilters] = React.useState([]);
  const [dashboard, setDashboard] = React.useState([]);
  const [BRFilters, setBRFilters] = React.useState({});
  const [temp, setTemp] = React.useState<any>(null);
  const [eosFilters, setEosFilters] = React.useState<any>(null);
  const [connectivityFilters, setConnectivityFilters] = React.useState<any>(
    null
  );
  const [BRPDF, setBRPDF] = React.useState<{
    loading: boolean;
    data: any;
    type: any;
  }>({
    loading: false,
    data: null,
    type: null,
  });
  const [mounted, isMounted] = React.useState<boolean>(false);
  const [partner, updatePartner] = React.useState<{
    queries: string;
    name: string;
    config?: any;
  } | null>(null);
  const [state, updateState] = React.useState<{
    loading: boolean;
    error: boolean;
  }>({ loading: true, error: false });
  const [BR, setBR] = React.useState({});
  const [director, setDirector] = React.useState<any>(null);
  const mounting = (bool: boolean) => isMounted(bool);
  // Methode fetch one or many KPI at same time.
  // Then we format the response of each kpi
  // with their own endpoint as object key
  // and also add some metadata based on request config returned
  // And finally store the new formatted kpi(s) on state
  const fetchKpi = (
    type: string,
    endpoints: { route: string; name: string }[]
  ) => {
    const end = [];
    endpoints.map(({ route }) => end.push(route));
    ApiService.getAll(type, endpoints, token, (res: KpiResponse[]) => {
      let formatted:
        | { [key: string]: KpiFormatted }
        | Record<string, unknown> = kpi;
      res.forEach((newKpi) => {
        if (newKpi) {
          const key = newKpi.name;
          const mutation: KpiFormatted = {
            data: newKpi.data.value,
            meta: {
              status: newKpi.status,
              endpoint: newKpi.config.url,
              baseURL: newKpi.config.baseURL,
            },
            extra: {
              total: newKpi.data.totalItems,
              perPage: newKpi.data.perPage,
              currentPage: newKpi.data.currentPage,
            },
          };
          formatted = { ...formatted, [key]: mutation };
        }
      });
      setKpi(formatted);
    });
  };

  const updateDelay = (
    type: string,
    endpoint: { route: string; name: string },
    data: { period: number } | Record<string, unknown>
  ) => {
    ApiService.delay(type, endpoint.route, data).then((res) => {
      setCurrentPeriod(res.data?.period);
    });
  };

  const retriveWM = (endpoint: string) => {
    return ApiService.retriveMessage(endpoint).then((res) => {
      const downloadLink = document.createElement("a");
      const fileName = `wm-${Date.now()}.xlsx`;
      downloadLink.href = res.data;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadLink.remove();
    });
  };

  const dropXlsx = (endpoint: string, data: any) => {
    return ApiService.drop(endpoint, data);
  };

  const getEosFilters = (endpoint: { route: string; name: string }) => {
    ApiService.get(endpoint.route, token).then((res) => {
      setEosFilters(res.data);
    });
  };

  const getConnectivityFilters = (endpoint: {
    route: string;
    name: string;
  }) => {
    ApiService.get(endpoint.route, token).then((res) => {
      setConnectivityFilters(res.data);
    });
  };

  const getBRPDF = (
    endpoint: { route: string; name: string },
    idList: number[],
    action: string
  ) => {
    setBRPDF({ loading: true, data: null, type: action });
    ApiService.createPDF(endpoint.route, { id: idList }, token)
      .then(async (res) => {
        await setBRPDF({ loading: false, data: res.data, type: action });
      })
      .catch(async (e) => {
        await setBRPDF({ loading: false, data: null, type: action });
        // eslint-disable-next-line no-console
        console.error(e.message);
        error("Le téléchargement du PDF a échoué.");
      });
  };

  const fetchBR = (endpoint: { route: string; name: string }) => {
    ApiService.get(endpoint.route, token).then((res) => {
      const formatted: BRFormatted = {
        data: res.data["hydra:member"],
        meta: {
          first:
            (res.data["hydra:view"] && res.data["hydra:view"]["hydra:first"]) ||
            undefined,
          last:
            (res.data["hydra:view"] && res.data["hydra:view"]["hydra:last"]) ||
            undefined,
          next:
            (res.data["hydra:view"] && res.data["hydra:view"]["hydra:next"]) ||
            undefined,
          items: res.data["hydra:totalItems"] || undefined,
          status: res.status,
          endpoint: res.config.url,
          baseURL: res.config.baseURL,
          loading: false,
        },
      };
      setBR(formatted);
    });
  };

  const loadDataByChunk = (endpoint: {
    route: string;
    name: string;
    type?: string;
  }) => {
    ApiService.post(endpoint.route, token).then((res) => {
      setTemp(res.data.value);
    });
  };

  const getBRFilters = (endpoint: { route: string; name: string }) => {
    ApiService.get(endpoint.route, token).then((res) => {
      const formatted: any = {
        data: res.data,
      };
      setBRFilters(formatted);
    });
  };

  const requestFilters = (endpoint: { route: string; name: string }) => {
    ApiService.get(endpoint.route, token).then((res) => {
      const formatted: any = {
        data: res.data,
        meta: {
          status: res.status,
          endpoint: res.config.url,
          baseURL: res.config.baseURL,
        },
      };
      setFilters(formatted);
    });
  };

  const requestDashboard = (endpoint: { route: string; name: string }) => {
    ApiService.get(endpoint.route, token)
      .then((res) => {
        const formatted: any = {
          data: res.data,
          meta: {
            status: res.status,
            endpoint: res.config.url,
            baseURL: res.config.baseURL,
          },
        };
        setDashboard(formatted);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message);
        error("La récupération des partenaires à échouée !");
      });
  };

  // Methode called when login form is filed and valid
  const auth = (credentials: { username: string; password: string }) => {
    ApiService.auth("login", {
      username: credentials.username,
      password: credentials.password,
    })
      .then((r) => {
        setToken(r.data.token);
      })
      .catch((e) => {
        error(e.response?.data?.message);
      });
  };

  const revokeAccess = async () => {
    const clientType = await localStorage.getItem("clientType");
    setToken("");
    setConfig(null);
    updateState({ loading: true, error: false });
    mounting(false);
    setKpi({});
    updatePartner(null);
    setFilters([]);
    setDirector(null);
    setEosFilters(null);
    setBRPDF({ loading: true, data: null, type: null });
    setBRFilters({});
    setBR({});
    localStorage.clear();
    if (clientType) {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === "development")
        history.push(routes.AUTH);
      else history.push(routes.PORTAL);
    } else history.push(routes.AUTH);
  };

  // Store token to localstorage when user just logged in
  // Then change location or fallback to Auth page.
  // HOTFIX: to prevent app state reloading, lastLocation is
  // given by the localStorage to the callback
  React.useEffect(() => {
    (async function any() {
      const sso = query.get("token");
      const lastLocation = await localStorage.getItem("from");
      if (token.length > 0 && lastLocation) {
        localStorage.setItem("token", token);
        history.push(lastLocation);
      } else if (token.length > 0) {
        localStorage.setItem("token", token);
        if (history.location.pathname === routes.AUTH) {
          history.push(routes.REDIRECT);
        }
      } else if (sso) {
        localStorage.setItem("token", sso);
        localStorage.setItem("clientType", "sso");
        setToken(sso);
        history.push(routes.REDIRECT);
      } else if (location.pathname === routes.RESET) {
        info("Please reset your password");
      } else history.push(routes.AUTH);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, history]);

  // Fetch statics data from API
  // These ones just contain info about
  // the current user and must be fetch on app mount
  React.useEffect(() => {
    updateState({ ...state, loading: true });
    if (token)
      ApiService.get("config/static", token)
        .then((r) => {
          localStorage.setItem("unix", `${r.data.exp || "1609999999"}`);
          setConfig(r.data);
        })
        .catch((e) => error(`${e.message}`));
    updateState({ ...state, loading: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  // Get token and expiration date from local storage
  // If expiration date is before current date
  // Then we clear the local storage and user should be sign in
  React.useEffect(() => {
    const localToken = localStorage.getItem("token");
    const exp = localStorage.getItem("unix");
    if (localToken && exp) {
      if (moment.unix(parseFloat(exp)).isAfter(moment())) setToken(localToken);
      else {
        error("Connection expired, please reconnect!");
        revokeAccess();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  React.useEffect(() => {
    if (partner && !partner.config && token) {
      ApiService.get(`config/dynamic${partner.queries}`, token)
        .then((r) => {
          updatePartner({ ...partner, config: r.data });
        })
        .catch((e) => error(`${e.message}`));
    }
  }, [partner, token]);

  React.useEffect(() => {
    if (config?.authorizations?.pages?.welcomeMessage) {
      (async function api() {
        let signature = "";
        let picture = "";
        await ApiService.get(`welcome-message/director/signature`, token)
          .then((r) => {
            signature = r.data;
          })
          // eslint-disable-next-line no-console
          .catch((e: any) => console.log(e));
        await ApiService.get(`welcome-message/director/picture`, token)
          .then((r) => {
            picture = r.data;
          })
          // eslint-disable-next-line no-console
          .catch((e: any) => console.log(e));
        setDirector({ picture, signature });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <AppContext.Provider
      value={{
        auth,
        fetchKpi,
        fetchBR,
        getBRFilters,
        BRFilters,
        getBRPDF,
        setBRPDF,
        setBR,
        BRPDF,
        kpi,
        setKpi,
        BR,
        revokeAccess,
        state,
        token,
        config,
        mounted,
        mounting,
        filters,
        dashboard,
        requestFilters,
        requestDashboard,
        retriveWM,
        partner,
        updatePartner,
        director,
        currentPeriod,
        setCurrentPeriod,
        updateDelay,
        dropXlsx,
        loadDataByChunk,
        temp,
        setTemp,
        setEosFilters,
        getEosFilters,
        eosFilters,
        getConnectivityFilters,
        setConnectivityFilters,
        connectivityFilters,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default Provider;
