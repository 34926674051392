import { FC } from "react";
import { Tabs } from "antd";
import { TabsProps } from "antd/lib/tabs";
import { FiChevronDown } from "react-icons/fi";
import { IconBaseProps } from "react-icons";

import { styled } from "theme/theme";

interface RespProps extends IconBaseProps {
  active: boolean;
}

interface PannelProps extends TabsProps {
  active: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const Pannel: FC<PannelProps> = styled(Tabs)`
  width: 100%;
  flex: 1;
  min-height: 68vh;
  align-self: center;
  color: ${(props) => props.theme.color.info};
  & .ant-tabs-nav-list {
    width: 100%;
    flex-direction: row;
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
  & div.ant-tabs-tab-active {
    transition: 800ms;
    background: ${(props) => props.theme.color.primary}!important;
    & div {
      color: ${(props) => props.theme.color.lightAccent}!important;
      font-weight: bold !important;
    }
  }
  & .ant-tabs-ink-bar {
    display: none;
  }
  & .ant-tabs-tab {
    width: 25%;
    height: 90px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.35px;
    font-weight: bold;
    text-align: center;
    padding: 0 4px;
    margin: 0;
    background: ${(props) => props.theme.color.lightShade}!important;
    color: ${(props) => props.theme.color.info};
    cursor: pointer;
    transition: 300ms;
    justify-content: center;
    white-space: initial;
    & div {
      text-align: center;
    }
    @media (max-width: 992px) {
      width: 100%;
      padding-left: 20px;
      justify-content: flex-start;
      text-align: left;
      height: ${(click: PannelProps) => (click.active ? "90" : "-90")}px;
      display: ${(click: PannelProps) => (click.active ? "flex" : "none")};
    }
  }

  & .ant-tabs-tab-btn {
    text-align: center !important;
    font-size: 12px;
    @media (max-width: 992px) {
      max-width: calc(100% - 36px);
      text-align: left !important;
      font-size: 14px;
    }
  }

  & .ant-tabs-tab-active {
    height: 90px;
    display: flex;
  }
  & .ant-tabs-tab:hover {
    color: ${(props) => props.theme.color.primary}!important;
  }
  & .ant-tabs-nav-operations,
  .ant-tabs-nav-operations-hidden {
    display: none !important;
  }
`;

export const RespIcon: FC<RespProps> = styled(FiChevronDown)`
  font-size: 20px;
  color: ${(props) => props.theme.color.lightAccent};
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.16);
  transition: 300ms;
  transform: rotate(
    ${(click: RespProps) => (click.active ? "-180deg" : "0deg")}
  );
`;

export const RespButton = styled.div`
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  z-index: 10;
  display: none;
  justify-content: center;
  margin-top: 29px;
  border-radius: 25px;
  border: 2px solid ${(props) => props.theme.color.lightAccent};
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  align-items: center;
  position: absolute;
  margin-left: 82%;
  cursor: pointer;
  @media (max-width: 992px) {
    display: flex;
  }
`;
