import { AppContext } from "providers";
import React, { useContext, useState } from "react";
import ApiService from "services/request";
import { useTranslation } from "react-i18next";
import * as S from "../style";

const ConnectivityExportButton = () => {
  const { token, partner } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const exportXlsx = () => {
    setIsLoading(true);
    ApiService.getXlsx(
      token,
      partner?.config.id ? `?userId=${partner.config.id}` : "",
      "connectivity"
    ).then((res) => {
      const downloadLink = document.createElement("a");
      const fileName = `connectivity-${Date.now()}.xlsx`;
      downloadLink.href = res.data;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadLink.remove();
      setIsLoading(false);
    });
  };

  return (
    <S.Submit loading={isLoading} onClick={exportXlsx}>
      {t("kpis.eos.export")}
    </S.Submit>
  );
};

export default ConnectivityExportButton;
