import React, { useContext, useEffect, useState } from "react";
import { proc } from "utils/api-common";
import { AppContext } from "providers";
import { useVT } from "virtualizedtableforantd4";
import { KpiProps } from "types/kpi";
import { Row } from "antd";
import * as S from "./style";
import { concatenateData } from "./utils";
import Table from "./ConnectivityTable";
import ExportButton from "./ConnectivityExportButton";
import Percent from "./ConnectivityPercent";
import Filters from "./ConnectivityFilters";

const scrollConfig = {
  y: 400,
};

const Connectivity = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [
    loadingConnectivityData,
    setLoadingConnectivityData,
  ] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any>([]);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);

  const { kpi }: KpiProps = useContext(AppContext);

  const { loadDataByChunk, temp, fetchKpi, partner } = useContext(AppContext);

  const connectivityDataRequestBuilder = (
    page: number = currentPage,
    company: string | null = selectedFilter
  ) => {
    const queries: { [key: string]: string | null } = {
      id: (partner?.config.id && `userId=${partner.config.id}`) || null,
      page: `page=${page}`,
      company: (company && `company=${company}`) || null,
    };

    Object.keys(queries).forEach(
      (k: string) => queries[k] == null && delete queries[k]
    );

    return {
      type: proc.connectivity.values.type,
      name: proc.connectivity.values.name,
      route: `${proc.connectivity.values.route}?${Object.values(queries).join(
        "&"
      )}`,
    };
  };

  const [vt] = useVT(
    () => ({
      onScroll: async ({ isEnd }) => {
        if (isEnd) {
          if (
            kpi.connectivity?.extra?.total &&
            kpi.connectivity?.extra?.perPage &&
            currentPage <
              Math.ceil(
                kpi.connectivity?.extra?.total /
                  kpi.connectivity?.extra?.perPage
              )
          ) {
            setCurrentPage(currentPage + 1);
            await loadDataByChunk(
              connectivityDataRequestBuilder(currentPage + 1)
            );
            setLoadingConnectivityData(true);
          }
        }
      },
      scroll: { y: scrollConfig.y },
    }),
    [scrollConfig, dataSource]
  );

  useEffect(() => {
    if (temp && temp.length > 0) {
      setDataSource(concatenateData(temp, dataSource));
    }
    setLoadingConnectivityData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp]);

  useEffect(() => {
    setLoadingConnectivityData(true);
    setCurrentPage(1);
    fetchKpi("POST", [{ ...connectivityDataRequestBuilder(1) }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  useEffect(() => {
    if (kpi?.connectivity?.meta?.status === 200) {
      setDataSource(kpi?.connectivity?.data || []);
      setLoadingConnectivityData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpi?.connectivity?.data]);

  return (
    <Row justify="space-between">
      <Percent />
      <S.Menu>
        <Filters
          setSelectedFilter={setSelectedFilter}
          selectedFilter={selectedFilter}
          loadingConnectivityData={loadingConnectivityData}
        />
        <ExportButton />
      </S.Menu>
      <Table
        loading={loadingConnectivityData}
        dataSource={dataSource}
        vt={vt}
      />
    </Row>
  );
};

export default Connectivity;
