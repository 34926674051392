import { FC } from "react";
import { Progress, Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import { TitleProps } from "antd/lib/typography/Title";
import { ProgressProps } from "antd/lib/progress";

import { styled } from "theme/theme";

const { Text } = Typography;

interface ProgressValue extends TitleProps {
  value?: number;
  color?: string;
}

interface UnderlineProps {
  dimensions: { width: number; height: number };
}

type YearsProps = {
  months: number;
};

export const Legend = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  height: 25%;
  :first-of-type {
    margin-top: 25%;
  }
`;

export const LegendTitle: FC<TextProps> = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  color: ${(props) => props.theme.color.info};
  text-align: left;
  text-transform: capitalize;
`;
export const LegendData = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  min-width: 130px;
  max-width: 100%;
`;

export const LegendHorizontal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LegendValue: FC<TextProps> = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => props.theme.color.info};
  text-align: left;
  text-transform: uppercase;
`;

export const ProgressBar: FC<ProgressProps> = styled(Progress)`
  & .ant-progress-text {
    display: none;
  }
  .ant-progress-outer {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
`;

export const ProgressBarValue: FC<ProgressValue> = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: ${(progress: ProgressValue) =>
    progress.color ? progress.color : (props) => props.theme.color.info};
  text-align: right;
  text-transform: uppercase;
  width: ${(progress: ProgressValue) =>
    progress.value && progress.value <= 0 ? 10 : progress.value}%;
  transition: 300ms;
`;

export const Underline: FC<UnderlineProps> = styled.div`
  width: ${(chart: UnderlineProps) => chart.dimensions.width + 20}px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-left: 24px;
  margin-top: -10px;
  cursor: default;
`;

export const Year: FC<YearsProps> = styled.div`
  width: ${(year: YearsProps) => year.months * 8}%;
  margin: 6px 10px;
  padding-top: 6px;
  border-top: 2px solid #3333336b;
  color: #111;
  text-align: center;
  opacity: 0.7;
`;
