import React from "react";

import { KpiFormatted, BRFormatted } from "types/kpi";

type ContextProps = {
  auth: (credentials: { username: string; password: string }) => void;
  fetchKpi: (
    type: string,
    endpoints: (
      | { route: string; name: string }
      | { route: string; name: string }
    )[]
  ) => void;
  updateDelay: (
    type: string,
    endpoint: { route: string; name: string },
    data: { period: number } | Record<string, unknown>
  ) => void;
  fetchBR: (endpoint: { route: string; name: string }) => void;
  kpi: { [key: string]: KpiFormatted } | Record<string, unknown>;
  BR: { [key: string]: BRFormatted } | Record<string, unknown> | any;
  setBR: any;
  BRFilters: { [key: string]: any };
  getBRFilters: (endpoint: { route: string; name: string }) => void;
  loadDataByChunk: (endpoint: { route: string; name: string }) => void;
  getBRPDF: (
    endpoint: { route: string; name: string },
    id: number[],
    action: string
  ) => void;
  retriveWM: (endpoint: string) => void;
  dropXlsx: (endpoint: string, data: any) => void;
  BRPDF: any;
  revokeAccess: () => void;
  state: { loading: boolean; error: boolean };
  token: string;
  config: {
    user: { [key: string]: string };
    exp: string | number;
    authorizations: any;
    welcomeMessage: string;
  } | null;
  director: any;
  mounted: boolean;
  mounting: (bool: boolean) => void;
  filters: any;
  dashboard: any;
  currentPeriod: number | null;
  setCurrentPeriod: React.Dispatch<React.SetStateAction<number | null>>;
  requestFilters: (endpoint: { route: string; name: string }) => void;
  requestDashboard: (endpoint: { route: string; name: string }) => void;
  partner: {
    queries: string;
    name: string;
    config?: any;
    from?: string;
  } | null;
  temp: any;
  setTemp: React.Dispatch<React.SetStateAction<any>>;
  setBRPDF: React.Dispatch<
    React.SetStateAction<{ loading: boolean; data: any; type: any }>
  >;
  updatePartner: React.Dispatch<
    React.SetStateAction<{
      queries: string;
      name: string;
      from?: string;
    } | null>
  >;
  setKpi: React.Dispatch<
    React.SetStateAction<
      { [key: string]: KpiFormatted } | Record<string, unknown>
    >
  >;
  getEosFilters: (endpoint: { route: string; name: string }) => void;
  setEosFilters: React.Dispatch<React.SetStateAction<any>>;
  eosFilters: any;
  getConnectivityFilters: (endpoint: { route: string; name: string }) => void;
  setConnectivityFilters: React.Dispatch<React.SetStateAction<any>>;
  connectivityFilters: any;
};

const exception = new Error("function not implemented");

export const Context = React.createContext<ContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  auth: (credentails: { username: string; password: string }) => {
    throw exception;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetchKpi: async (
    type: string,
    endpoints: { route: string; name: string }[]
  ) => {
    throw exception;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadDataByChunk: (endpoint: { route: string; name: string }) => {
    throw exception;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  fetchBR: (endpoint: { route: string; name: string }) => {
    throw exception;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getBRFilters: (endpoint: { route: string; name: string }) => {
    throw exception;
  },
  getBRPDF: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    endpoint: { route: string; name: string },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    id: number[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    action: string
  ) => {
    throw exception;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  requestDashboard: (endpoint: { route: string; name: string }) => {
    throw exception;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  requestFilters: (endpoint: { route: string; name: string }) => {
    throw exception;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mounting: (bool: boolean) => {
    throw exception;
  },
  revokeAccess: () => {
    throw exception;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  retriveWM: (endpoint: string) => {
    throw exception;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dropXlsx: (endpoint: string, data: any) => {
    throw exception;
  },
  setBR: () => {
    throw exception;
  },
  kpi: {},
  BR: {},
  BRFilters: {},
  BRPDF: {},
  mounted: false,
  state: { loading: true, error: false },
  token: "",
  config: null,
  filters: [],
  dashboard: [],
  partner: null,
  currentPeriod: null,
  setCurrentPeriod: () => {
    throw exception;
  },
  updatePartner: () => {
    throw exception;
  },
  updateDelay: () => {
    throw exception;
  },
  setBRPDF: () => {
    throw exception;
  },
  setKpi: () => {
    throw exception;
  },
  setTemp: () => {
    throw exception;
  },
  getEosFilters: () => {
    throw exception;
  },
  setEosFilters: () => {
    throw exception;
  },
  eosFilters: null,
  director: null,
  temp: null,
  getConnectivityFilters: () => {
    throw exception;
  },
  setConnectivityFilters: () => {
    throw exception;
  },
  connectivityFilters: null,
});

Context.displayName = "AppData";

export default Context;
