import { FC } from "react";
import { TitleProps } from "antd/lib/typography/Title";
import { TextProps } from "antd/lib/typography/Text";
import { Col, Typography } from "antd";

import { styled } from "theme/theme";

const { Text, Title } = Typography;

interface TotalProps extends TitleProps {
  primary?: boolean;
}

interface CountryProps extends TitleProps {
  small?: boolean;
}

type DotProps = {
  fill: string;
};

export const Wrapper = styled.div`
  padding: 20px 50px;
`;

export const PanTitle: FC<TitleProps> = styled(Title)`
  font-weight: 300 !important;
`;

export const Category: FC<TextProps> = styled(Text)`
  font-size: 14px;
  margin-left: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.info};
`;

export const RowChart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
`;

export const Reverse = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Square = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Year: FC<TextProps> = styled(Text)`
  font-weight: 300 !important;
  font-size: 16px;
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid ${(props) => props.theme.color.info};
`;

export const Value = styled.div`
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 150px;
`;

export const Total: FC<TotalProps> = styled(Text)`
  font-weight: 700;
  text-align: center;
  font-size: 16px;
  color: ${(props) => props.theme.color.info}80;
`;

export const Percentage: FC<TextProps> = styled(Text)`
  font-weight: 700;
  font-size: 20px;
  color: ${(props) => props.theme.color.info};
`;

export const Country: FC<CountryProps> = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.color.lightShade};
  margin: 20px 15px;
  border-radius: 60px 0 0 60px;
  height: ${(is: CountryProps) => (is.small ? "50px" : "60px")};
  justify-content: ${(is: CountryProps) =>
    is.small ? "flex-start" : "space-between"};
  & .ant-typography {
    font-size: ${(is: CountryProps) => (is.small ? "11px" : "18px")};
  }
  & img {
    width: ${(is: CountryProps) => (is.small ? "50px" : "60px")};
    height: ${(is: CountryProps) => (is.small ? "50px" : "60px")};
  }
`;

export const Flag = styled.img`
  object-fit: fill;
  border-radius: 60px;
  background-color: ${(props) => props.theme.color.lightShade};
  color: ${(props) => props.theme.color.mainBrand};
  text-align: center;
`;

export const Info: FC<TextProps> = styled(Text)`
  font-weight: 300;
  margin-left: 10px;
  color: ${(props) => props.theme.color.info};
`;

export const Perf: FC<TextProps> = styled(Text)`
  font-weight: 700;
  font-size: 32px !important;
  height: 60px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  line-height: 1.9;
  padding: 0 50px;
  color: ${(props) => props.theme.color.lightAccent};
  background-color: ${(props) => props.theme.color.info}80;
`;

export const Empty: FC<TextProps> = styled(Text)`
  display: flex;
  white-space: nowrap;
  padding: 0 10px;
  align-items: center;
  font-weight: 300;
  text-align: left;
  font-style: italic;
  margin-left: 10px;
  opacity: 0.5;
  width: 100%;
  height: 112px;
  color: ${(props) => props.theme.color.info};
`;

export const DotLegend = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin: 0 0 28px 0;
`;

export const Dot: FC<DotProps> = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background-color: ${(color: DotProps) => color.fill};
  margin-left: 10px;
  margin-right: 4px;
`;

export const LegendTitle: FC<TextProps> = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  color: ${(props) => props.theme.color.info};
  text-align: left;
  text-transform: capitalize;
`;

export const PercentLegend = styled(Text)`
  font-weight: 300;
  font-size: 12px;
  color: ${(props) => props.theme.color.info};
  text-align: center;
  width: 100%;
`;

export const Column = styled(Col)`
  &:first-of-type {
    border-right: 1px solid ${(props) => props.theme.color.info}50;
    @media (max-width: 992px) {
      border: none;
    }
  }
`;

export const Tier = styled(Title)`
  margin-top: -18px !important;
  margin-left: -2px !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  color: ${(props) => props.theme.color.info}30 !important;
  text-align: left !important;
`;
