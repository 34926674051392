import React from "react";
import { Divider, Typography } from "antd";
import { DividerProps } from "antd/lib/divider";
import { TextProps } from "antd/lib/typography/Text";

import { styled } from "theme/theme";

export const { Text } = Typography;

type FooterProps = {
  fixed?: boolean;
  displayed?: boolean;
};

export const Wrapper = styled.div`
  margin-top: ${(is: FooterProps) => (is.fixed ? "10px" : "auto")};
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 92px;
  display: ${(should: FooterProps) => (should.displayed ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  padding: 0 60px;
  align-items: center;
  background: ${(props) => props.theme.color.lightShade};
  @media (max-width: 1240px) {
    padding: 0;
  }
`;

export const Nav = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  margin-bottom: 10px;
  @media (max-width: 1240px) {
    display: none;
  }
`;

export const Separator: React.FunctionComponent<DividerProps> = styled(Divider)`
  background: ${(props) => props.theme.color.info};
`;

export const NavLink: React.FunctionComponent<TextProps> = styled(Text)`
  font-size: 14px;
  margin: 0 10px 10px 10px;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.color.info};
`;

export const Copyright: React.FunctionComponent<TextProps> = styled(Text)`
  text-align: center;
  opacity: 0.4;
  color: ${(props) => props.theme.color.info};
`;
