import React, { FC, useEffect, useState } from "react";
import { Layout, Tabs } from "antd";
import { LayoutProps } from "antd/lib/layout";
import { TabsProps } from "antd/lib/tabs";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { styled } from "theme/theme";

const Centered: FC<LayoutProps> = styled(Layout)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 92px 60px 92px 60px;
  height: auto;
  width: 100%;
  overflow: hidden;
  background-color: ${(props) => props.theme.color.lightShade};
`;

const Pannel: FC<TabsProps> = styled(Tabs)`
  width: 100%;
  flex: 1;
  min-height: 68vh;
  align-self: center;
  padding-top: 20px;
  margin-top: 40px;
  color: ${(props) => props.theme.color.info};
  & .ant-tabs-tab-active div {
    color: ${(props) => props.theme.color.primary}!important;
  }
  & .ant-tabs-ink-bar {
    background: ${(props) => props.theme.color.primary}!important;
  }
  & .ant-tabs-tab:hover {
    color: ${(props) => props.theme.color.primary}!important;
  }
`;

const Content = styled.h4`
  color: ${(props) => props.theme.color.info};
`;

const { TabPane } = Tabs;

const Changelog: FC = () => {
  const { t } = useTranslation();
  const [front, setFront] = useState("");
  const [back, setBack] = useState("");
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem("from", history.location.pathname);
    fetch("/CHANGELOG.md").then((response) => {
      if (response.status !== 200) return;
      response.text().then((text) => {
        setFront(text);
      });
    });
    // eslint-disable-next-line consistent-return
    fetch("/api/CHANGELOG.md").then((response) => {
      if (response.status !== 200) return setBack(t("global.error.fetch"));
      response.text().then((text) => {
        setBack(text);
      });
    });
  }, [t, history]);

  return (
    <Centered>
      <Pannel defaultActiveKey="1" tabPosition="left" size="large" type="line">
        <TabPane tab={t("changelog.front")} key="1">
          <ReactMarkdown
            source={front}
            escapeHtml={false}
            renderers={{ text: Content }}
          />
        </TabPane>
        <TabPane tab={t("changelog.back")} key="2">
          <ReactMarkdown source={back} escapeHtml={false} />
        </TabPane>
      </Pannel>
    </Centered>
  );
};

export default Changelog;
