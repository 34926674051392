import React from "react";
import i18n from "i18next";

import { Export } from "components";
import { SortIcon, TableCell, TableTitle } from "components/Table/style";

type QuarterMapper = {
  [key: string]: string;
};

const quarterMapper = (): QuarterMapper => ({
  Q1: i18n.t("business.tab.aprilToJune"),
  Q2: i18n.t("business.tab.julyToSeptember"),
  Q3: i18n.t("business.tab.octoberToDecember"),
  Q4: i18n.t("business.tab.januaryToMarch"),
});

const capitalized = (str: string | number) => {
  if (typeof str === "number") return str;
  const splitStr = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

const createQuery = (updateSort: any, sort: any, col: string) => {
  let str = "";
  if (Array.isArray(col)) str = col[col.unshift() - 1];
  else str = col;
  const arr = { ...sort };
  if (arr[str] && arr[str] === "asc") {
    arr[str] = "desc";
    updateSort(arr);
  } else if (arr[str] && arr[str] === "desc") {
    delete arr[str];
    updateSort(arr);
  } else updateSort({ [str]: "asc" });
};

const tableTitle = (name: any) => (
  <TableCell>
    <TableTitle>
      {name}
      <SortIcon />
    </TableTitle>
  </TableCell>
);

const setColumns = (
  t: any,
  revealColumn: any,
  updateSort: any,
  sort: any,
  setExport: any
): Record<string, unknown>[] => {
  return [
    {
      show: revealColumn?.search,
      title: tableTitle(i18n.t("business.tab.bp")),
      dataIndex: "bpNumber",
      responsive: ["lg"],
      className: "hide",
      showSorterTooltip: false,
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            createQuery(updateSort, sort, column.dataIndex);
          },
        };
      },
    },
    {
      show: revealColumn?.search,
      title: tableTitle(i18n.t("business.tab.country")),
      dataIndex: ["user", "country"],
      responsive: ["md"],
      render: (text: string) => capitalized(text),
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            createQuery(updateSort, sort, column.dataIndex);
          },
        };
      },
    },
    {
      show: revealColumn?.search,
      title: tableTitle(i18n.t("business.tab.company")),
      dataIndex: ["user", "company"],
      render: (text: string) => capitalized(text),
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            createQuery(updateSort, sort, column.dataIndex);
          },
        };
      },
    },
    {
      show: false,
      title: tableTitle(i18n.t("business.tab.cgm")),
      dataIndex: ["user", "cgm"],
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            createQuery(updateSort, sort, column.dataIndex);
          },
        };
      },
    },
    {
      show: false,
      title: tableTitle(i18n.t("business.tab.cbm")),
      dataIndex: ["user", "cbm"],
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            createQuery(updateSort, sort, column.dataIndex);
          },
        };
      },
    },
    {
      show: true,
      title: tableTitle(i18n.t("business.tab.quarter")),
      dataIndex: "quarter",
      render: (text: string) => quarterMapper()[text],
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            createQuery(updateSort, sort, column.dataIndex);
          },
        };
      },
    },
    {
      show: true,
      title: tableTitle(i18n.t("business.tab.year")),
      dataIndex: "year",
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            createQuery(updateSort, sort, column.dataIndex);
          },
        };
      },
    },
    {
      show: true,
      dataIndex: "actions",
      render: (_text: string, row: any) => (
        <Export id={row.id} state={setExport} />
      ),
    },
  ];
};

function formatCol(
  config: any,
  t: any,
  updateSort: any,
  sort: any,
  setExport: any
) {
  const revealColumn = config;
  const data = setColumns(t, revealColumn, updateSort, sort, setExport);
  const newData = data.filter((column: any) => column.show === true);
  return newData;
}

export default function useRow(): any {
  const row = (
    config: any,
    t: any,
    updateSort: any,
    sort: any,
    setExport: any
  ) => {
    return formatCol(config, t, updateSort, sort, setExport);
  };
  return { row } as const;
}
