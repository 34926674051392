import { Col, Row, Pagination } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useTextWidth, useFilters, useRow, usePreview } from "hooks";
import { proc } from "utils/api-common";
import { AppContext } from "providers";
import * as S from "components/BRTable/style";
import { Filters } from "components";

const PDF = { DOWNLOAD: "DOWNLOAD", SELECTION: "SELECTION", VIEW: "VIEW" };

const Table = () => {
  const { row } = useRow();
  const { format } = useFilters();
  const { t } = useTranslation();
  const {
    config,
    fetchBR,
    BR,
    getBRFilters,
    BRFilters,
    getBRPDF,
    BRPDF,
    setBRPDF,
    setBR,
  } = useContext(AppContext);
  const { textWidth } = useTextWidth();
  const { preview, download, downloadAll } = usePreview();
  const [searchValue, handleSearch] = useState<string>("");
  const [dataSource, setDataSource] = useState<any>();
  const [selectedKeys, setselectedKeys] = useState<any>([]);
  const [selectedFilters, setSelectedFilters] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState<number>(1);
  const [sort, updateSort] = useState<{ [k: string]: string }>({
    country: "asc",
  });
  const [prevSort, setPrevSort] = useState<{ [k: string]: string }>({});
  const [exportId, setExport] = useState<null[] | number[] | string[]>([
    null,
    null,
  ]);
  const [fieldWidth, setFieldWidth] = useState<string | number | undefined>(
    undefined
  );
  const inputRef = useRef<any>(null);
  const submitRef = useRef<any>(null);

  const createQuery = () => {
    const searchQuery: string =
      searchValue.length >= 1 ? `&search=${searchValue}` : "";
    if (selectedFilters) {
      const data = { ...selectedFilters };
      const cgm: any = [];
      const cbm: any = [];
      if (selectedFilters.cgm) {
        selectedFilters.cgm.forEach((el: string) => {
          cgm.push(
            Object.keys(BRFilters.data.cgm).find(
              (key: string) => BRFilters.data.cgm[key] === el
            )
          );
        });
        Object.assign(data, { cgm: [...cgm] });
      }
      if (selectedFilters.cbm) {
        selectedFilters.cbm.forEach((el: string) => {
          cbm.push(
            Object.keys(BRFilters.data.cbm).find(
              (key: string) => BRFilters.data.cbm[key] === el
            )
          );
        });
        Object.assign(data, { cbm: [...cbm] });
      }
      const queries: any = [];
      Object.keys(data).forEach((key: string) => {
        queries.push(
          data[key].map((value: any) => `${key}[]=${value}`).join("&")
        );
      });
      if (queries.length >= 1)
        return `?page=${currentPage}${searchQuery}&${queries.join("&")}`;
    }
    return `?page=${currentPage}${searchQuery}`;
  };

  useEffect(() => {
    if (!BRFilters.data) getBRFilters(proc.BR.filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    const input: string = inputRef?.current?.props.placeholder;
    const submit: string = submitRef?.current?.innerText;
    setFieldWidth(
      textWidth(input, "14px arial") + textWidth(submit, "14px arial") + 75
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef, submitRef]);

  let dropfilters: any = [];
  if (BRFilters.data) {
    const obj: any = {};
    Object.values(BRFilters.data).forEach((el: any, i: number) => {
      if (el) {
        obj[Object.keys(BRFilters.data)[i]] = Object.values(el);
      }
    });
    dropfilters = format(obj);
  }

  useEffect(() => {
    if (selectedFilters) {
      const sorted: string[] = [];
      Object.keys(sort).forEach((key: string) => {
        sorted.push(`sort[${key}]=${sort[key]}`);
      });
      getBRFilters({
        route: `${proc.BR.filters.route}${createQuery()}`,
        name: proc.BR.filters.name,
      });
      setBR({ data: BR.data, meta: { ...BR.meta, loading: true } });
      fetchBR({
        route: `${proc.BR.collection.route}${createQuery()}&${sorted.join(
          "&"
        )}`,
        name: proc.BR.collection.name,
      });
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  useEffect(() => {
    if (Object.keys(sort).length >= 1 && sort !== prevSort) {
      setPrevSort(sort);
      const sorted: string[] = [];
      Object.keys(sort).forEach((key: string) => {
        sorted.push(`sort[${key}]=${sort[key]}`);
      });
      setBR({ data: BR.data, meta: { ...BR.meta, loading: true } });
      fetchBR({
        route: `${proc.BR.collection.route}${createQuery()}&${sorted.join(
          "&"
        )}`,
        name: proc.BR.collection.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  useEffect(() => {
    if (BR.data) setDataSource(BR.data);
    const total: any = BR.meta;
    if (BR.meta) {
      setPagination(total.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BR.data]);

  const updateTable = () => {
    const sorted: string[] = [];
    Object.keys(sort).forEach((key: string) => {
      sorted.push(`sort[${key}]=${sort[key]}`);
    });
    setBR({ data: BR.data, meta: { ...BR.meta, loading: true } });
    fetchBR({
      route: `${proc.BR.collection.route}${createQuery()}`,
      name: proc.BR.collection.name,
    });
  };

  const handleChange = (e: any) => {
    handleSearch(e.target.value);
  };

  const onSelectRow = (seletedRowKeys: any) => setselectedKeys(seletedRowKeys);

  const rowSelection = {
    selectedKeys,
    onChange: onSelectRow,
  };

  useEffect(() => {
    setBR({ data: BR.data, meta: { ...BR.meta, loading: true } });
    const sorted: string[] = [];
    Object.keys(sort).forEach((key: string) => {
      sorted.push(`sort[${key}]=${sort[key]}`);
    });
    fetchBR({
      route: `${proc.BR.collection.route}${createQuery()}${
        sorted.length >= 1 ? "&" : ""
      }${sorted.join("&")}`,
      name: proc.BR.collection.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const switchPage = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (typeof exportId[0] === "number" && typeof exportId[1] === "string") {
      getBRPDF(proc.BR.getPDF, [exportId[0]], exportId[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportId]);

  useEffect(() => {
    if (BRPDF.data && BRPDF.type === PDF.VIEW) preview(BRPDF.data, setBRPDF);
    if (BRPDF.data && BRPDF.type === PDF.SELECTION)
      downloadAll(BRPDF.data, setBRPDF);
    if (BRPDF.data && BRPDF.type === PDF.DOWNLOAD)
      download(BRPDF.data, setBRPDF);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BRPDF]);

  return (
    <S.Wrapper>
      <S.ContentTitle>{t("business.title")}</S.ContentTitle>
      <Row justify="space-between" align="bottom">
        <S.Sort>
          <S.Filters>
            <Col>
              <Filters
                type="br"
                spaced={4}
                data={dropfilters}
                updateManager={setSelectedFilters}
                displayResult
                inlineSelected
                useCheckbox
                displayResetButton
              />
            </Col>
            {config?.authorizations?.filters?.br?.search && (
              <S.SearchContainer style={{ minWidth: fieldWidth }}>
                <S.Field
                  ref={inputRef}
                  value={searchValue}
                  onChange={(e) => handleChange(e)}
                  placeholder={t("business.search.placeholder")}
                  onSearch={() => updateTable()}
                  onPressEnter={() => updateTable()}
                  enterButton={
                    <S.Submit ref={submitRef}>
                      {t("business.search.button")}
                    </S.Submit>
                  }
                />
              </S.SearchContainer>
            )}
          </S.Filters>
          <Row justify="end" style={{ width: "100%" }}>
            {config?.authorizations?.filters?.br?.search || (
              <S.Submit
                style={{ marginLeft: 4, marginTop: 6 }}
                onClick={() =>
                  getBRPDF(proc.BR.getPDF, selectedKeys, PDF.SELECTION)
                }
              >
                {t("business.downloadMySelection")}
              </S.Submit>
            )}
          </Row>
        </S.Sort>
      </Row>
      <Row justify="end">
        {config?.authorizations?.filters?.br?.search && (
          <Col>
            <S.Submit
              style={{
                marginLeft: 4,
                marginTop: 6,
                position: "relative",
                top: "63px",
                zIndex: 10,
              }}
              onClick={() =>
                getBRPDF(proc.BR.getPDF, selectedKeys, PDF.SELECTION)
              }
            >
              {t("business.downloadMySelection")}
            </S.Submit>
          </Col>
        )}
      </Row>
      <Row>
        <Col flex="auto">
          <S.Results
            loading={BR?.meta?.loading}
            rowKey="id"
            rowSelection={rowSelection}
            columns={row(
              config?.authorizations?.filters?.br,
              t,
              updateSort,
              sort,
              setExport
            )}
            dataSource={dataSource}
            pagination={false}
          />
          <S.Pagination>
            <Pagination
              current={currentPage}
              defaultCurrent={1}
              onChange={(page) => switchPage(page)}
              total={pagination}
              responsive
              showSizeChanger={false}
            />
          </S.Pagination>
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default Table;
