import i18next from "i18next";

export default function useLocal(): any {
  const setLang = (lang: string | undefined) => {
    if (!lang) return false;
    let current = lang.toLowerCase();
    current = current.replace("ch_", "");
    current = current.replace("be_", "");
    if (current === "german") current = "de";
    if (current === "french") current = "fr";
    if (current === "italian") current = "it";
    if (current === "spanish") current = "es";
    if (i18next.language !== current) i18next.changeLanguage(current);
    return [i18next.language, current];
  };
  return { setLang } as const;
}
