import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Row, Skeleton } from "antd";
import { Cell, Tooltip, PieChart, Pie } from "recharts";

import { CustomTooltip } from "components";
import { KpiProps } from "types/kpi";
import { AppContext } from "providers";
import * as S from "./style";

const COLORS: string[] = ["#f67e00", "#a57a70", "#8c021b"];

const Support: FC = () => {
  const { t } = useTranslation();
  const { kpi }: KpiProps = useContext(AppContext);
  const [state, setState] = React.useState(0);
  const [pie, setPie] = React.useState<
    | { NAME: string; PERCENT: number; VALUE: string | number; UNIT: string }[]
    | []
  >([]);
  const [table, setTable] = React.useState<{ [key: string]: any }[] | never[]>(
    []
  );

  React.useEffect(() => {
    setTimeout(() => {
      setState(45);
    }, 2000);
  }, [state]);

  React.useEffect(() => {
    if (kpi.standard?.data && kpi.bids?.data && kpi.promotion?.data) {
      const newPie = [
        {
          NAME: t("kpis.support.legend.standards"),
          PERCENT: kpi.standard.data.PERCENT,
          VALUE: kpi.standard.data.CA,
          UNIT: kpi.standard.data.UNIT,
        },
        {
          NAME: t("kpis.support.legend.bids"),
          PERCENT: kpi.bids.data.PERCENT,
          VALUE: kpi.bids.data.CA,
          UNIT: kpi.bids.data.UNIT,
        },
        {
          NAME: t("kpis.support.legend.promotions"),
          PERCENT: kpi.promotion.data.PERCENT,
          VALUE: kpi.promotion.data.CA,
          UNIT: kpi.promotion.data.UNIT,
        },
      ];
      setPie(newPie);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpi]);

  React.useEffect(() => {
    const data: { [key: string]: any }[] = kpi?.top?.data;
    if (data) {
      let formatted: { [key: string]: any }[] | never[] = [];
      data.forEach((entry) => {
        if (entry.NAME !== "") {
          formatted = [
            ...formatted,
            {
              ...entry,
              VALUE: `${entry.VALUE.toUpperCase()} ${entry.UNIT}`,
              PERCENT: `${Math.round(parseFloat(entry.PERCENT))}%`,
            },
          ];
        }
      });
      setTable(formatted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpi.top]);

  const columns = [
    {
      title: t("kpis.mix.top.col.product"),
      dataIndex: "NAME",
    },
    {
      title: t("kpis.mix.top.col.purchases"),
      dataIndex: "VALUE",
    },
    {
      title: t("kpis.mix.top.col.share"),
      dataIndex: "PERCENT",
    },
  ];

  const displayBubbleData = () => {
    if (typeof kpi.bids?.data.AVERAGE !== "number") return false;
    return typeof kpi.promotion?.data.AVERAGE === "number";
  };

  return (
    <Row gutter={[16, 16]}>
      <S.Column lg={8} md={24}>
        <S.SupportCard>
          <S.SupportCardTitle>{t("kpis.support.sales")}</S.SupportCardTitle>
          {pie.length > 0 ? (
            <S.DotLegend>
              <S.Dot fill={COLORS[0]} />
              <S.LegendTitle>
                {t("kpis.support.legend.standards")}
              </S.LegendTitle>
              <S.Dot fill={COLORS[1]} />
              <S.LegendTitle>{t("kpis.support.legend.bids")}</S.LegendTitle>
              <S.Dot fill={COLORS[2]} />
              <S.LegendTitle>
                {t("kpis.support.legend.promotions")}
              </S.LegendTitle>
            </S.DotLegend>
          ) : (
            <Skeleton.Input
              style={{ width: 500, height: 40, marginTop: 10 }}
              active
              size="large"
            />
          )}
          {pie.length > 0 ? (
            <PieChart width={500} height={300}>
              <Pie
                data={pie}
                cx="50%"
                cy="75%"
                startAngle={180}
                endAngle={0}
                innerRadius={100}
                outerRadius={160}
                fill="#8884d8"
                paddingAngle={0}
                dataKey="PERCENT"
                nameKey="NAME"
              >
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {pie.map((entry, index: number) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip
                cursor={false}
                contentStyle={{
                  fontWeight: "bold",
                  borderRadius: 3,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                labelStyle={{ marginRight: 5 }}
                formatter={(value: any, name: any) => [
                  `${name}: ${Math.round(value)} %`,
                  parseFloat(name),
                ]}
                content={<CustomTooltip />}
                viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
              />
            </PieChart>
          ) : (
            <Skeleton.Input
              style={{ width: 500, height: 276, marginTop: 24 }}
              active
              size="large"
            />
          )}
        </S.SupportCard>
      </S.Column>
      <S.Column lg={8} md={24}>
        <S.SupportCard>
          <S.SupportCardTitle>{t("kpis.support.average")}</S.SupportCardTitle>
          {displayBubbleData() ? (
            <S.SupportCardCenter>
              <S.Circle type="bids">
                <S.CircleTitle>{t("kpis.support.bids")}</S.CircleTitle>
                <S.CircleValue>{kpi.bids?.data.AVERAGE}%</S.CircleValue>
              </S.Circle>
              <S.Circle type="tacticals">
                <S.CircleTitle>{t("kpis.support.tacticals")}</S.CircleTitle>
                <S.CircleValue>{kpi.promotion?.data.AVERAGE}%</S.CircleValue>
              </S.Circle>
            </S.SupportCardCenter>
          ) : (
            <S.SupportCardCenter>
              <Skeleton.Avatar
                style={{ width: 168, height: 168 }}
                active
                size="large"
              />
              <Skeleton.Avatar
                style={{ width: 168, height: 168 }}
                active
                size="large"
              />
            </S.SupportCardCenter>
          )}
        </S.SupportCard>
      </S.Column>
      <S.Column lg={8} md={24}>
        <S.SupportCard>
          <S.SupportCardTitle>
            {t("kpis.support.promotions")}
          </S.SupportCardTitle>
          <S.SupportCardRow>
            {kpi?.top?.data ? (
              <S.MinimalTable
                pagination={false}
                columns={columns}
                dataSource={table}
              />
            ) : (
              <Skeleton.Input
                style={{ width: 500, height: 300 }}
                active
                size="large"
              />
            )}
          </S.SupportCardRow>
        </S.SupportCard>
      </S.Column>
    </Row>
  );
};

export default Support;
