import React, { FC, useContext } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { AppContext } from "providers";
import * as S from "./style";

interface UserData extends HeroProps, Config {
  lastKpisUpdate: string | null;
}

type Config = {
  user?: { [key: string]: string } | any;
  partner?: {
    queries: string;
    name: string;
    config?: any;
  } | null;
};

type HeroProps = {
  kpiManager?: () => void | false;
  switchAllowed?: boolean;
};
const RenderUserData: FC<UserData> = ({
  user,
  kpiManager,
  switchAllowed,
  partner,
  lastKpisUpdate,
}: UserData) => {
  const { t } = useTranslation();
  return (
    <Row justify="space-between">
      <Col>
        <S.Container left>
          {user?.firstName && (
            <S.Name level={1}>
              {t("hero.welcome")} {user.firstName.toLowerCase()}
            </S.Name>
          )}
          {user?.company && (
            <S.Company>
              {t("hero.company")} {user.company}
            </S.Company>
          )}
        </S.Container>
      </Col>
      <Col>
        <S.Container>
          {!switchAllowed && user.level && (
            <S.Level>
              {t("hero.level")} <strong>{user.level || "unknown"}</strong>
            </S.Level>
          )}
          {switchAllowed && (
            <S.Level overview>
              {partner?.name ? (
                <strong>{partner.name}</strong>
              ) : (
                <strong>Name Company Overwiew</strong>
              )}
            </S.Level>
          )}
          {switchAllowed && (
            <S.Switch onClick={() => kpiManager && kpiManager()}>
              <S.Icon />
              switch company
            </S.Switch>
          )}
          {lastKpisUpdate && (
            <S.Update>
              {t("hero.update")}: {lastKpisUpdate}
            </S.Update>
          )}
        </S.Container>
      </Col>
    </Row>
  );
};

RenderUserData.defaultProps = {
  kpiManager: undefined,
  user: undefined,
  switchAllowed: false,
  partner: null,
};

function updateDate({ user, partner }: Config) {
  if (partner?.config?.lastUpdateKpis)
    return moment(partner.config.lastUpdateKpis).format("MMMM YYYY");
  return user?.lastUpdateKpis
    ? moment(user.lastUpdateKpis).format("DD/MM/YYYY")
    : null;
}

const Hero: FC<HeroProps> = ({ kpiManager, switchAllowed }: HeroProps) => {
  const { config, partner } = useContext(AppContext);
  return (
    <S.Wrapper>
      {config?.user && (
        <RenderUserData
          switchAllowed={switchAllowed}
          user={config?.user}
          kpiManager={kpiManager}
          partner={partner}
          lastKpisUpdate={updateDate({ user: config?.user, partner })}
        />
      )}
    </S.Wrapper>
  );
};

Hero.defaultProps = {
  kpiManager: undefined,
  switchAllowed: false,
};

export default Hero;
