import React, { ReactElement } from "react";
import * as S from "components/Market/style";

const renderProd = (array: string[], key: number): ReactElement => (
  <S.CellTitle key={key.toString()}>
    {/*
    Each string in array set in
    Country is at the first place and must not be wrapped
    we add white-space: nowrap in './styles' and add a <br /> tag
    to wrap after the country.

    Array can only contain 2 or 3 strings.
    We know that the last string is not the country.
    eg: ['UNITED','KINGDOM','CHANNEL'] or ['GERMANY', 'CHANNEL']
    and result should be United Kingdom <br /> Channel or Germany <br /> Channel
    To add the wrapping tag, While mapping we check if the 1st or 2nd element is going to be insert in JS.
    This will get the 1st character if length is equal 2
    or 2nd if length is equal 3 and then adding the tag just after the element.
    */}
    {array.map((string, index) =>
      array.length - 2 === index ? (
        <>
          {string}
          <br />
        </>
      ) : (
        string
      )
    )}
  </S.CellTitle>
);

const renderColor = (array: string[]): ReactElement => (
  <S.ChartLabel>
    {/*
    Each string in array set in
    Country is at the first place and must not be wrapped
    we add white-space: nowrap in './styles' and add a <br /> tag
    to wrap after the country

    Array can only contain 2 or 3 strings.
    We know that the last string is not the country.
    eg: ['UNITED','KINGDOM','CHANNEL'] or ['GERMANY', 'CHANNEL']
    and result should be United Kingdom <br /> Channel or Germany <br /> Channel
    To add the wrapping tag, While mapping we check if the 1st or 2nd element is going to be insert in JS.
    This will get the 1st character if length is equal 2
    or 2nd if length is equal 3 and then adding the tag just after the element.
    */}
    {array.map((string, index) =>
      array.length - 2 === index ? (
        <>
          {string}
          <br />
        </>
      ) : (
        string
      )
    )}
  </S.ChartLabel>
);

export default function ChartTitle({
  type,
  text,
  index,
}: {
  type: "prod" | "color";
  text: string;
  index: number;
}) {
  // Retrieving the character string in an array
  const array = text.split(" ");
  if (array !== undefined && type === "prod") return renderProd(array, index);
  if (array !== undefined && type === "color") return renderColor(array);

  // If the string doesn't contain space (1 word only)
  return type === "prod" ? (
    <S.CellTitle key={index.toString()}>{text}</S.CellTitle>
  ) : (
    <S.ChartLabel>{text}</S.ChartLabel>
  );
}
