import React, { FC } from "react";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";

import { PanesProps } from "types/panes";
import * as S from "./style";

const { TabPane } = Tabs;

interface TabContainerProps {
  panes: PanesProps;
}

const TabsContainer: FC<TabContainerProps> = ({ panes }: TabContainerProps) => {
  const { t } = useTranslation();
  const [active, setActive] = React.useState<boolean>(false);
  return (
    <>
      <S.RespButton onClick={() => setActive(!active)}>
        <S.RespIcon active={active} />
      </S.RespButton>
      <S.Pannel
        active={active}
        defaultActiveKey="0"
        centered
        onTabClick={() => setActive(!active)}
      >
        {panes.map((tab, index) => (
          <TabPane tab={t(tab.title)} key={index.toString()}>
            {tab.component}
          </TabPane>
        ))}
      </S.Pannel>
    </>
  );
};

export default TabsContainer;
