import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const lang = [
  "fr",
  "en",
  "de",
  "es",
  "it",
  "nl",
  "portuguese",
  "at_german",
  "sw",
  "no",
  "dk",
  "fi",
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    whitelist: lang,
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/translate.json",
      requestOptions: {
        cache: "no-store",
      },
    },
  });

export default i18n;
