import React, { FC, useContext } from "react";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";

import { useWindowDimensions } from "hooks";
import { AppContext } from "providers";
import * as S from "./style";

interface LetterProps {
  markdown: string | undefined;
}

const Letter: FC<LetterProps> = ({ markdown }: LetterProps) => {
  const { t } = useTranslation();
  const { config, director } = useContext(AppContext);
  const dimensions = useWindowDimensions();

  return (
    <S.Wrapper>
      <S.Head style={{ textTransform: "capitalize" }}>
        {config?.user?.gender === "female"
          ? t("welcome.letter.female")
          : t("welcome.letter.male")}{" "}
        {config?.user?.firstName},
      </S.Head>
      <S.Content column={dimensions.width >= 1200}>
        <ReactMarkdown
          escapeHtml={false}
          renderers={{ text: S.MdParagraph, strong: S.MdStrong }}
        >
          {markdown}
        </ReactMarkdown>
        {director?.signature && (
          <img
            alt="signature"
            src={director?.signature}
            style={{ maxWidth: 362, objectFit: "contain" }}
          />
        )}
      </S.Content>
    </S.Wrapper>
  );
};

export default Letter;
