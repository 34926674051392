import { Typography } from "antd";

import { styled } from "theme/theme";

type PercentPops = {
  color: string;
};

const { Text } = Typography;

export const Wrapper = styled.div`
  padding: 8px 14px;
  background: ${(props) => props.theme.color.lightAccent};
  box-shadow: 0 3px 6px ${(props) => props.theme.color.info}30;
`;

export const Content = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Title = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.color.info};
`;

export const Percent = styled(Text)`
  margin-right: 6px;
  font-size: 16px;
  font-weight: 700;
  color: ${(fill: PercentPops) => fill.color};
`;

export const Value = styled(Text)`
  margin-right: 3px;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.color.info}80;
`;

export const Unit = styled(Text)`
  font-size: 12px;
  color: ${(props) => props.theme.color.info}80;
`;

export const Arrow = styled.img``;

export const Evol = styled(Text)`
  margin-left: 6px;
  font-size: 20px;
  font-weight: 700;
  color: ${(fill: PercentPops) => fill.color};
`;

export const Spacer = styled.div`
  height: 21px;
  margin-left: 8px;
  margin-right: 5px;
  width: 1px;
  background: ${(props) => props.theme.color.info}80;
`;

export const DualValues = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
