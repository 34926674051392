import { AxiosPromise } from "axios";

import api, { dev, admin, proc } from "utils/api-common";
import { error } from "./notify";

const get = (endpoint: string, token: string): AxiosPromise => {
  return api.get(endpoint, { headers: { Authorization: `Bearer ${token}` } });
};

const post = (endpoint: string, token: string): AxiosPromise => {
  return api.post(endpoint, { headers: { Authorization: `Bearer ${token}` } });
};

const getAll = (
  type: string,
  endpoints: any[],
  token: string,
  callback: (res: any) => void
): Promise<any> => {
  if (type === "POST") {
    const promiseArr = endpoints.map(({ route, name, partner }) =>
      api
        .post(
          `${route}${partner?.queries || ""}`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          return { ...res, name };
        })
        .catch(() => {
          error(`Unable to load ${name} with route ${route}`);
        })
    );
    return Promise.all(promiseArr).then((res) => callback(res));
  }
  const promiseArr = endpoints.map(({ route, name }) =>
    api
      .get(route, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        return { ...res, name };
      })
  );
  return Promise.all(promiseArr).then((res) => callback(res));
};

const getLocal = (endpoint: string, token: string): AxiosPromise => {
  return dev.get(endpoint, { headers: { Authorization: `Bearer ${token}` } });
};

const retriveMessage = (endpoint: string): AxiosPromise => {
  return admin.get(endpoint);
};

const createPDF = (
  endpoint: string,
  data: { [key: string]: number[] },
  token: string
): AxiosPromise => {
  const headers = {
    "Content-Type": "application/pdf",
    Authorization: `Bearer ${token}`,
    Accept: "application/pdf",
  };
  return api.post(endpoint, data, {
    headers,
  });
};

const delay = (
  type: string,
  endpoint: string,
  data: { period: number } | Record<string, unknown>
): AxiosPromise => {
  if (type === "GET") return admin.get(endpoint);
  return admin.patch(endpoint, data);
};

const drop = (endpoint: string, data: any): AxiosPromise => {
  return admin.post(endpoint, data, {
    headers: {
      "Content-type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  });
};

const getXlsx = (token: string, userId: string, type: string): AxiosPromise => {
  let route;

  if (type === "eos") {
    route = proc.eos.export.route;
  } else if (type === "connectivity") {
    route = proc.connectivity.export.route;
  } else {
    route = proc.mif.export.route;
  }

  return api.get(`${route}${userId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
  });
};

const auth = (
  endpoint: string,
  cred: { [key: string]: string }
): AxiosPromise => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return api.post(`${process.env.REACT_APP_DEVELOP_AUTH}${endpoint}`, cred);
  }
  return api.post(`../auth/jwt/${endpoint}`, cred);
};

const reset = (data: { [key: string]: any }): AxiosPromise => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return api.post(
      `${process.env.REACT_APP_DEVELOP_AUTH}/password/reset`,
      data
    );
  }
  return api.post(`../auth/jwt/password/reset`, data);
};

const save = (data: { [key: string]: any }): AxiosPromise => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return api.post(
      `${process.env.REACT_APP_DEVELOP_AUTH}/password/save`,
      data
    );
  }
  return api.post(`../auth/jwt/password/save`, data);
};

export default {
  auth,
  get,
  post,
  getAll,
  getXlsx,
  getLocal,
  retriveMessage,
  createPDF,
  delay,
  drop,
  reset,
  save,
};
