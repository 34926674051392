import React, { FC, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col, Row } from "antd";

import Global from "theme/global";
import { AppContext } from "providers";
import { AdminCards } from "components";
import { proc } from "utils/api-common";

const Admin: FC = () => {
  const {
    mounted,
    mounting,
    updateDelay,
    currentPeriod,
    retriveWM,
    dropXlsx,
  } = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    if (!mounted) mounting(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  useEffect(() => {
    localStorage.setItem("from", history.location.pathname);
  }, [history]);

  const request = async () => {
    await updateDelay(
      proc.admin.requestPeriod.type,
      {
        route: proc.admin.requestPeriod.route,
        name: proc.admin.requestPeriod.name,
      },
      {}
    );
  };

  const update = async (value: number) => {
    await updateDelay(
      proc.admin.updatePeriod.type,
      {
        route: proc.admin.updatePeriod.route,
        name: proc.admin.updatePeriod.name,
      },
      { period: value }
    );
  };

  const retrieve = async () => {
    await retriveWM(proc.admin.exportMessage.route);
  };

  const drop = async (file: any) => {
    await dropXlsx(proc.admin.importMessage.route, file);
  };

  return (
    <Global.Container style={{ paddingBottom: 100 }}>
      <Global.Render>
        <Global.Wrapper transparent>
          <Row gutter={[36, 36]} justify="center">
            <Col md={24} lg={12} xl={11}>
              <AdminCards
                normalized
                title="Business Review retention period"
                content="Please define the retention period allowing Xerox members to view the BR before their partners"
                button="Validate"
                period={{
                  currentPeriod,
                  update,
                  request,
                }}
                type="br"
              />
            </Col>
            <Col md={24} lg={12} xl={11}>
              <AdminCards
                normalized
                title="Welcome Message translations"
                content="Please export the initial document and import your translation below"
                button="Validate"
                type="wm"
                actions={{ retrieve, drop }}
              />
            </Col>
          </Row>
        </Global.Wrapper>
      </Global.Render>
    </Global.Container>
  );
};

export default Admin;
