import { FC } from "react";
import { Typography, Button, Avatar, Input } from "antd";
import { TitleProps } from "antd/lib/typography/Title";
import { TextProps } from "antd/lib/typography/Text";
import { ButtonProps } from "antd/lib/button";
import { AvatarProps } from "antd/lib/avatar";
import { InputProps } from "antd/lib/input";
import { MdGTranslate } from "react-icons/md";
import { FiDownloadCloud } from "react-icons/fi";
import { IconBaseProps } from "react-icons";

import { styled } from "theme/theme";

type ContactProps = {
  padding?: boolean;
};

type CardProps = {
  normalized?: boolean;
  height?: number;
};

interface BtnProps extends ButtonProps {
  color: string;
}

interface PictureProps extends AvatarProps {
  src: any;
}

export const { Title, Text } = Typography;

export const Card = styled.div`
  display: flex;
  height: ${(style: CardProps) =>
    // eslint-disable-next-line no-nested-ternary
    style.height ? `${style.height}px` : style.normalized ? "auto" : "150px"};
  width: 100%;
  flex-direction: ${(style: CardProps) =>
    style.normalized ? "column" : "row"};
  justify-content: ${(style: CardProps) =>
    style.normalized ? "center" : "flex-start"};
  padding: ${(style: CardProps) => (style.normalized ? "0px 70px" : 0)};
  background-color: ${(style: CardProps) =>
    style.normalized
      ? (props) => props.theme.color.lightAccent
      : (props) => props.theme.color.variant};
`;

export const Picture: FC<PictureProps> = styled(Avatar)`
  background-color: ${(props) => props.theme.color.lightAccent};
`;

export const UserCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 150px;
  width: calc(536px - 212px);
  padding: 20px;
  background-color: ${(props) => props.theme.color.alternative};
`;

export const UserName: FC<TitleProps> = styled(Title)`
  font-size: 40px;
  font-weight: 300;
  text-align: left;
  color: ${(props) => props.theme.color.variant} !important;
`;

export const UserCompany: FC<TextProps> = styled(Text)`
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  color: ${(props) => props.theme.color.white};
`;

export const StatusCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 212px;
  background-color: ${(props) => props.theme.color.variant};
`;

export const StatusInfo: FC<TextProps> = styled(Text)`
  font-size: 20px;
  font-weight: 300;
  color: ${(props) => props.theme.color.white};
`;

export const StatusType: FC<TitleProps> = styled(Title)`
  font-size: 27px;
  font-weight: 300;
  color: ${(props) => props.theme.color.white} !important;
`;

export const ProfileName: FC<TitleProps> = styled(Title)`
  font-size: 20px;
  color: ${(props) => props.theme.color.alternative} !important;
`;

export const ProfileJob: FC<TextProps> = styled(Text)`
  font-size: 16px;
  text-align: left;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: calc(100% - 150px);
  padding: 30px;
  background-color: ${(props) => props.theme.color.lightShade};
`;

export const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0;
  background-color: ${(props) => props.theme.color.orange};
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(has: ContactProps) =>
    has.padding ? "10px 50px" : "10px"} !important;
`;

export const ContactTitle: FC<TitleProps> = styled(Title)`
  font-size: 40px;
  margin-top: 0;
  font-weight: 300 !important;
  text-align: center;
  color: ${(props) => props.theme.color.darkShade} !important;
`;

export const ContactName: FC<TextProps> = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.color.white};
`;

export const ContactEmail: FC<TextProps> = styled(Text)`
  font-size: 20px;
  font-weight: 300;
  color: ${(props) => props.theme.color.primary};
`;

export const DiscoverTitle: FC<TitleProps> = styled(Title)`
  font-size: 50px !important;
  font-weight: 300 !important;
  text-align: left;
  margin-top: 0 !important;
  color: ${(props) => props.theme.color.info} !important;
`;

export const DiscoverContent: FC<TextProps> = styled(Text)`
  font-size: 24px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 30px;
  color: ${(props) => props.theme.color.info} !important;
`;

export const DiscoverButton: FC<BtnProps> = styled(Button)`
  background-color: ${(style: BtnProps) => style.color} !important;
  color: ${(props) => props.theme.color.white} !important;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 16px;
  padding: 10px;
  height: 54px;
  width: 220px;
  border-radius: 0;
  border: none;
`;

export const AdminTitle: FC<TitleProps> = styled(Title)`
  font-size: 50px !important;
  font-weight: 300 !important;
  text-align: left;
  margin-top: 0 !important;
  color: ${(props) => props.theme.color.info} !important;
`;

export const AdminContent: FC<TextProps> = styled(Text)`
  font-size: 24px;
  font-weight: 300;
  text-align: left;
  margin-bottom: 30px;
  color: ${(props) => props.theme.color.info} !important;
`;

export const AdminButton: FC<ButtonProps> = styled(Button)`
  background-color: ${(props) => props.theme.color.mainBrand} !important;
  color: ${(props) => props.theme.color.white} !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  width: 240px;
  padding: 12px 20px;
  height: 44px;
  border-radius: 0;
  border: none;
`;

export const Delay: FC<InputProps> = styled(Input)`
  padding: 12px 20px;
  max-width: 240px;
  margin: 30px 0;
  outline: none;
  background-color: ${(props) => props.theme.color.lightAccent};
  color: ${(props) => props.theme.color.info};
  border: 1px solid ${(props) => props.theme.color.lightShade};
`;

export const InputGroup = styled("div")`
  display: flex;
  flex-direction: column;
`;

type UploadProps = {
  uploaded: boolean;
};

export const Upload = styled("label")`
  background-color: ${(u: UploadProps) =>
    u.uploaded
      ? (props) => props.theme.color.success
      : (props) => props.theme.color.lightAccent};
  color: ${(u: UploadProps) =>
    u.uploaded
      ? (props) => props.theme.color.lightAccent
      : (props) => props.theme.color.info};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  margin-top: 12px;
  padding: 12px 20px;
  width: 240px;
  border: 1px solid
    ${(u: UploadProps) =>
      u.uploaded
        ? (props) => props.theme.color.success
        : (props) => props.theme.color.info};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(u: UploadProps) => (u.uploaded ? 1 : 0.3)};
  transition: 300ms;
  :hover {
    opacity: 0.8;
  }
`;

export const UploadFile = styled("input")`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const Download = styled("button")`
  background-color: ${(props) => props.theme.color.lightShade};
  color: ${(props) => props.theme.color.info};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  padding: 12px 20px;
  margin-top: 24px;
  width: 240px;
  border: 1px solid ${(props) => props.theme.color.lightShade};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  transition: 300ms;
  :hover {
    border: 1px solid ${(props) => props.theme.color.info};
  }
`;

export const TranslateIcon: FC<IconBaseProps> = styled(MdGTranslate)`
  color: inherit;
  font-size: 20px;
  margin-right: 12px;
`;

export const DownloadIcon: FC<IconBaseProps> = styled(FiDownloadCloud)`
  color: ${(props) => props.theme.color.info};
  font-size: 20px;
  margin-right: 12px;
`;
