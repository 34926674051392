import React, { FC, useState, useContext, useEffect } from "react";
import {
  BrowserRouter,
  Redirect as RouterRedirect,
  Route,
  Switch,
  RouteProps,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";

import {
  Home,
  Welcome,
  Redirect,
  Performance,
  Auth,
  Reset,
  Changelog,
  Admin,
  Business,
} from "views";
import { Header, Footer, Loader } from "components";
import { darkTheme, lightTheme } from "theme/theme";
import { StoreProvider, AppContext } from "providers";
import { Notifier } from "services/notify";
import routes, { access } from "./routes";

interface PrivateProps extends RouteProps {
  children: React.ReactNode;
  index: string;
}

type LoadingProps = {
  children: React.ReactNode;
};

const WaitOnLoad: FC<LoadingProps> = ({ children }: LoadingProps) => {
  const { config, token, mounted } = useContext(AppContext);
  let loading = false;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (token && !config) loading = true;
  }, [token, config]);
  if (loading) {
    return <Loader wrap />;
  }
  return (
    <>
      <Header />
      {children}
      <Footer displayed={mounted} />
    </>
  );
};

const PrivateRoute: FC<PrivateProps> = ({
  children,
  index,
  ...rest
}: PrivateProps) => {
  const { token, config } = useContext(AppContext);
  React.useEffect(() => {}, [config]);
  if (config?.authorizations?.pages[index] || index === "admin")
    return (
      <Route
        {...rest}
        render={() =>
          token &&
          (config?.authorizations?.pages[index] || index === "admin") ? (
            children
          ) : (
            <RouterRedirect
              to={{
                pathname: routes.AUTH,
              }}
            />
          )
        }
      />
    );
  return <Loader wrap />;
};

const Router: FC = () => {
  const [theme] = useState("light");
  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <BrowserRouter>
        <StoreProvider>
          <WaitOnLoad>
            <Switch>
              <Route exact path={routes.AUTH}>
                <Auth />
              </Route>
              <Route exact path={routes.REDIRECT}>
                <Redirect />
              </Route>
              <Route exact path={routes.RESET}>
                <Reset />
              </Route>
              <Route exact path={routes.ROOT}>
                <RouterRedirect exact to={routes.HOME} />
              </Route>
              <PrivateRoute exact path={routes.HOME} index={access.HOME}>
                <Home />
              </PrivateRoute>
              <PrivateRoute exact path={routes.WELCOME} index={access.WELCOME}>
                <Welcome />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={routes.PERFORMANCE}
                index={access.PERFORMANCE}
              >
                <Performance />
              </PrivateRoute>
              <PrivateRoute
                exact
                path={routes.BUSINESS_REVIEW}
                index={access.BUSINESS_REVIEW}
              >
                <Business />
              </PrivateRoute>

              <PrivateRoute exact path={routes.ADMIN} index={access.ADMIN}>
                <Admin />
              </PrivateRoute>
              <Route exact path={routes.CHANGELOG}>
                <Changelog />
              </Route>
              <RouterRedirect to={routes.ROOT} />
            </Switch>
          </WaitOnLoad>
        </StoreProvider>
      </BrowserRouter>
      <Notifier />
    </ThemeProvider>
  );
};

export default Router;
