import React, { FC, useContext, useRef, useState } from "react";
import { Col, Row, Skeleton } from "antd";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTranslation } from "react-i18next";

import Icon from "assets/icons/Legend";
import { KpiProps } from "types/kpi";
import { AppContext } from "providers";
import { useFormatter, useContainerDimensions } from "hooks";
import * as S from "./style";

type PeriodType = { name: number; count: number };

const Asp: FC = () => {
  const { t } = useTranslation();
  const { kpi }: KpiProps = useContext(AppContext);
  const chartRef = useRef(null);
  const [max, setMax] = useState<number>(1000);
  const dimensions = useContainerDimensions(chartRef);
  const [yearsOcc, setYearsOcc] = useState<{
    pre: PeriodType;
    post: PeriodType;
  } | null>(null);
  const { formatValue } = useFormatter();

  const months = t("global.month", { returnObjects: true });

  React.useEffect(() => {
    // eslint-disable-next-line prefer-spread
    const entry = Math.max.apply(
      Math,
      kpi?.asp?.data.map((o: any) => {
        return o.CONSUMABLES;
      })
    );
    // eslint-disable-next-line prefer-spread
    const mid = Math.max.apply(
      Math,
      kpi?.asp?.data.map((o: any) => {
        return o.SPARES;
      })
    );
    let maxValues = [entry, mid];
    maxValues = maxValues.sort((a, b) => a - b);
    setMax(maxValues[1]);

    if (kpi?.asp?.data) {
      const { data } = kpi.asp;
      let lastYear = 0;
      let nextYear = 0;

      data.forEach((item: { [key: string]: string }, index: number) => {
        if (index < 1) {
          lastYear = parseInt(item.YEAR, 0);
        }

        if (parseInt(item.YEAR, 0) !== lastYear) {
          nextYear = parseInt(item.YEAR, 0);
        }
      });

      const countLast = data.filter(
        (obj: { [k: string]: string }) => parseInt(obj.YEAR, 0) === lastYear
      ).length;

      const countNext = data.filter(
        (obj: { [k: string]: string }) => parseInt(obj.YEAR, 0) === nextYear
      ).length;

      setYearsOcc({
        pre: { name: lastYear, count: countLast },
        post: { name: nextYear, count: countNext },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpi.asp]);

  return (
    <Row gutter={[16, 16]}>
      <Col lg={4} xs={24}>
        {!kpi?.asp?.data && (
          <Skeleton.Input
            style={{
              width: 274,
              height: 400,
            }}
            active
            size="large"
          />
        )}
        {kpi?.asp?.data && (
          <>
            <S.Legend>
              <Icon fill="#f67e00" />
              <S.LegendData>
                <S.LegendTitle>{t("kpis.asp.legend.spears")}</S.LegendTitle>
                <S.LegendHorizontal>
                  <S.LegendValue>{kpi?.trends?.data?.CA_SPARE}</S.LegendValue>
                  <S.ProgressBarValue color="#f67e00">
                    {Math.round(kpi?.trends?.data?.PERCENT_SPARE)}%
                  </S.ProgressBarValue>
                </S.LegendHorizontal>
                <S.ProgressBar
                  percent={Math.round(kpi?.trends?.data?.PERCENT_SPARE)}
                  strokeColor="#f67e00"
                />
              </S.LegendData>
            </S.Legend>
            <S.Legend>
              <Icon fill="#27800e" />
              <S.LegendData>
                <S.LegendTitle>{t("kpis.asp.legend.consumable")}</S.LegendTitle>
                <S.LegendHorizontal>
                  <S.LegendValue>
                    {kpi?.trends?.data?.CA_CONSUMABLE}
                  </S.LegendValue>
                  <S.ProgressBarValue color="#27800e">
                    {Math.round(kpi?.trends?.data?.PERCENT_CONSUMABLE)}%
                  </S.ProgressBarValue>
                </S.LegendHorizontal>
                <S.ProgressBar
                  percent={Math.round(kpi?.trends?.data?.PERCENT_CONSUMABLE)}
                  strokeColor="#27800e"
                />
              </S.LegendData>
            </S.Legend>
          </>
        )}
      </Col>
      <Col lg={20} xs={24}>
        {!kpi?.asp?.data && (
          <Skeleton.Input
            style={{
              width: 1000,
              height: 400,
              marginLeft: 30,
            }}
            active
            size="large"
          />
        )}
        {kpi?.asp?.data && (
          <div ref={chartRef}>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={kpi?.asp?.data}>
                <XAxis
                  dataKey="MONTH"
                  tickFormatter={(m: any) => months[m - 1]}
                />
                <YAxis
                  tickFormatter={(v: any) => formatValue(v)}
                  type="number"
                  domain={[0, max + 10000]}
                />
                <CartesianGrid vertical={false} />
                <Tooltip
                  contentStyle={{ fontWeight: "bold", borderRadius: 3 }}
                  label="null"
                  labelStyle={{ display: "none" }}
                  labelFormatter={(m: any) => months[m - 1]}
                  formatter={(value: any, name: any) => [
                    formatValue(value),
                    parseFloat(name),
                  ]}
                  viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
                />
                <Line
                  type="monotone"
                  dataKey="SPARES"
                  stroke="#f67e00"
                  strokeWidth={2}
                />
                <Line
                  type="monotone"
                  dataKey="CONSUMABLES"
                  stroke="#5e9850"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
            {yearsOcc?.pre && yearsOcc?.post && dimensions && (
              <S.Underline dimensions={dimensions}>
                <S.Year months={yearsOcc.pre.count}>
                  {yearsOcc.pre.name || ""}
                </S.Year>
                <S.Year months={yearsOcc.post.count}>
                  {yearsOcc.post.name || ""}
                </S.Year>
              </S.Underline>
            )}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default Asp;
