import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import Loader from "./components/Loader";
import GlobalStyle from "./theme/theme";
import Router from "./router";
import "./lang";

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <Suspense fallback={<Loader wrap />}>
      <Router />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
