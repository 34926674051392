import converter from "services/converter";

function b64toUri(pdf: string, ctx: any) {
  const contentType = "application/pdf";
  const b64Data = pdf.replace("data:application/pdf;base64,", "");
  const blob = converter(b64Data, contentType);
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.target = "pdf-frame";
  link.id = "";
  document.body.appendChild(link);
  link.click();
  link.remove();
  ctx({ loading: false, data: null, type: null });
}

function saveAll(pdf: string, ctx: any) {
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = `br-${Date.now()}.zip`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
  ctx({ loading: false, data: null, type: null });
}

function save(pdf: string, ctx: any) {
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = `br-${Date.now()}.pdf`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
  ctx({ loading: false, data: null, type: null });
}

export default function usePreview(): any {
  const preview = (base64URL: string, ctx: any) => {
    return b64toUri(base64URL, ctx);
  };
  const download = (base64URL: string, ctx: any) => {
    return save(base64URL, ctx);
  };
  const downloadAll = (base64URL: string, ctx: any) => {
    return saveAll(base64URL, ctx);
  };
  return { preview, download, downloadAll } as const;
}
