import React, { FC, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { useQuery } from "hooks";
import { proc } from "utils/api-common";
import { error, success } from "services/notify";
import ApiService from "services/request";
import { AppContext } from "providers";
import routes from "router/routes";
import Logo from "assets/xerox_logo.svg";
import Filters, { Partners } from "components/Filters";
import * as S from "./style";

export const AuthForm: FC = () => {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  const [uid, setUid] = useState<string>("");
  const [pwd, setPwd] = useState<string>("");
  const [visibility, toggleVisibility] = useState<boolean>(false);
  const [recoveryCred, setRecoveryCred] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const submit = () => {
    const errors: string[] = [];
    const regex = /[!@#$%^&*(),.?":{}|<>]/g;
    if (!uid) errors.push("User ID is required");
    if (!pwd) errors.push("Password is required");
    if (uid.match(regex))
      errors.push("User Id should not contain special characters");
    if (uid.length < 3 || pwd.length < 3)
      errors.push("User Id and password should be upper than 3 characters");
    if (errors.length >= 1) return error(errors.join(". "));
    try {
      context.auth({ username: uid, password: pwd });
    } catch (e) {
      error(e.message);
    }
    return false;
  };

  const handleRecover = () => {
    setLoading(true);
    if (
      !recoveryCred ||
      recoveryCred.length <= 3 ||
      recoveryCred.includes("@") ||
      !recoveryCred.toUpperCase().includes("XRX")
    ) {
      setLoading(false);
      return error("User ID is unknown. Please try again!");
    }
    ApiService.reset({
      username: recoveryCred.toUpperCase(),
      subject: "Reset your password",
      fromName: "Xerox - Just For You",
      fromEmail: "noreply@xeroxjustforyou.com",
      replyTo: "noreply@xeroxjustforyou.com",
      redirectUrl: `https://${window.location.host}/reset`,
      templateUrl:
        "https://xerox-justforyou.loyaltyexpert.net/api/templates/reset_password_email_template.html",
    })
      .then(() => success("An email has been sent to user"))
      .catch((e: any) => {
        if (e.message === "Request failed with status code 401")
          error("User ID is unknown. Please try again!");
        setRecoveryCred("");
      });
    return new Promise(() => {
      setTimeout(() => {
        toggleVisibility(false);
        setLoading(false);
      }, 1000);
    }).catch((err) => error(err.message));
  };

  const handleCancel = () => {
    toggleVisibility(false);
  };

  return (
    <S.Centered>
      <S.Container>
        <S.Brand src={Logo} />
        <S.Subtitle>{t("global.title")}</S.Subtitle>
        <S.Email
          onChange={(e) => setUid(e.target.value)}
          placeholder={t("authentication.userid")}
        />
        <S.Password
          onChange={(e) => setPwd(e.target.value)}
          onPressEnter={() => submit()}
          placeholder={t("authentication.password")}
        />
        <S.More>
          <S.Action onClick={() => toggleVisibility(true)}>
            {t("authentication.forgot_password")}
          </S.Action>
        </S.More>
        <S.Check>{t("authentication.stay_logged")}</S.Check>
        <S.Submit onClick={() => submit()}>
          {t("authentication.submit")}
        </S.Submit>
      </S.Container>
      <S.Recovery
        title={t("authentication.forgot_password")}
        visible={visibility}
        centered
        confirmLoading={loading}
        cancelText={t("authentication.cancel")}
        okText={t("authentication.submit")}
        onOk={() => handleRecover()}
        onCancel={() => handleCancel()}
      >
        <S.Recover
          type="text"
          onChange={(e) => setRecoveryCred(e.target.value)}
          placeholder={t("authentication.userid")}
          suffix={
            <Tooltip title="Your Xerox username. Remark, your email is not your username.">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />
      </S.Recovery>
    </S.Centered>
  );
};

type ManagerProps = {
  submitPartner: () => void;
  allowed: { [key: string]: boolean } | undefined;
};

type FiltersProps = any;

export const KpisManager: FC<ManagerProps> = ({
  submitPartner,
  allowed,
}: ManagerProps) => {
  const [stateLoading, setStateLoading] = useState<boolean>(false);
  const {
    requestFilters,
    filters,
    updatePartner,
    partner,
    config,
  } = useContext(AppContext);
  const [allowedFilters, setAllowedFilters] = useState<FiltersProps>([]);
  const [topLvl, setTopLvl] = useState<string | null>(null);
  const [filterState, setFilterState] = useState<any>(null);
  const [filterToUpdate, setFilterToUpdate] = useState<string | null>(null);
  const [filterToRemove, setFilterToRemove] = useState<string | null>(null);
  const [initialState, setInitialState] = useState<any>({
    init: true,
    values: {},
  });
  const [resetState, forceReset] = useState<boolean>(false);
  const hierarchy: string[] = ["country", "cgm", "cbm", "partners"];

  // Add partners name and the queries to the context
  React.useEffect(() => {
    if (filterState?.partners) {
      const partnersList = allowedFilters.filter(
        (word: { name: string; drop: { [k: string]: string }[] }) =>
          word.name === "partners"
      );
      const currentPartner = partnersList[0].drop.filter(
        (el: { label: string; value: number }) =>
          el.value === filterState.partners[0]
      );
      if (currentPartner[0]?.label) {
        updatePartner({
          queries: `?userId=${filterState.partners[0]}`,
          name: currentPartner[0]?.label,
        });
      } else {
        // TODO: User need to dble ckick to remove selection
        // We need to get the current filter name he want
        // to remove and give it to SetFilterToRemove state
        updatePartner(null);
        // setFilterToUpdate(null);
        setFilterToRemove(null);
        // setFilterState(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  // call values to the child level
  React.useEffect(() => {
    if (filterState) {
      const queries: string[] = [];
      const translator: { [k: string]: string } = {
        country: "country",
        cgm: "cgm",
        cbm: "cbm",
      };
      let toFilter: string | boolean = "";
      Object.keys(filterState).forEach((el) => {
        queries.push(`${translator[el]}=${filterState[el][0]}`);
        toFilter = el;
        if (el !== "partners") {
          setFilterToUpdate(
            hierarchy[
              Object.keys(translator).findIndex((key) => key === el) + 1
            ]
          );
        } else setFilterToUpdate(null);
      });
      toFilter =
        // eslint-disable-next-line no-nested-ternary
        toFilter === "country"
          ? "country"
          : toFilter === "cgm"
          ? "cbm"
          : toFilter === "cbm" && "partners";
      requestFilters({
        ...proc.filters,
        route: `${proc.filters.route}?filter=${toFilter}&${queries.join("&")}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  // Add values to the child level
  React.useEffect(() => {
    if (filterToUpdate) {
      const drop: any = [];
      filters.data[filterToUpdate].forEach(
        (el: { id: number; name: string }) => {
          drop.push({ value: el.id, label: el.name });
        }
      );
      const entry = allowedFilters.find((o: any) => o.name === filterToUpdate);
      if (entry) entry.drop = drop;
      const updatedFilters = allowedFilters.filter((obj: any) => {
        return obj.name !== topLvl;
      });
      setAllowedFilters([...updatedFilters, { name: topLvl, drop }]);
      setFilterToUpdate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // Initialize firsts values
  React.useEffect(() => {
    const found = [];
    let initializedRequest = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const filter in allowed) {
      if (allowed[filter]) {
        found.push({ name: filter, drop: [] });
        // eslint-disable-next-line no-loop-func
        hierarchy.forEach((lvl) => {
          if (lvl === filter && !initializedRequest) {
            setTopLvl(lvl);
            initializedRequest = true;
            setStateLoading(true);
            requestFilters({
              ...proc.filters,
              route: `${proc.filters.route}?filter=${filter}`,
            });
          }
        });
      }
    }
    setAllowedFilters(found);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowed]);

  // Add values to the top level filter
  React.useEffect(() => {
    if (filters.data) {
      const emptyArray = [];
      let drop: any = [];
      const object: any = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key in filters.data) {
        if (topLvl) {
          if (topLvl === "country" && key === "country") {
            drop = [];
            // eslint-disable-next-line no-loop-func
            filters.data[topLvl].forEach((el: string) => {
              drop.push({ value: el, label: el });
            });
            object.push({ name: key, drop });
          }
          if (
            filters.data[key] !== null &&
            filters.data[key].length > 0 &&
            key !== "country"
          ) {
            drop = [];
            // eslint-disable-next-line no-loop-func
            filters.data[key].forEach((el: { id: number; name: string }) => {
              drop.push({ value: el.id, label: el.name });
            });
            object.push({ name: key, drop });
          }
          if (filters.data[key] === null || filters.data[key].length <= 0) {
            emptyArray.push(key);
          }
        }
      }
      emptyArray.forEach((key: string) => {
        if (config?.authorizations?.filters?.kpis?.[key] === true)
          object.push({ name: key, drop: [] });
      });
      const newObject = [];
      if (config?.authorizations?.filters?.kpis?.country === true)
        newObject.push(object.find((o: any) => o.name === "country"));

      if (config?.authorizations?.filters?.kpis?.cgm === true)
        newObject.push(object.find((o: any) => o.name === "cgm"));

      if (config?.authorizations?.filters?.kpis?.cbm === true)
        newObject.push(object.find((o: any) => o.name === "cbm"));

      if (config?.authorizations?.filters?.kpis?.partners === true)
        newObject.push(object.find((o: any) => o.name === "partners"));

      setAllowedFilters(newObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  React.useEffect(() => {
    if (initialState.length <= 0) setInitialState(allowedFilters);
  }, [allowedFilters, initialState]);

  // Loading while fetching some new values
  React.useEffect(() => {
    setStateLoading(false);
  }, [filters]);

  React.useEffect(() => {
    if (resetState) {
      const found = [];
      let initializedRequest = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const filter in allowed) {
        if (allowed[filter]) {
          found.push({ name: filter, drop: [] });
          // eslint-disable-next-line no-loop-func
          hierarchy.forEach((lvl) => {
            if (lvl === filter && !initializedRequest) {
              setTopLvl(lvl);
              initializedRequest = true;
              setStateLoading(true);
              requestFilters({
                ...proc.filters,
                route: `${proc.filters.route}?filter=${filter}`,
              });
            }
          });
        }
      }
      updatePartner(null);
      setAllowedFilters(found);
      forceReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetState]);

  return (
    <S.Container>
      <S.PanName>View a partner&apos;s KPI</S.PanName>
      <>
        {!allowed?.cbm && allowed?.partners && (
          <Partners
            list={filters?.data?.partners}
            updateManager={setFilterState}
          />
        )}
        {allowed?.cbm && (
          <S.Filters>
            <Filters
              type="fdv"
              data={allowedFilters}
              displayResult
              updateManager={setFilterState}
              updatingFilters={stateLoading}
              forceReset={resetState}
              onRemove={filterToRemove}
            />
          </S.Filters>
        )}
        <S.Reset onClick={() => forceReset(true)}>Reset all filters</S.Reset>
      </>
      <S.Submit disabled={!partner} onClick={() => submitPartner()}>
        See my selection
      </S.Submit>
    </S.Container>
  );
};

export const ResetForm: FC = () => {
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();
  const [conf, setConf] = useState<string>("");
  const [pwd, setPwd] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleReset = () => {
    ApiService.save({
      username: query.get("username"),
      password: pwd,
      resetToken: query.get("resetToken"),
    })
      .then(() => {
        setLoading(false);
        history.push(routes.AUTH);
      })
      .catch((e: any) => {
        setLoading(false);
        error(e.message);
      });
  };

  const submit = () => {
    setLoading(true);
    const errors: string[] = [];
    const regex = RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#_$%^&*])(?=.{8,})"
    );

    if (!regex.test(pwd)) {
      setLoading(false);
      return error("Your password must respect rules below");
    }
    if (pwd !== conf) {
      errors.push("Confirmation should match with your password");
    }
    if (errors.length >= 1) {
      setLoading(false);
      return error(errors.join(". "));
    }
    return handleReset();
  };

  return (
    <S.Centered>
      <S.Container>
        <S.Brand src={Logo} />
        <S.Subtitle>{t("global.title")}</S.Subtitle>
        <S.Rules>
          Your password must contain at least one <strong>uppercase</strong> and
          <strong> lowercase </strong>
          alphabetical character at least one <strong>numeric</strong> character
          and <strong>special </strong>
          character and must be <strong>8 or longer</strong>
        </S.Rules>
        <S.Password
          onChange={(e) => setPwd(e.target.value)}
          onPressEnter={() => submit()}
          placeholder="New password"
        />
        <S.Spacer />
        <S.Password
          onChange={(e) => setConf(e.target.value)}
          onPressEnter={() => submit()}
          placeholder="Confirm your password"
        />
        <S.Submit onClick={() => submit()} loading={loading}>
          {t("authentication.submit")}
        </S.Submit>
      </S.Container>
    </S.Centered>
  );
};
