import { Button } from "antd";

import { styled } from "theme/theme";

const DownloadButton = styled(Button)`
  text-transform: uppercase;
  margin-left: 10px;
  :hover {
    background: ${(props) => props.theme.color.mainBrand};
    border-color: ${(props) => props.theme.color.mainBrand};
    color: #fff;
  }
`;

export default DownloadButton;
