import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Select } from "antd";
import { useVT } from "virtualizedtableforantd4";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import moment from "moment";

import { KpiProps } from "types/kpi";
import { AppContext } from "providers";
import ApiService from "services/request";
import { proc } from "utils/api-common";
import caution from "assets/icons/caution.svg";
import reverse from "assets/icons/reverse.svg";
import * as S from "./style";

type TableData = { [key: string]: string | number }[];

const { Option } = Select;

const scrollConfig = {
  y: 500,
};

const Eos: FC = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [request, setRequest] = useState<boolean>(false);
  const [companies, setCompanies] = useState<any>([]);
  const [dataSource, setDataSource] = useState<any>([]);
  const { kpi }: KpiProps = useContext(AppContext);
  const [selectedFilters, updateSelectedFilters] = useState<any>([]);
  const {
    loadDataByChunk,
    temp,
    eosFilters,
    getEosFilters,
    token,
    fetchKpi,
    partner,
  } = useContext(AppContext);

  const columns = [
    {
      title: t("kpis.eos.tab.company"),
      dataIndex: "company",
    },
    {
      title: t("kpis.eos.tab.install"),
      dataIndex: "install_date",
      render: (text: string) => `${moment(text).format("DD/MM/YYYY")}`,
    },
    {
      title: t("kpis.eos.tab.date"),
      dataIndex: "eos_date",
      render: (text: string) => `${moment(text).format("DD/MM/YYYY")}`,
    },
    {
      title: t("kpis.eos.tab.serial"),
      dataIndex: "serial_number",
    },
    {
      title: t("kpis.eos.tab.product"),
      dataIndex: "product",
      render: (text: string) => (
        <S.TabCustom>
          <S.TabIcon src={caution} />
          <p>{text}</p>
        </S.TabCustom>
      ),
    },
    {
      title: t("kpis.eos.tab.recommended"),
      dataIndex: "recommended_product",
      render: (text: string) => (
        <S.TabCustom>
          <S.TabIcon src={reverse} />
          <p>{text}</p>
        </S.TabCustom>
      ),
    },
  ];

  const requestBuilder = (
    page: number = currentPage,
    company: string | null = selectedFilters[0]
  ) => {
    const queries: { [key: string]: string | null } = {
      id: (partner?.config.id && `userId=${partner.config.id}`) || null,
      page: `page=${page}`,
      company: (company && `company=${company}`) || null,
    };

    Object.keys(queries).forEach(
      (k: string) => queries[k] == null && delete queries[k]
    );

    return {
      type: proc.eos.values.type,
      name: proc.eos.values.name,
      route: `${proc.eos.values.route}?${Object.values(queries).join("&")}`,
    };
  };

  useEffect(() => {
    if (kpi?.eos?.data && kpi?.eos?.data.length > 0) {
      setDataSource(kpi?.eos?.data);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpi?.eos?.data]);

  useEffect(() => {
    if (!eosFilters) {
      getEosFilters({
        route: `${proc.eos.filters.route}${
          partner?.queries ? partner.queries : ""
        }`,
        name: proc.eos.filters.name,
      });
    } else {
      setCompanies(Object.values(eosFilters)[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eosFilters]);

  const concat = (newData: TableData, source: TableData) => {
    const obj = source.concat(newData);
    const withoutDuplicatedValues = obj.filter(
      (data: { [key: string]: string | number }, index: number, self: any) =>
        self.findIndex((v: any) => v.id === data.id) === index
    );
    return withoutDuplicatedValues;
  };

  useEffect(() => {
    if (temp && temp.length > 0) {
      setDataSource(concat(temp, dataSource));
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temp]);

  const [vt] = useVT(
    () => ({
      onScroll: async ({ isEnd }) => {
        if (isEnd) {
          if (
            kpi.eos?.extra?.total &&
            kpi.eos?.extra?.perPage &&
            currentPage <
              Math.ceil(kpi.eos?.extra?.total / kpi.eos?.extra?.perPage)
          ) {
            setCurrentPage(currentPage + 1);
            await loadDataByChunk(requestBuilder(currentPage + 1));
            setLoading(true);
            setCurrentPage(currentPage + 1);
          }
        }
      },
      scroll: { y: scrollConfig.y },
    }),
    [scrollConfig, dataSource]
  );

  const updateState = (e: CheckboxValueType[]) => {
    updateSelectedFilters([e.toString()]);
  };

  const exportXlsx = () => {
    setRequest(true);
    ApiService.getXlsx(
      token,
      partner?.config.id ? `?userId=${partner.config.id}` : "",
      "eos"
    ).then((res) => {
      const downloadLink = document.createElement("a");
      const fileName = `eos-${Date.now()}.xlsx`;
      downloadLink.href = res.data;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadLink.remove();
      setRequest(false);
    });
  };

  const removeFilter = (index: number) => {
    const array = [...selectedFilters];
    setLoading(true);
    if (index > -1) array.splice(index, 1);
    updateSelectedFilters(array);
    setDataSource([]);
    fetchKpi("POST", [{ ...requestBuilder(1, null) }]);
  };

  useEffect(() => {
    if (selectedFilters.length > 0) {
      setLoading(true);
      setCurrentPage(1);
      fetchKpi("POST", [{ ...requestBuilder(1) }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  return (
    <Row justify="space-between">
      <S.ProgressContent>
        <S.ProgressValue>
          <b>{kpi?.percent?.data.PERCENT || 0}% </b>
          {t("kpis.eos.risk")}
        </S.ProgressValue>
        <S.ProgressBar
          percent={kpi?.percent?.data.PERCENT || 0}
          strokeColor="#d92231"
        />
      </S.ProgressContent>
      <S.Menu>
        <S.Collapse>
          <S.Selector
            loading={companies.length < 1}
            disabled={!eosFilters}
            suffixIcon={<S.DropdownIcon />}
            value={eosFilters && Object.keys(eosFilters)[0]}
            onChange={(e) => updateState(e)}
            dropdownMatchSelectWidth={false}
          >
            {companies.length > 0 &&
              companies.map((company: string, index: number) => (
                <Option key={index.toString()} value={company}>
                  {company}
                </Option>
              ))}
          </S.Selector>
          {selectedFilters &&
            selectedFilters.map((filter: string, index: number) => (
              <S.Selected
                key={index.toString()}
                onClick={() => removeFilter(index)}
              >
                <S.CloseIcon />
                {filter}
              </S.Selected>
            ))}
        </S.Collapse>
        <S.Submit loading={request} onClick={() => exportXlsx()}>
          {t("kpis.eos.export")}
        </S.Submit>
      </S.Menu>
      <Col xs={24}>
        <S.Results
          loading={loading}
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          scroll={{
            y: scrollConfig.y,
            scrollToFirstRowOnChange: true,
          }}
          components={vt}
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default Eos;
