import i18n from "i18next";

const createDrop = (key: string, list: any) => {
  return list.map((value: string | number) => ({
    value: value.toString(),
    label:
      key === "country"
        ? i18n.t(`business.country.${value.toString()}`)
        : value.toString(),
  }));
};

const formatFiters = (data: any) => {
  const formattedFilters: any = [];
  if (data) {
    Object.keys(data).map((key) => {
      if (data[key] !== null) {
        formattedFilters.push({
          name: key,
          drop: [...createDrop(key, data[key])],
        });
      }
      return null;
    });
  }
  return formattedFilters;
};

export default function useFilters(): any {
  const format = (data: any) => {
    return formatFiters(data);
  };
  return { format } as const;
}
