import { Layout } from "antd";
import { FC } from "react";
import { LayoutProps } from "antd/lib/layout";

import Background from "assets/cover.png";
import { styled } from "./theme";

interface WrapperProps extends LayoutProps {
  padding?: number;
  transparent?: boolean;
}

const { Content } = Layout;

const Container: FC<LayoutProps> = styled(Content)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: calc(92px + 40px);
`;

const Render: FC<LayoutProps> = styled(Content)`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Wrapper: FC<WrapperProps> = styled(Content)`
  min-height: 63vh;
  margin-top: -50px;
  width: 90vw;
  padding: ${(style: WrapperProps) =>
    style.padding ? `${style.padding}px` : "0"};
  background-color: ${(style: WrapperProps) =>
    style.transparent
      ? "transparent"
      : (props) => props.theme.color.lightAccent};
`;

const Cover = styled.div`
  top: 92px;
  position: absolute;
  width: 100%;
  height: calc(100vh - 60%);
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: brightness(60%);
  transition: 300ms;
  @media (max-width: 1240px) {
    top: 60px;
  }
`;

const Wallpaper = styled.div`
  top: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: -1;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: brightness(60%);
`;

const Global = {
  Container,
  Cover,
  Render,
  Wrapper,
  Wallpaper,
};

type Breakpoints = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
};

export const breakpoint: Breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export default Global;
