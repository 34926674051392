import { FC } from "react";
import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import { TitleProps } from "antd/lib/typography/Title";

import { styled } from "theme/theme";

type ContentProps = {
  column?: boolean;
};

const { Text, Title } = Typography;

export const Wrapper = styled.div`
  flex: 1;
  height: 100%;
  margin-left: 36px;
`;

export const Head: FC<TitleProps> = styled(Title)`
  font-size: 40px;
  margin-top: 0;
  font-weight: 300 !important;
  text-align: left;
  color: ${(props) => props.theme.color.pulpe} !important;
`;

export const Content = styled.div`
  column-count: ${(is: ContentProps) => (is.column ? 2 : 1)};
`;

export const MdParagraph = styled.span`
  color: ${(props) => props.theme.color.info};
`;

export const MdStrong: FC<TextProps> = styled(Text)`
  font-weight: bold;
`;
