import { FC } from "react";
import { Col, Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";

import { styled, css } from "theme/theme";

const { Text } = Typography;

type SelectProps = {
  active: boolean;
  secondary?: boolean;
};

type DotProps = {
  fill: string;
};

type CenterColProps = {
  bordered: 0 | 1;
};

type ChartWidth = {
  width: number;
};

type NullProps = {
  isVisible: "inherit" | "hidden";
  color: string;
};

export const borders = css`
  border-left: 1px solid ${(props) => props.theme.color.info}30;
  border-right: 1px solid ${(props) => props.theme.color.info}30;
`;

export const CenterCol = styled(Col)`
  ${(col: CenterColProps) => col.bordered === 1 && borders}
  & .recharts-label-list {
    font-weight: bold;
  }
  & .recharts-surface {
    height: 350px;
  }
  height: 100%;
`;

export const SelectGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  & div {
    border: 1px solid ${(props) => props.theme.color.lightShade};
    :first-of-type {
      border-right-width: 0 !important;
      color: ${(props) => props.theme.color.white};
    }
    :last-of-type {
      border-left-width: 0 !important;
      color: ${(props) => props.theme.color.lightShade};
      background-color: ${(props) => props.theme.color.lightAccent};
    }
  }
`;

export const Select = styled.div`
  width: 110px;
  padding: 8px 0;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
`;

export const SelectSegment = styled(Select)`
  background-color: ${(is: SelectProps) =>
    // eslint-disable-next-line no-nested-ternary
    is.active
      ? (props) => props.theme.color.primary
      : (props) => props.theme.color.lightAccent}!important;
  color: ${(is: SelectProps) =>
    is.active
      ? (props) => props.theme.color.white
      : (props) => props.theme.color.info}!important;
  border-width: ${(is: SelectProps) => (is.active ? 0 : "1px")}!important;
`;

export const ColTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Category: FC<TextProps> = styled(Text)`
  font-size: 14px;
  margin-left: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.info};
`;

export const SubCategory: FC<TextProps> = styled(Text)`
  font-size: 14px;
  margin-left: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.color.grey};
`;

export const CustomSection = styled(Col)`
  padding-top: 12px;
  margin-top: -12px;
`;

export const Scale = styled.div`
  width: ${(current: ChartWidth) => current.width}px;
  height: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -42px;
`;

export const ScaleBox = styled.div`
  height: 100%;
  width: calc(${(current: ChartWidth) => current.width}px / 6);
  border-right: 1px solid ${(props) => props.theme.color.info};
  :first-child {
    border-left: 1px solid ${(props) => props.theme.color.info};
  }
`;

export const Percentage = styled.div`
  margin-left: -19px;
  width: calc(${(current: ChartWidth) => current.width}px + 38px);
  height: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

export const Value: FC<TextProps> = styled(Text)`
  font-size: 14px;
  margin-left: 15px;
  font-weight: 400;
  color: ${(props) => props.theme.color.info};
`;

export const ChartContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 28px;
`;

export const ChartLabel: FC<TextProps> = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.color.info};
  margin-top: 25px;
  width: 100px;
  white-space: nowrap;
`;

export const ChartArrow = styled.img`
  width: 28px;
  height: 28px;
  margin-top: 25px;
`;

export const ChartValue: FC<TextProps> = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.color.info};
  margin-top: 25px;
`;

export const BarLegend = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const CellTitle: FC<TextProps> = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  color: ${(props) => props.theme.color.info};
  margin-top: 25px;
  width: 85px;
  text-align: center;
  white-space: nowrap;
`;

export const DotLegend = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin: 28px 0;
`;

export const Dot: FC<DotProps> = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background-color: ${(color: DotProps) => color.fill};
  margin-left: 10px;
  margin-right: 4px;
`;

export const LegendTitle: FC<TextProps> = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  color: ${(props) => props.theme.color.info};
  text-align: left;
`;

export const Fake = styled.div`
  width: 300px;
  height: 16px;
  border-radius: 14px;
  background-color: #ececec;
`;

export const Asterisk: FC<TextProps> = styled(Text)`
  font-weight: 400;
  font-style: italic;
  font-size: 12px;
  color: ${(props) => props.theme.color.info};
  text-align: left;
`;
export const Null = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -18px;
`;

export const NullValue = styled.span`
  visibility: ${({ isVisible }: NullProps) => isVisible};
  font-weight: 700;
  color: ${({ color }: NullProps) => color};
`;
