import React, { FC, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Table, Hero } from "components";
import Global from "theme/global";
import { AppContext } from "providers";

const Business: FC = () => {
  const history = useHistory();
  const { mounted, mounting } = useContext(AppContext);

  useEffect(() => {
    if (!mounted) mounting(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  React.useEffect(() => {
    localStorage.setItem("from", history.location.pathname);
  }, [history]);

  return (
    <Global.Container>
      <Global.Cover />
      <Global.Render>
        <Hero />
        <Global.Wrapper>
          <Table />
        </Global.Wrapper>
      </Global.Render>
    </Global.Container>
  );
};

export default Business;
