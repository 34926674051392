import React from "react";

import UP from "assets/icons/arrow_up.svg";
import DOWN from "assets/icons/arrow_down.svg";
import {
  Wrapper,
  Title,
  Content,
  Percent,
  Value,
  Unit,
  Arrow,
  Evol,
  Spacer,
  DualValues,
} from "./style";

type Payload = {
  NAME: string;
  PERCENT: number;
  UNIT: string;
  VALUE: string | number;
  fill: string;
};

type EvolData = {
  "DIRECTION-1": string;
  "DIRECTION-2": string;
  "EVOL-1": number;
  "EVOL-2": number;
  "PERCENT-1": number;
  "PERCENT-2": number;
  NAME: string;
};

type EvolPayload = {
  color: string;
  dataKey: string;
  fill: string;
  name: string;
  payload: EvolData;
  radius: [number, number, number, number];
  value: number;
  formatter?: string;
  type?: string;
  unit?: string;
}[];

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const data: Payload = payload[0].payload;
    return (
      <Wrapper>
        <Title>{data.NAME}:</Title>
        <Content>
          <Percent color={data.fill}>{data.PERCENT}%</Percent>
          <Value>{data.VALUE}</Value>
          <Unit>{data.UNIT}</Unit>
        </Content>
      </Wrapper>
    );
  }
  return null;
};

export const EvolTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const payloadContent: EvolPayload = payload;
    const data: EvolData = payloadContent[0].payload;

    const renderValue = (value: number) => {
      if (value > 0) return `+${value}`;
      if (value === 0) return "0";
      return value;
    };

    return (
      <DualValues>
        <Arrow
          src={data["DIRECTION-1"] === "DOWN" ? DOWN : UP}
          alt="Evolution"
        />
        <Evol color={payloadContent[0].fill}>
          {renderValue(data["EVOL-1"])}%
        </Evol>
        <Spacer />
        <Arrow
          src={data["DIRECTION-2"] === "DOWN" ? DOWN : UP}
          alt="Evolution"
        />
        <Evol color={payloadContent[1].fill}>
          {renderValue(data["EVOL-2"])}%
        </Evol>
      </DualValues>
    );
  }
  return null;
};

export default CustomTooltip;
