import { FC } from "react";
import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";

import { styled } from "theme/theme";

const { Text } = Typography;

interface TotalColor {
  color?: string;
}

interface RowProps extends TotalColor {
  total?: boolean;
}

interface ProgressProps {
  value: number | string;
}

export const RowContainer = styled.div`
  padding: 8px 5%;
  width: 90%;
  background-color: ${(is: RowProps) => (is.total ? is.color : "inherit")};
  & .ant-typography {
    color: ${(is: RowProps) => is.total && "#fff"} !important;
  }
  > * {
    color: ${(is: RowProps) => is.total && "#fff"} !important;
    font-size: 12px;
  }
`;

export const RowContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
`;

export const RowTitle: FC<TextProps> = styled(Text)`
  width: 20%;
`;

export const RowList = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RowItem = styled.div`
  width: 25%;
  text-align: center;
`;

export const ProgressOuter = styled.div`
  width: 100%;
  height: 6px;
  background: red;
  border-radius: 6px;
  background: ${(props) => props.theme.color.lightShade};
`;

export const ProgressInner: FC<ProgressProps> = styled.div`
  width: ${(progress: ProgressProps) => progress.value}%;
  height: 100%;
  border-radius: 6px;
  opacity: 0.5;
  background: ${(props) => props.theme.color.info};
  transition: 300ms;
`;

export const ColContainer = styled.div`
  padding: 8px 5%;
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: -30px;
`;

export const ColIcon = styled.img`
  width: 25%;
  object-fit: contain;
  text-align: center;
  height: 60px;
`;

export const ColList = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ColItem = styled.div`
  width: 25%;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
`;
