import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./style";

interface CTProps {
  icon: string;
  color: string;
  data: { [key: string]: string }[];
}

const CustomTable: FC<CTProps> = ({ icon, color, data }: CTProps) => {
  const { t } = useTranslation();
  return (
    <>
      <S.ColContainer>
        <S.ColIcon src={icon} />
        <S.ColList>
          <S.ColItem>{t("kpis.mix.product.col.units")}</S.ColItem>
          <S.ColItem>{t("kpis.mix.product.col.purchases")}</S.ColItem>
          <S.ColItem>{t("kpis.mix.product.col.share")}</S.ColItem>
          <S.ColItem>{t("kpis.mix.product.col.delta")}</S.ColItem>
        </S.ColList>
      </S.ColContainer>
      {data &&
        data.map(({ QTY, CA, SHARE, EVOLUTION, FAMILY }, i) => (
          <S.RowContainer
            key={i.toString()}
            total={FAMILY === "A3" || FAMILY === "A4"}
            color={FAMILY === "A3" || FAMILY === "A4" ? color : undefined}
          >
            <S.RowContent>
              <S.RowTitle>
                {FAMILY.includes("_") &&
                  t(
                    `kpis.mix.product.row.${
                      FAMILY.includes("a3_")
                        ? FAMILY.replace("a3_", "")
                        : FAMILY?.replace("a4_", "")
                    }`
                  )}
                {(FAMILY === "A3" || FAMILY === "A4") &&
                  t("kpis.mix.product.row.total")}
              </S.RowTitle>
              <S.RowList>
                <S.RowItem>{QTY && QTY.toUpperCase()}</S.RowItem>
                <S.RowItem>{CA && CA.toUpperCase()}</S.RowItem>
                <S.RowItem>
                  {SHARE ? Math.round(parseFloat(SHARE) * 10) / 10 : 0}%
                </S.RowItem>
                <S.RowItem>
                  {EVOLUTION &&
                    Math.round(parseFloat(EVOLUTION) * 10) / 10 > 0 &&
                    "+"}
                  {EVOLUTION
                    ? `${Math.round(parseFloat(EVOLUTION) * 10) / 10}%`
                    : "n.s"}
                </S.RowItem>
              </S.RowList>
            </S.RowContent>
            <S.ProgressOuter>
              <S.ProgressInner value={SHARE ? parseFloat(SHARE) : 0} />
            </S.ProgressOuter>
          </S.RowContainer>
        ))}
    </>
  );
};

export default CustomTable;
