import React from "react";

function kFormatter(num: React.ReactText = 0) {
  if (num === 0) return 0;
  const number: number = parseFloat(num.toString());
  const abstract: any = Math.round(Math.abs(number) / 1000).toFixed(1);
  return Math.abs(number) > 999
    ? `${Math.sign(number) * abstract}K`
    : Math.sign(number) * Math.abs(number);
}

export default function useFormatter(): any {
  const formatValue = (value: any) => {
    return value && kFormatter(value);
  };
  return { formatValue } as const;
}
