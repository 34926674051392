import React, { FC, useContext, useEffect } from "react";

import { AppContext } from "providers";
import { ResetForm } from "components";

const Reset: FC = () => {
  const { token } = useContext(AppContext);

  useEffect(() => {
    localStorage.getItem("token");
  }, [token]);

  return <ResetForm />;
};

export default Reset;
