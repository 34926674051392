import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useWindowDimensions } from "hooks";
import { breakpoint } from "theme/global";
import { AppContext } from "providers";
import placeholder from "assets/placeholder.png";
import { success, error } from "services/notify";
import * as S from "./style";

interface ContactProps {
  contacts: { name: string | undefined; email: string | undefined }[];
}
interface ProfileProps {
  profileName: string | undefined;
  jobTitle: string | undefined;
}

interface DiscoverProps {
  // eslint-disable-next-line react/require-default-props
  normalized?: boolean;
  color: string;
  title: string;
  content: string;
  link: string;
  button: string;
}

interface AdminProps {
  // eslint-disable-next-line react/require-default-props
  normalized?: boolean;
  title: string;
  content: string;
  button: string;
  type: "wm" | "br";
  period?: any;
  actions?: { [key: string]: (file?: any) => Promise<void> } | undefined;
}

export const ContactCards: FC<ContactProps> = ({ contacts }: ContactProps) => {
  const { t } = useTranslation();
  const dimensions = useWindowDimensions();
  return (
    <S.ContactCard>
      <S.ContactTitle
        level={
          dimensions.width >= 1200 || dimensions.width <= breakpoint.lg ? 1 : 2
        }
      >
        {t("welcome.contact")}
      </S.ContactTitle>
      {contacts.map(({ email, name }) => (
        <S.Contact key={email} padding={dimensions.width >= 1200}>
          <S.ContactName>{name}</S.ContactName>
          <S.ContactEmail>{email}</S.ContactEmail>
        </S.Contact>
      ))}
    </S.ContactCard>
  );
};

export const ProfileCards: FC<ProfileProps> = ({
  profileName,
  jobTitle,
}: ProfileProps) => {
  const { director } = useContext(AppContext);
  return (
    <S.Card>
      {director?.picture ? (
        <S.Picture shape="square" size={150} src={director?.picture} />
      ) : (
        <S.Picture shape="square" size={150} src={placeholder} />
      )}
      <S.ProfileInfo>
        <S.ProfileName level={4}>{profileName}</S.ProfileName>
        <S.ProfileJob>{jobTitle?.replace("#", " ")}</S.ProfileJob>
      </S.ProfileInfo>
    </S.Card>
  );
};

export const DiscoverCards: FC<DiscoverProps> = ({
  normalized = false,
  color,
  title,
  content,
  link,
  button,
}: DiscoverProps) => {
  const history = useHistory();
  return (
    <S.Card normalized={normalized} height={426}>
      <S.DiscoverTitle level={1}>{title}</S.DiscoverTitle>
      <S.DiscoverContent>{content}</S.DiscoverContent>
      <S.DiscoverButton onClick={() => history.push(link)} color={color}>
        {button}
      </S.DiscoverButton>
    </S.Card>
  );
};

export const AdminCards: FC<AdminProps> = ({
  normalized = false,
  title,
  content,
  button,
  type,
  period,
  actions,
}: AdminProps) => {
  const [uploaded, setUploaded] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string | undefined>();
  const [isUpdating, updating] = React.useState<boolean>(false);
  const [isDownloading, downloading] = React.useState<boolean>(false);
  const [isUploading, uploading] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<any>(null);
  const addFile = (event: any) => {
    if (event.target.files[0]) {
      setUploaded(true);
      setFile(event.target.files[0]);
    } else {
      setUploaded(false);
    }
  };

  const handleSubmit = () => {
    if (period?.update && value) {
      updating(true);
      period
        ?.update(parseInt(value, 0))
        .then(() => {
          success(`Retention period changed to ${value} days`);
          updating(false);
        })
        .catch(() => {
          error(`Unable to change retention period`);
          updating(false);
        });
    }
  };

  const handleDownload = async () => {
    downloading(true);
    await actions?.retrieve();
    downloading(false);
  };

  const handleUpload = async () => {
    if (file !== null) {
      uploading(true);
      const xlsx = new FormData();
      xlsx.append("excel", file);
      await actions
        ?.drop(xlsx)
        .then(() => {
          success(`Translation successfully uploaded`);
          updating(false);
        })
        .catch(() => {
          error(`Error while uploading translation file`);
          updating(false);
        });
      uploading(false);
    }
  };

  return (
    <S.Card normalized={normalized} height={565}>
      <S.AdminTitle level={1}>{title}</S.AdminTitle>
      <S.AdminContent>
        {content}
        {type === "wm" && (
          <S.InputGroup>
            <S.Download onClick={() => handleDownload()}>
              <S.DownloadIcon /> Download Initial Text
            </S.Download>
            <S.UploadFile
              onChange={(e) => addFile(e)}
              type="file"
              name="file"
              id="file"
              accept=".xlsx"
            />
            <S.Upload htmlFor="file" uploaded={uploaded}>
              <S.TranslateIcon /> Import Translation
            </S.Upload>
          </S.InputGroup>
        )}
        {type === "br" && (
          <div>
            <S.Delay
              placeholder="Enter the delay"
              suffix="Days"
              type="number"
              value={value}
              onChange={(e) => setValue(e.currentTarget.value)}
            />
          </div>
        )}
      </S.AdminContent>
      <S.AdminButton
        loading={isUpdating || isUploading || isDownloading}
        onClick={() => (type === "br" && handleSubmit()) || handleUpload()}
      >
        {button}
      </S.AdminButton>
    </S.Card>
  );
};

AdminCards.defaultProps = {
  period: { currentPeriod: null, update: null, request: null },
  actions: undefined,
};
