import React, { FC, ReactElement, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AppContext } from "providers";
import { KpiProps } from "types/kpi";
import {
  Product,
  Mif,
  Asp,
  Performance,
  Support,
  Market,
  Eos,
  DigitalPerformance,
  Connectivity,
} from "components";
import procList from "utils/procList";
import * as S from "./style";

interface ChildrenProps {
  content: string;
  title: string;
}

const Pane: FC<ChildrenProps> = ({ content, title }: ChildrenProps) => {
  const { t } = useTranslation();
  const context = useContext(AppContext);
  const { kpi }: KpiProps = context;
  const { partner, config } = context;

  useEffect(() => {
    if (
      typeof kpi.sales === "undefined" ||
      typeof kpi.equipments === "undefined"
    ) {
      context.fetchKpi("POST", procList(partner));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const quarter: number = kpi?.prod?.data?.[0].QUARTER;

  function partnerInfo(): { country: string; quarter: string } | undefined {
    // In several case country can be undefined if user force data rendering.
    // To prevent this, logic is wrapped inside a 'true/false' condition.

    const country: string | null =
      partner?.config.country || config?.user?.country || null;

    if (country) {
      return {
        country:
          country.charAt(0).toUpperCase() + country.slice(1).toLowerCase(),
        // [WARN]: dummy quarter for production pending correct methods
        quarter: t("business.filters.q", { number: quarter }),
      };
    }

    return undefined;
  }

  const components: { [key: string]: ReactElement } = {
    asp: <Asp />,
    eos: <Eos />,
    mif: <Mif />,
    perform: <Performance />,
    digitalPerformance: <DigitalPerformance />,
    product: <Product />,
    support: <Support />,
    market: <Market country={partnerInfo()?.country} />,
    connectivity: <Connectivity />,
  };

  return (
    <S.Wrapper>
      {content === "market" && (
        <S.PanTitle level={2}>{`${partnerInfo()?.quarter} ${t(
          `global.country.${partnerInfo()?.country.toUpperCase()}`
        )} ${t(title)}*`}</S.PanTitle>
      )}
      {!["mif", "market", "digitalPerformance"].includes(content) && (
        <S.PanTitle level={2}>{t(title)}</S.PanTitle>
      )}
      {content === "digitalPerformance" &&
        partner?.config?.digitalPerformance && (
          <S.PanTitle level={2}>{t(title)}</S.PanTitle>
        )}
      {components[content]}
    </S.Wrapper>
  );
};

export default Pane;
