import React, { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useHistory } from "react-router-dom";

import { ContactCards, ProfileCards, Letter, Hero, Loader } from "components";
import { AppContext } from "providers";
import Global from "theme/global";

const Welcome: FC = () => {
  const history = useHistory();
  const [contact, setContact] = useState<
    { name: string | undefined; email: string | undefined }[]
  >([]);
  const { mounted, mounting, state, config } = useContext(AppContext);

  useEffect(() => {
    if (!mounted) mounting(true);
    const contactArr = [
      {
        name: `${config?.user?.cbmName && `${config?.user?.cbmName} - CBM`}`,
        email: config?.user?.cbmEmail,
      },
      {
        name: `${
          config?.user?.managerName &&
          `${config?.user?.managerName} - ${config?.user?.managerTitle}`
        }`,
        email: config?.user?.managerEmail,
      },
    ];
    setContact(contactArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted, config]);

  React.useEffect(() => {
    localStorage.setItem("from", history.location.pathname);
  }, [history]);

  if (state.loading) return <Loader wrap />;
  return (
    <Global.Container>
      <Global.Cover />
      <Global.Render>
        <Hero />
        <Global.Wrapper padding={36}>
          <Row justify="start">
            <Col lg={9} xs={24}>
              <ProfileCards
                profileName={config?.user?.cgmName}
                jobTitle={config?.user?.jobTitle}
              />
              <ContactCards contacts={contact} />
            </Col>
            <Col lg={15} xs={24}>
              <Letter markdown={config?.welcomeMessage} />
            </Col>
          </Row>
        </Global.Wrapper>
      </Global.Render>
    </Global.Container>
  );
};

export default Welcome;
