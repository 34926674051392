import { FC } from "react";
import { Table, Typography, Col } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import { TitleProps } from "antd/lib/typography/Title";
import { keyframes } from "styled-components";

import { styled } from "theme/theme";

const { Text } = Typography;

interface PieCardProps extends TitleProps {
  primary?: boolean;
}

type DotProps = {
  fill: string;
};

type CircleProps = {
  type: "bids" | "tacticals";
};

const zoomIn = keyframes`
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
`;

export const Column = styled(Col)`
  border-left: 1px solid ${(props) => props.theme.color.info}50;
  &:first-of-type {
    border: none;
  }
  @media (max-width: 992px) {
    border: none;
  }
`;

export const SupportCard = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(is: PieCardProps) =>
    is.primary
      ? (props) => props.theme.color.orange
      : (props) => props.theme.color.lightAccent};
  > * {
    color: ${(is: PieCardProps) =>
      is.primary
        ? (props) => props.theme.color.white
        : (props) => props.theme.color.info} !important;
  }
`;

export const SupportCardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-flow: wrap;
  margin-top: 54px;
  width: 96%;
`;

export const SupportCardTitle: FC<TextProps> = styled(Text)`
  font-weight: 700;
  padding: 35px 20px 0 20px;
  color: ${(props) => props.theme.color.lightAccent};
  width: 100%;
  text-transform: uppercase;
`;

export const SupportCardCenter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const SupportContentCard: FC = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding: 0 20px;
`;

export const MinimalTable: FC<any> = styled(Table)`
  min-width: 418px !important;
  width: 100% !important;
  margin-top: 10px;
  & tr:first-child {
    background: ${(props) => props.theme.color.lightShade};
  }
  & tr:nth-child(2n + 3) {
    background: ${(props) => props.theme.color.lightShade};
  }
  & td {
    text-align: left !important;
    padding: 10px 16px !important;
  }
  & th {
    font-weight: bold !important;
    text-align: left !important;
    padding: 10px 16px !important;
  }
`;

export const DotLegend = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin: 10px 0 28px 10px;
`;

export const Dot: FC<DotProps> = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background-color: ${(color: DotProps) => color.fill};
  margin-left: 10px;
  margin-right: 4px;
`;

export const LegendTitle: FC<TextProps> = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  color: ${(props) => props.theme.color.info};
  text-align: left;
  text-transform: capitalize;
`;

export const Circle = styled.div`
  margin-top: 20px;
  width: 168px;
  height: 168px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 168px;
  background: rgb(255, 0, 0);
  background: ${(circle: CircleProps) =>
    circle.type === "bids"
      ? "linear-gradient(45deg, #a57a70 0%, rgba(255, 0, 0, 0) 100%)"
      : "linear-gradient(45deg, #8c021b 0%, rgba(255, 0, 0, 0) 100%)"};
  animation: ${zoomIn} 1s ease-in-out;
`;

export const CircleTitle = styled(Text)`
  font-size: 16px;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.lightAccent};
`;

export const CircleValue = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.color.lightAccent};
`;
