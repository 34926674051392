import React, { FC } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ToastOptions } from "react-toastify/dist/types";
import "react-toastify/dist/ReactToastify.css";

const options: ToastOptions = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const notify = (notification: string) => toast(notification, options);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const dark = (notification: string) => toast.dark(notification, options);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const error = (notification: string) =>
  toast.error(notification, options);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const info = (notification: string) => toast.info(notification, options);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const success = (notification: string) =>
  toast.success(notification, options);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const warning = (notification: string) =>
  toast.warning(notification, options);

export const Notifier: FC = () => {
  return <ToastContainer />;
};
