import { FC } from "react";
import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import { TitleProps } from "antd/lib/typography/Title";
import { FiRefreshCcw } from "react-icons/fi";

import { styled } from "theme/theme";

const { Text, Title } = Typography;

interface LinkProps extends TextProps {
  onClick?: () => void;
}

interface LevelProps extends TextProps {
  overview?: boolean;
}

type ContainerProps = {
  left?: boolean;
};

export const Wrapper = styled.div`
  margin-top: -54px;
  margin-bottom: 50px;
  width: 90vw;
  min-height: 10vh;
  align-items: start;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${(align: ContainerProps) =>
    align.left ? "flex-start" : "flex-end"};
`;

export const Name: FC<TitleProps> = styled(Title)`
  margin: 0 !important;
  text-transform: capitalize;
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 3px;
  font-weight: 400 !important;
  color: ${(props) => props.theme.color.lightAccent} !important;
`;

export const Company: FC<TextProps> = styled(Text)`
  color: ${(props) => props.theme.color.lightAccent};
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 3px;
  margin-left: 0;
  text-align: left;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 36px;
`;

export const Level: FC<LevelProps> = styled(Text)`
  text-align: right;
  padding: 6px 20px;
  font-size: 20px;
  background: ${(is: LevelProps) =>
    is.overview
      ? (props) => props.theme.color.lightAccent
      : (props) => props.theme.color.pulpe};
  color: ${(is: LevelProps) =>
    is.overview
      ? (props) => props.theme.color.blue
      : (props) => props.theme.color.lightAccent};
  margin-bottom: 10px;
  font-weight: 300;
  & strong {
    text-transform: ${(is: LevelProps) =>
      is.overview ? "capitalize" : "uppercase"};
  }
`;

export const Switch: FC<LinkProps> = styled(Text)`
  text-align: right;
  padding: 6px 20px;
  font-size: 12px;
  border: 1px solid ${(props) => props.theme.color.lightAccent};
  background: ${(props) => props.theme.color.lightAccent}40;
  color: ${(props) => props.theme.color.lightAccent};
  margin-bottom: 10px;
  font-weight: 300;
  text-transform: uppercase;
  cursor: pointer;
`;

export const Update: FC<TextProps> = styled(Text)`
  text-shadow: rgba(0, 0, 0, 0.2) 0 0 3px;
  text-align: right;
  font-style: italic;
  color: ${(props) => props.theme.color.lightAccent};
  margin-bottom: 20px;
  font-weight: 300;
`;

export const Icon = styled(FiRefreshCcw)`
  margin-right: 8px;
  margin-bottom: -3px;
  font-size: 15px;
  color: ${(props) => props.theme.color.lightAccent};
`;
