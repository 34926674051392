import React, { useContext, useEffect, useState } from "react";
import Filters from "components/Filters";
import { useTranslation } from "react-i18next";
import { AppContext } from "providers";
import { useHistory } from "react-router-dom";
import routes from "router/routes";

import { proc } from "utils/api-common";
import upIcon from "assets/icons/arrow_up.svg";
import equalIcon from "assets/icons/arrow_equal.svg";
import downIcon from "assets/icons/arrow_down.svg";
import * as S from "./style";
import { useFormatter } from "../../hooks";

type DashboardDatasProps = {
  userid: string;
  partner_name: string;
  country: string;
  revenue: string;
  unit: string;
  revenue_trend: string;
  units: string;
  units_trend: string;
  digital_active: string;
  a3_market_share: string;
  mif: string;
  eos_risk_percentage: string;
  unconnected_units_percentage: string;
};
type FormattedDashboardProps = {
  userId: string;
  partnerName: string;
  country: string;
  revenueTrend: {
    revenue: number;
    direction: string;
  };
  unitsTrend: {
    units: string;
    direction: string;
  };
  digitalActive: string;
  marketShare: string;
  mif: string | null;
  eosRisk: string | null;
  unconnectedUnits: string | null;
};

type PartnersFilterProps = {
  name: "partners";
  drop: { value: number; label: string }[] | [];
};

type AllowedFiltersProps =
  | [
      {
        name: "country";
        drop: { value: string; label: string }[] | [];
      },
      {
        name: "cgm";
        drop: { value: string; label: string }[] | [];
      },
      {
        name: "cbm";
        drop: { value: string; label: string }[] | [];
      },
      PartnersFilterProps
    ]
  | [];

const Dashboard = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [allowedFilters, setAllowedFilters] = useState<
    AllowedFiltersProps | any
  >([]);
  const [filterState, setFilterState] = useState<any>(null);
  const [topLvl, setTopLvl] = useState<string | null>(null);
  const [filterToRemove, setFilterToRemove] = useState<string | null>(null);
  const [resetState, forceReset] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<FormattedDashboardProps[]>([]);
  const [initialPartners, setInitialPartners] = useState<
    FormattedDashboardProps[]
  >([]);
  const [initialState, setInitialState] = useState<any>({
    init: true,
    values: {},
  });
  const {
    requestDashboard,
    dashboard,
    requestFilters,
    filters,
    updatePartner,
    config,
  } = useContext(AppContext);
  const { formatValue } = useFormatter();
  const [filterToUpdate, setFilterToUpdate] = useState<string | null>(null);
  const hierarchy: string[] = ["country", "cgm", "cbm", "partners"];
  const allowedKpi = config?.authorizations?.filters?.kpis;

  const formattedDashboardData = (datas: DashboardDatasProps[]) => {
    return datas.reduce(
      (acc: FormattedDashboardProps[], data: DashboardDatasProps) => {
        acc.push({
          userId: data.userid,
          partnerName: data.partner_name,
          country: data.country,
          revenueTrend: {
            revenue: Math.round(Number(data.revenue)),
            direction: data.revenue_trend,
          },
          unitsTrend: {
            units: data.units,
            direction: data.units_trend,
          },
          digitalActive: data.digital_active === "N" ? "No" : "Yes",
          marketShare: data.a3_market_share,
          mif: data.mif,
          eosRisk: data.eos_risk_percentage,
          unconnectedUnits: data.unconnected_units_percentage,
        });
        return acc;
      },
      []
    );
  };
  const redirectPartner = (namePartner: string) => {
    history.push(routes.PERFORMANCE);
    const userId = dataSource?.find((el: any) => el.partnerName === namePartner)
      ?.userId;
    updatePartner({
      queries: `?userId=${userId}`,
      name: namePartner,
      from: "dashboard",
    });
  };

  const directionIcon = (direction: string) => {
    switch (direction) {
      case "UP":
        return upIcon;
      case "DOWN":
        return downIcon;
      default:
        return equalIcon;
    }
  };

  const columns: any = [
    {
      title: t("home.dashboard.tab.partnerName"),
      dataIndex: "partnerName",
      width: 400,
      render: (name: string) => (
        <S.ButtonRedirect onClick={() => redirectPartner(name)}>
          {name}
        </S.ButtonRedirect>
      ),
    },
    {
      title: t("home.dashboard.tab.country"),
      dataIndex: "country",
    },
    {
      title: t("home.dashboard.tab.revenueTrend"),
      dataIndex: "revenueTrend",
      render: (revenueTrend: any) => (
        <S.CellCustom>
          <p>{formatValue(revenueTrend.revenue)}</p>
          <S.TabIcon src={directionIcon(revenueTrend.direction)} />
        </S.CellCustom>
      ),
      defaultSortOrder: "descend",
      sorter: {
        compare: (a: any, b: any) => {
          return a.revenueTrend.revenue - b.revenueTrend.revenue;
        },
      },
    },
    {
      title: t("home.dashboard.tab.unitsTrend"),
      dataIndex: "unitsTrend",
      render: (unitsTrend: any) => (
        <S.CellCustom>
          <p>{unitsTrend.units}</p>
          <S.TabIcon src={directionIcon(unitsTrend.direction)} />
        </S.CellCustom>
      ),
    },
    {
      title: () => (
        <>
          {t("home.dashboard.tab.digitalActive")}{" "}
          <div className="under_title">
            {t("home.dashboard.tab.digitalActiveSubTitle")}
          </div>
        </>
      ),
      dataIndex: "digitalActive",
      render: (text: string) => {
        const colorText = text === "Yes" ? "green" : "red";
        return (
          <S.CellCustom>
            <p style={{ color: colorText, fontWeight: "bold" }}>{text}</p>
          </S.CellCustom>
        );
      },
    },
    {
      title: () => (
        <>
          {t("home.dashboard.tab.colourMarket")}
          <div className="under_title">
            {t("home.dashboard.tab.colourMarketSubTitle")}
          </div>
        </>
      ),
      dataIndex: "marketShare",
      render: (marketDirection: string) => (
        <S.CellCustom>
          <S.TabIcon src={directionIcon(marketDirection)} />
        </S.CellCustom>
      ),
    },
    {
      title: t("home.dashboard.tab.mif"),
      dataIndex: "mif",
      render: (mifDirection: string) => (
        <S.CellCustom>
          {mifDirection ? (
            <S.TabIcon src={directionIcon(mifDirection)} />
          ) : (
            "N/A"
          )}
        </S.CellCustom>
      ),
    },
    {
      title: t("home.dashboard.tab.eosRisk"),
      dataIndex: "eosRisk",
      render: (percent: string) => (
        <S.CellCustom>
          {percent ? <p>{percent === "0.0" ? "0" : percent}%</p> : "N/A"}
        </S.CellCustom>
      ),
    },
    {
      title: t("home.dashboard.tab.unconnectedUnits"),
      dataIndex: "unconnectedUnits",
      render: (connectedUnits: string) => (
        <S.CellCustom>
          {connectedUnits ? (
            <p>{Math.round((100 - parseFloat(connectedUnits)) * 10) / 10}%</p>
          ) : (
            "N/A"
          )}
        </S.CellCustom>
      ),
    },
  ];

  const getAllIdFilteredPartners = (
    partnersFiltered: { value: number; label: string }[]
  ) => {
    return partnersFiltered?.reduce(
      (partnersId: string[], partnerFiltered: { label: string }) => {
        partnersId.push(partnerFiltered.label);
        return partnersId;
      },
      []
    );
  };

  // update data source with selected filters
  useEffect(() => {
    if (allowedFilters) {
      const allIdFilteredPartners = getAllIdFilteredPartners(
        allowedFilters?.find(
          (filter: PartnersFilterProps) => filter?.name === "partners"
        )?.drop
      );
      const allDataFiltered = initialPartners?.filter(
        (el: { partnerName: string }) =>
          allIdFilteredPartners?.includes(el.partnerName)
      );

      setDataSource(allDataFiltered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedFilters, dashboard]);

  // Add partners name and the queries to the context
  useEffect(() => {
    if (filterState?.partners) {
      const partnersList = allowedFilters.filter(
        (word: PartnersFilterProps) => word.name === "partners"
      );
      const currentPartner = partnersList[0].drop.filter(
        (el: { label: string; value: number }) =>
          el.value === filterState.partners[0]
      );
      if (currentPartner[0]?.label) {
        updatePartner({
          queries: `?userId=${filterState.partners[0]}`,
          name: currentPartner[0]?.label,
          from: "dashboard",
        });
      } else {
        // TODO: User need to dble ckick to remove selection
        // We need to get the current filter name he want
        // to remove and give it to SetFilterToRemove state
        updatePartner(null);
        // setFilterToUpdate(null);
        setFilterToRemove(null);
        // setFilterState(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  // call values to the child level
  useEffect(() => {
    if (filterState) {
      const queries: string[] = [];
      const translator: { [k: string]: string } = {
        country: "country",
        cgm: "cgm",
        cbm: "cbm",
        partners: "partner",
      };
      let toFilter: string | boolean = "";
      Object.keys(filterState).forEach((el) => {
        queries.push(`${translator[el]}=${filterState[el][0]}`);
        toFilter = el;
        if (el !== "partners") {
          setFilterToUpdate(
            hierarchy[
              Object.keys(translator).findIndex((key) => key === el) + 1
            ]
          );
        } else setFilterToUpdate(null);
      });
      if (toFilter === "country") {
        toFilter = "?filter=country&";
      } else if (toFilter === "cgm") {
        toFilter = "?filter=cbm&";
      } else if (toFilter === "cbm") {
        toFilter = "?filter=partners&";
      } else if (toFilter === "partners") {
        toFilter = "?filter=partners&";
      }
      requestFilters({
        ...proc.filters,
        route: `${proc.filters.route}${toFilter}${queries.join("&")}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  // Add values to the child level
  useEffect(() => {
    if (filterToUpdate) {
      const drop: any = filters.data[filterToUpdate].map(
        (el: { id: number; name: string }) => {
          return { value: el.id, label: el.name };
        }
      );
      const getEntry = allowedFilters.find(
        (o: any) => o.name === filterToUpdate
      );
      const entry = { ...getEntry };
      if (entry) entry.drop = drop;
      const updatedFilters = allowedFilters.filter((obj: any) => {
        return obj.name !== topLvl;
      });
      setAllowedFilters([...updatedFilters, { name: topLvl, drop }]);
      setFilterToUpdate(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  // update data source with dashboard data formatted
  useEffect(() => {
    if (dashboard.data) {
      setDataSource(formattedDashboardData(dashboard.data));
      setInitialPartners(formattedDashboardData(dashboard.data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard]);

  // update data source with dashboard data formatted
  useEffect(() => {
    requestDashboard({
      ...proc.dashboard,
      route: `${proc.dashboard.route}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initialize firsts values
  useEffect(() => {
    const found = [];
    let initializedRequest = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const filter in allowedKpi) {
      if (allowedKpi[filter]) {
        found.push({ name: filter, drop: [] });
        // eslint-disable-next-line no-loop-func
        hierarchy.forEach((lvl) => {
          if (lvl === filter && !initializedRequest) {
            setTopLvl(lvl);
            initializedRequest = true;
            requestFilters({
              ...proc.filters,
              route: `${proc.filters.route}?filter=${filter}`,
            });
          }
        });
      }
    }
    setAllowedFilters(found);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedKpi]);

  // Add values to the top level filter
  useEffect(() => {
    if (filters.data) {
      const emptyArray = [];
      let drop: any = [];
      const object: any = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key in filters.data) {
        if (topLvl) {
          if (topLvl === "country" && key === "country") {
            drop = [];
            // eslint-disable-next-line no-loop-func
            filters.data[topLvl].forEach((el: string) => {
              drop.push({ value: el, label: el });
            });
            object.push({ name: key, drop });
          }
          if (
            filters.data[key] !== null &&
            filters.data[key].length > 0 &&
            key !== "country"
          ) {
            drop = [];
            // eslint-disable-next-line no-loop-func
            filters.data[key].forEach((el: { id: number; name: string }) => {
              drop.push({ value: el.id, label: el.name });
            });
            object.push({ name: key, drop });
          }
          if (filters.data[key] === null || filters.data[key].length <= 0) {
            emptyArray.push(key);
          }
        }
      }
      emptyArray.forEach((key: string) => {
        if (config?.authorizations?.filters?.kpis?.[key] === true)
          object.push({ name: key, drop: [] });
      });
      const newObject = [];
      if (config?.authorizations?.filters?.kpis?.country === true)
        newObject.push(object.find((o: any) => o.name === "country"));

      if (config?.authorizations?.filters?.kpis?.cgm === true)
        newObject.push(object.find((o: any) => o.name === "cgm"));

      if (config?.authorizations?.filters?.kpis?.cbm === true)
        newObject.push(object.find((o: any) => o.name === "cbm"));

      if (config?.authorizations?.filters?.kpis?.partners === true)
        newObject.push(object.find((o: any) => o.name === "partners"));

      setAllowedFilters(newObject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (initialState.length <= 0) setInitialState(allowedFilters);
  }, [allowedFilters, initialState]);

  useEffect(() => {
    if (resetState) {
      const found = [];
      let initializedRequest = false;
      // eslint-disable-next-line no-restricted-syntax
      for (const filter in allowedKpi) {
        if (allowedKpi[filter]) {
          found.push({ name: filter, drop: [] });
          // eslint-disable-next-line no-loop-func
          hierarchy.forEach((lvl) => {
            if (lvl === filter && !initializedRequest) {
              setTopLvl(lvl);
              initializedRequest = true;
              requestFilters({
                ...proc.filters,
                route: `${proc.filters.route}?filter=${filter}`,
              });
            }
          });
        }
      }
      updatePartner(null);
      setAllowedFilters(found);
      forceReset(false);
      requestDashboard({
        ...proc.dashboard,
        route: `${proc.dashboard.route}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetState]);

  return (
    <S.Wrapper>
      <S.HeadTitle>{t(`home.dashboard.title`)}</S.HeadTitle>
      <S.Filters>
        <Filters
          type="fdv"
          data={allowedFilters}
          displayResult
          updateManager={setFilterState}
          forceReset={resetState}
          onRemove={filterToRemove}
        />
        <S.Reset onClick={() => forceReset(true)}>
          {t(`home.dashboard.resetFilter`)}
        </S.Reset>
      </S.Filters>

      {dashboard?.data && (
        <S.DashboardTable
          rowKey="userId"
          columns={columns}
          dataSource={dataSource}
          pagination={{ pageSize: 6, showSizeChanger: false }}
        />
      )}
    </S.Wrapper>
  );
};

export default Dashboard;
