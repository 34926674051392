import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import routes, { navigation } from "router/routes";
import { AppContext } from "providers";
import { Wrapper, Nav, NavLink, Separator, Copyright } from "./style";

type FooterProps = {
  displayed?: boolean;
};

const Footer: FC<FooterProps> = ({ displayed }: FooterProps) => {
  const { config } = useContext(AppContext);
  const { t } = useTranslation();
  const location = useLocation();

  if (
    location.pathname === routes.AUTH ||
    location.pathname === routes.REDIRECT
  )
    return null;
  return (
    <Wrapper displayed={displayed}>
      <Nav>
        {navigation.map((nav) => {
          if (config?.authorizations?.pages[nav.authorization]) {
            if (nav === navigation[navigation.length - 1])
              return (
                <Link key={nav.name} to={nav.root}>
                  <NavLink>{t(nav.name)}</NavLink>
                </Link>
              );
            return (
              <Link key={nav.name} to={nav.root}>
                <NavLink>{t(nav.name)}</NavLink>
                <Separator type="vertical" />
              </Link>
            );
          }
          return null;
        })}
      </Nav>
      <Copyright>{t("global.copyright")}</Copyright>
    </Wrapper>
  );
};

Footer.defaultProps = {
  displayed: true,
};

export default Footer;
