import { FC } from "react";
import { Typography, Button, Select, Checkbox, Radio } from "antd";
import { OptionProps, SelectProps } from "antd/lib/select";
import { CheckboxGroupProps } from "antd/lib/checkbox";
import { TextProps } from "antd/lib/typography/Text";
import { ButtonProps } from "antd/lib/button";
import { RadioProps } from "antd/lib/radio";
import { IconBaseProps } from "react-icons";
import { BiCaretDownCircle, BiX } from "react-icons/bi";

import { styled } from "theme/theme";

const { Option } = Select;
const { Text } = Typography;

interface LinkProps extends TextProps {
  onClick?: () => void;
}

interface SelectorProps extends SelectProps<any> {
  dropdownRender: any;
  spaced?: number;
}

interface CheckProps extends CheckboxGroupProps {
  value: any;
}

export const SelectorGroup: FC = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 6px;
`;

export const Selected: FC<LinkProps> = styled(Text)`
  font-size: 16px;
  margin-top: 14px;
  margin-left: 15px;
  font-weight: 300 !important;
  text-align: center;
  color: ${(props) => props.theme.color.mainBrand} !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize !important;
`;

export const Selector: FC<SelectorProps> = styled(Select)`
  min-width: 138px;
  margin: 0 ${(custom: SelectorProps) => custom.spaced || 15}px;
  color: ${(props) => props.theme.color.info};
  & * {
    width: auto !important;
    text-transform: capitalize;
  }
  & span {
    font-weight: 700;
  }
  & *:hover {
    color: ${(props) => props.theme.color.info};
    border-color: ${(props) => props.theme.color.info}!important;
  }
  & *:focus {
    outline: none !important;
  }
`;

export const Item: FC<OptionProps> = styled(Option)`
  text-transform: capitalize !important;
  & * {
    text-transform: capitalize !important;
  }
`;

export const Reset: FC<ButtonProps> = styled(Button)`
  margin-top: 14px;
  margin-left: 14px;
  text-decoration-line: underline;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.color.info};
  text-transform: uppercase;
  :hover {
    color: ${(props) => props.theme.color.info};
    border-color: ${(props) => props.theme.color.info};
  }
`;

export const CloseIcon: FC<IconBaseProps> = styled(BiX)`
  color: ${(props) => props.theme.color.mainBrand};
  margin-right: 5px;
`;

export const DropdownIcon: FC<IconBaseProps> = styled(BiCaretDownCircle)`
  margin-top: -4px;
  color: ${(props) => props.theme.color.info};
  font-size: 20px;
`;

export const CheckWrapper: FC = styled.div`
  padding: 8px;
`;

export const FiltersGroup: FC = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const InlineSelected: FC = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const CheckboxGroup: FC<CheckProps> = styled(Checkbox.Group)`
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  width: 200px;
`;

export const RadioTitle: FC<TextProps> = styled(Text)`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 16px;
  color: ${(props) => props.theme.color.info};
`;

export const RadioWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 50px;
`;

export const CustomRadio: FC<RadioProps> = styled(Radio)`
  & .ant-radio-inner::after {
    background-color: ${(props) => props.theme.color.info}!important;
  }
  & .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => props.theme.color.info}!important;
  }
`;
