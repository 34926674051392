import baseStyled, {
  css,
  createGlobalStyle,
  ThemedStyledInterface,
} from "styled-components";
import { normalize } from "styled-normalize";

export const lightTheme = {
  color: {
    lightShade: "#ebebeb" /* Cararra */,
    lightAccent: "#ffffff" /* Stack */,
    mainBrand: "#d92231" /* LipStick */,
    darkFaded: "#d9d9d9" /* Clay */,
    darkShade: "#252423" /* Shark */,
    darkAccent: "#c34400" /* Burnt */,
    primary: "#d92231" /* Shadow */,
    variant: "#c34400" /* Shadow */,
    alternative: "#f67e00" /* pumpkin */,
    info: "#333333" /* Shark */,
    success: "#27800e" /* Fruit Salad */,
    warning: "#db8818" /* Zest */,
    danger: "#f44336" /* Pomegranate */,
    white: "#ffffff" /* White */,
    grey: "#737373" /* Grey */,
    pulpe: "#c34400" /* Burnt */,
    orange: "#f67e00" /* pumpkin */,
    blue: "#1a6fc5" /* marine */,
    purple: "#9b2583",
    lightGreen: "#e6f4ea",
  },
};

export const darkTheme = {
  color: {
    lightShade: "#242424" /* Cararra */,
    lightAccent: "#212121" /* Stack */,
    mainBrand: "#d92231" /* LipStick */,
    darkFaded: "#ababab" /* Clay */,
    darkShade: "#252423" /* Shark */,
    darkAccent: "#ffffff" /* Burnt */,
    primary: "#d92231" /* Shadow */,
    variant: "#c34400" /* Shadow */,
    alternative: "#f67e00" /* pumpkin */,
    info: "#eeeeee" /* Shark */,
    success: "#27800e" /* Fruit Salad */,
    warning: "#db8818" /* Zest */,
    danger: "#f44336" /* Pomegranate */,
    white: "#ffffff" /* White */,
    grey: "#737373" /* Grey */,
    pulpe: "#c34400" /* Burnt */,
    orange: "#f67e00" /* pumpkin */,
    blue: "#1a6fc5" /* marine */,
    purple: "#9b2583",
  },
};

const GlobalStyle = createGlobalStyle`
  ${normalize}
  body {background-color: ${lightTheme.color.darkFaded}
  }
  html::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: ${lightTheme.color.darkFaded};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(255,255,255,1);
  }
`;

const styled = baseStyled as ThemedStyledInterface<Theme>;

export default GlobalStyle;
export type Theme = typeof lightTheme;
export { styled, css };
