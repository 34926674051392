import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import icon from "assets/icons/toner.png";
import * as S from "./style";

interface CTProps {
  color: string;
  data: { [key: string]: string };
}

const Toner: FC<CTProps> = ({ color, data }: CTProps) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const [value, setValue] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setProgress(Math.floor(Math.random() * Math.floor(100)));
    }, 2000);
  }, [progress]);

  useEffect(() => {
    setTimeout(() => {
      if (value < progress) setValue(value + 1);
      if (value > progress) setValue(value - 1);
    }, 20);
  }, [value, progress]);

  return (
    <S.Wrapper>
      <S.Icon src={icon} />
      <S.Status>{data.TYPE}</S.Status>
      <S.ProgressValue color={color}>
        {parseFloat(data.PERCENT)}%
      </S.ProgressValue>
      <S.ProgressOuter>
        <S.ProgressInner value={parseFloat(data.PERCENT)} color={color} />
      </S.ProgressOuter>
      <S.UnitsValue color={color}>
        {t("kpis.mix.toner.unit", {
          count: Math.round(parseFloat(data.VALUE)),
        })}{" "}
        <span>
          {parseFloat(data.PERCENT_EVOL) > 0 && "+"}
          {parseFloat(data.PERCENT_EVOL) === 0 && "="}
          {parseFloat(data.PERCENT_EVOL) !== 0 &&
            `${parseFloat(data.PERCENT_EVOL)}%`}
        </span>
      </S.UnitsValue>
    </S.Wrapper>
  );
};

export default Toner;
