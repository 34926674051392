import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";

import { KpiProps } from "types/kpi";
import { AppContext } from "providers";
import { useFormatter, useContainerDimensions } from "hooks";

import UP from "assets/icons/arrow_up.svg";
import DOWN from "assets/icons/arrow_down.svg";
import EQUAL from "assets/icons/arrow_equal.svg";
import ApiService from "services/request";
import * as S from "./style";

type PeriodType = { name: number; count: number };

interface AppContextProps extends KpiProps {
  token: string;
}

const Mif: FC = () => {
  const { formatValue } = useFormatter();
  const { t } = useTranslation();
  const { kpi, token }: AppContextProps = useContext(AppContext);
  const { config, partner } = useContext(AppContext);
  const chartRef = useRef(null);
  const dimensions = useContainerDimensions(chartRef);
  const [isRequest, setRequest] = useState<boolean>(false);
  const [yearsOcc, setYearsOcc] = useState<{
    pre: PeriodType;
    post: PeriodType;
  } | null>(null);
  const [year, setYear] = useState<{
    past: PeriodType;
    actual: PeriodType;
  } | null>(null);

  const months = t("global.month", { returnObjects: true });

  // Boutton que le client ne souhaite plus afficher mais que nous gardons au cas où il souhaite réactiver la fonctionnalité
  const displayImportButton = false;

  const exportXlsx = () => {
    setRequest(true);
    ApiService.getXlsx(
      token,
      partner?.config.id ? `?userId=${partner.config.id}` : "",
      "mif"
    ).then((res) => {
      const downloadLink = document.createElement("a");
      const fileName = `mif-${Date.now()}.xlsx`;
      downloadLink.href = res.data;
      downloadLink.download = fileName;
      downloadLink.click();
      downloadLink.remove();
      setRequest(false);
    });
  };

  useEffect(() => {
    if (kpi?.base?.data) {
      const { data } = kpi.base;
      let i = 0;
      let name = "";
      data.forEach((d: any) => {
        i += 1;
        name = d.YEAR;
      });
      setYear({
        past: { name: parseFloat(name) - 1, count: 12 - i },
        actual: { name: parseFloat(name), count: i },
      });

      let lastYear = 0;
      let nextYear = 0;

      data.forEach((item: { [key: string]: string }, index: number) => {
        if (index < 1) {
          lastYear = parseInt(item.YEAR, 0);
        }

        if (parseInt(item.YEAR, 0) !== lastYear) {
          nextYear = parseInt(item.YEAR, 0);
        }
      });

      const countLast = data.filter(
        (obj: { [k: string]: string }) => parseInt(obj.YEAR, 0) === lastYear
      ).length;

      const countNext = data.filter(
        (obj: { [k: string]: string }) => parseInt(obj.YEAR, 0) === nextYear
      ).length;

      setYearsOcc({
        pre: { name: lastYear, count: countLast },
        post: { name: nextYear, count: countNext },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpi]);

  const renderTooltip = (e: any) => {
    if (e.active && e.payload) {
      const data = e.payload.reverse();
      return (
        <S.CustomTooltip>
          {data.map((payload: any) => (
            <S.TooltipValue fill={payload.fill}>
              {Math.round(parseFloat(payload.value))}
            </S.TooltipValue>
          ))}
        </S.CustomTooltip>
      );
    }
    return null;
  };

  const renderArrow = () => {
    const value = kpi?.versus?.data?.PERCENT;
    if (value > 0) return <S.Indicator src={UP} alt="up" />;
    if (value < 0) return <S.Indicator src={DOWN} alt="down" />;
    return <S.Indicator src={EQUAL} alt="equal" />;
  };

  return (
    <Row justify="space-between">
      <Col xl={16} lg={12} xs={24}>
        <S.Category>{t("kpis.mif.base")}</S.Category>
        <S.ColChart>
          <S.RowChart>
            <S.DotLegend>
              <S.Dot fill="#c34400" />
              <S.LegendTitle>{t("kpis.mif.legend.high")}</S.LegendTitle>
              <S.Dot fill="#9b2583" />
              <S.LegendTitle>{t("kpis.mif.legend.mid")}</S.LegendTitle>
              <S.Dot fill="#1a6fc5" />
              <S.LegendTitle>{t("kpis.mif.legend.entry")}</S.LegendTitle>
              {!!displayImportButton && (
                <S.Submit
                  idle={!isRequest}
                  loading={isRequest}
                  onClick={() => exportXlsx()}
                >
                  {isRequest ? t("kpis.mif.loading") : t("kpis.mif.export")}
                </S.Submit>
              )}
            </S.DotLegend>
            <S.Versus>
              {t("kpis.mif.legend.versus")}
              <S.Value>
                {kpi?.versus?.data?.PERCENT}%{renderArrow()}
              </S.Value>
            </S.Versus>
          </S.RowChart>
          <div ref={chartRef}>
            <ResponsiveContainer width="95%" height={300}>
              <BarChart
                data={kpi?.base?.data}
                barSize={14}
                barCategoryGap={-10}
              >
                <XAxis
                  dataKey="MONTH"
                  tickFormatter={(month: any) => months[month - 1]}
                />
                <YAxis
                  tickFormatter={(value: any) => formatValue(value)}
                  type="number"
                />
                <CartesianGrid vertical={false} />
                <Tooltip
                  cursor={false}
                  contentStyle={{
                    fontWeight: "bold",
                    borderRadius: 3,
                  }}
                  content={(e: PropsWithChildren<any> | TooltipProps) =>
                    renderTooltip(e)
                  }
                  label="null"
                  labelStyle={{ display: "none" }}
                  labelFormatter={(month: any) => months[month - 1]}
                  itemSorter={() => -1}
                  formatter={(value: any, name: any) => [parseFloat(name)]}
                  viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
                />
                <Bar
                  dataKey="ENTRY"
                  stackId="MONTH"
                  fill="#1a6fc5"
                  radius={[0, 0, 0, 0]}
                />
                <Bar
                  dataKey="MID"
                  stackId="MONTH"
                  fill="#9b2583"
                  radius={[0, 0, 0, 0]}
                />
                <Bar
                  dataKey="HIGH"
                  stackId="MONTH"
                  fill="#c34400"
                  radius={[20, 20, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
          {yearsOcc?.pre && yearsOcc?.post && (
            <S.Underline dimensions={dimensions}>
              <S.Year months={yearsOcc.pre.count}>
                {yearsOcc.pre.name || ""}
              </S.Year>
              <S.Year months={yearsOcc.post.count}>
                {yearsOcc.post.name || ""}
              </S.Year>
            </S.Underline>
          )}
        </S.ColChart>
      </Col>
      <Col xl={8} lg={12} xs={24}>
        <S.Category>{t("kpis.mif.xpps")}</S.Category>
        {partner?.config?.xpps || config?.user?.xpps ? (
          <S.RowProgress>
            <S.SpeedometerWrapper>
              <S.SpeedometerTitle>
                {year?.past.name} {t("kpis.xpps.ytd")}
              </S.SpeedometerTitle>
              <S.Speedometer
                gapDegree={138}
                strokeWidth={12}
                strokeLinecap="square"
                strokeColor="#d92231"
                type="dashboard"
                width={186}
                percent={kpi?.xpps?.data?.PERCENT_Y_N1}
              />
            </S.SpeedometerWrapper>
            <S.SpeedometerWrapper>
              <S.SpeedometerTitle>
                {year?.actual.name} {t("kpis.xpps.ytd")}
              </S.SpeedometerTitle>
              <S.Speedometer
                gapDegree={138}
                strokeWidth={12}
                strokeLinecap="square"
                strokeColor="#d92231"
                type="dashboard"
                width={186}
                percent={kpi?.xpps?.data?.PERCENT_Y}
              />
            </S.SpeedometerWrapper>
          </S.RowProgress>
        ) : (
          <S.RowProgress>
            <S.SpeedometerWrapper>
              <S.SpeedometerMessage>
                {t("kpis.xpps.message")}
              </S.SpeedometerMessage>
            </S.SpeedometerWrapper>
          </S.RowProgress>
        )}
      </Col>
    </Row>
  );
};

export default Mif;
