import { Button, Table, Typography } from "antd";
import { styled } from "theme/theme";

const { Title } = Typography;

export const Wrapper = styled.div`
  width: 100%;
  padding: 40px;
  background-color: ${(props) => props.theme.color.lightAccent};
  margin-bottom: 16px;
`;

export const HeadTitle = styled(Title)`
  font-size: 40px;
  margin-top: 0;
  font-weight: 300 !important;
  color: ${(props) => props.theme.color.info} !important;
`;

export const Filters = styled.div`
  display: flex;
  justify-content: space-between;
  transition: 300ms;
  width: 100%;
  margin: 50px 0;
`;

export const Reset = styled(Button)`
  text-decoration-line: underline;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.color.info};
  text-transform: uppercase;
  margin-top: 6px;
  :hover {
    color: ${(props) => props.theme.color.info};
    border-color: ${(props) => props.theme.color.info};
  }
  :active {
    color: ${(props) => props.theme.color.info} !important;
    border-color: ${(props) => props.theme.color.info};
  }
  :focus {
    color: ${(props) => props.theme.color.info} !important;
    border-color: ${(props) => props.theme.color.info};
  }
`;

export const DashboardTable = styled(Table)`
  margin-top: 20px;
  & .ant-table-thead > tr > th {
    font-weight: 600;
    text-align: center;
    & .under_title {
      font-weight: 400;
      font-size: 12px;
    }
  }
  & .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 2px 16px;
    text-align: center;
  }
  & .ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: rgba(0, 0, 0, 0.03);
  }
  & .ant-checkbox:hover {
    outline: ${(props) => props.theme.color.info} !important;
    border-color: ${(props) => props.theme.color.info} !important;
  }
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) => props.theme.color.info};
    border-color: ${(props) => props.theme.color.info};
  }
  & .ant-pagination-item-active {
    border-color: ${(props) => props.theme.color.info};
    a,
    a:hover {
      color: ${(props) => props.theme.color.info};
    }
  }
  & .ant-pagination-item:focus,
  .ant-pagination-item:hover,
  .ant-pagination-item a:hover {
    border-color: ${(props) => props.theme.color.info};
    color: ${(props) => props.theme.color.info};
  }
  & .ant-spin-dot-item {
    background-color: ${(props) => props.theme.color.mainBrand};
  }
`;

export const TabIcon = styled.img`
  margin-left: 8px;
`;

export const CellCustom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & p {
    margin: 0.5rem 0;
  }
`;

export const ButtonRedirect = styled(Button)`
  background: none;
  color: blue;
  text-decoration: underline;
  padding: 0;
  height: initial;
  border: none;
`;
