import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  BarChart,
  Bar,
  ReferenceLine,
  Cell,
  YAxis,
  LabelList,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Col, Row, Skeleton } from "antd";

import { lightTheme } from "theme/theme";
import UP from "assets/icons/arrow_up.svg";
import DOWN from "assets/icons/arrow_down.svg";
import { KpiProps } from "types/kpi";
import { AppContext } from "providers";
import { DoubleTable } from "components";
import { useContainerDimensions } from "hooks";
import Chart from "./Chart";
import ChartTitle from "./ChartTitle";
import * as S from "./style";

type FormattedData = { [key: string]: string | number };

const colors = ["#f67e00", "#1a6fc5", "#9b2583"];
const ARROW_DOWN = "DOWN";

const Market = ({ country }: { country: string | undefined }): ReactElement => {
  const { t } = useTranslation();
  const { lg, md } = useBreakpoint();
  const { kpi }: KpiProps = useContext(AppContext);
  const chartRef = useRef(null);
  const dimensions = useContainerDimensions(chartRef);
  const [segment, setSegment] = useState("ENTRY");
  const [dataChart, setDataChart] = useState<[FormattedData][]>([]);
  const [prod, setProd] = useState<FormattedData[]>([]);
  const colorTranslation: string[] = t("kpis.market.color.chart", {
    country: t(`global.country.${country?.toUpperCase()}`),
    returnObjects: true,
  });
  const prodTranslation: string[] = t("kpis.market.entry.chart", {
    country: t(`global.country.${country?.toUpperCase()}`),
    returnObjects: true,
  });

  const handleSelectSegment = () => {
    setSegment(segment === "ENTRY" ? "MID" : "ENTRY");
  };

  useEffect(() => {
    if (kpi.color && dataChart.length < 1) {
      let formattedData: [FormattedData][] = [];
      let data: FormattedData = {};
      const keys: string[] = Object.keys(kpi.color.data);
      keys.forEach((name) => {
        data = {};
        Object.values(kpi.color?.data[name]).forEach((values: any, index) => {
          if (index < 2) {
            data[`DIRECTION-${index + 1}`] = values.DIRECTION;
            data[`PERCENT-${index + 1}`] = values.PERCENT;
            data[`EVOL-${index + 1}`] = values.EVOL;
            data.NAME = name;
          }
        });
        formattedData = [[data], ...formattedData];
      });
      setDataChart(formattedData);
    }
    if (kpi.prod && prod.length < 1) {
      const formatted = kpi.prod.data.map(
        (original: { [key: string]: string }) => ({
          TYPE: original.TYPE,
          EVOL: parseInt(original.EVOL, 0),
        })
      );
      setProd([].concat(formatted).reverse());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpi]);

  return (
    <Row gutter={[16, 16]}>
      <Col xl={10} lg={12} md={24}>
        <S.ColTitle>
          <S.Category>{t("kpis.market.color.title")}</S.Category>
          <S.SubCategory>{t("kpis.market.color.subtitle")}</S.SubCategory>
        </S.ColTitle>
        {dataChart.length >= 3 ? (
          <S.DotLegend>
            <S.Dot fill={lightTheme.color.orange} />
            <S.LegendTitle>1-30 ppm</S.LegendTitle>
            <S.Dot fill={lightTheme.color.mainBrand} />
            <S.LegendTitle>31+ ppm</S.LegendTitle>
          </S.DotLegend>
        ) : (
          <Skeleton.Input
            style={{ width: 500, height: 40, marginTop: 10 }}
            active
            size="large"
          />
        )}
        {dataChart.length >= 3 ? (
          dataChart.map((data: any, index: number) => (
            <div key={index.toString()} style={{ marginLeft: -26 }}>
              <S.ChartContent ref={chartRef}>
                <ChartTitle
                  type="color"
                  text={colorTranslation[index]}
                  index={index}
                />
                <Chart data={data} width={dimensions.width} />
                <S.ChartArrow
                  src={
                    kpi?.color?.data[data[0].NAME].Total.DIRECTION ===
                    ARROW_DOWN
                      ? DOWN
                      : UP
                  }
                />
                <S.ChartValue>
                  {kpi?.color?.data[data[0].NAME].Total.EVOL}%
                </S.ChartValue>
              </S.ChartContent>
            </div>
          ))
        ) : (
          <Skeleton.Input
            style={{ width: 500, height: 340, marginTop: 24 }}
            active
            size="large"
          />
        )}
        {kpi.prod && <S.Asterisk>*{t("kpis.market.update")}</S.Asterisk>}
      </Col>
      <S.CenterCol xl={6} lg={12} md={24} bordered={lg && md === true ? 1 : 0}>
        <S.ColTitle>
          <S.Category>{t("kpis.market.entry.title")}</S.Category>
          <S.SubCategory>{t("kpis.market.entry.subtitle")}</S.SubCategory>
          {kpi.prod ? (
            <S.BarLegend>
              {prodTranslation.map((title, index) => (
                <ChartTitle type="prod" text={title} index={index} />
              ))}
            </S.BarLegend>
          ) : (
            <Skeleton.Input
              style={{ width: 300, height: 40, marginTop: 10 }}
              active
              size="large"
            />
          )}
          {prod ? (
            <>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={prod}
                  margin={{
                    top: 0,
                    right: 30,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <ReferenceLine
                    y={0}
                    strokeWidth={0.5}
                    stroke={lightTheme.color.grey}
                  />
                  <YAxis domain={["auto", (dataMax) => dataMax + 100]} hide />
                  <Bar
                    barSize={32}
                    dataKey="EVOL"
                    fill={lightTheme.color.grey}
                    radius={[32, 32, 0, 0]}
                  >
                    <LabelList
                      formatter={(value) =>
                        value > 0 ? `+${value}%` : `${value}%`
                      }
                      dataKey="EVOL"
                      position="top"
                    />
                    {prod.map((entry: any, index: number) => (
                      <Cell key={`cell-${index}`} fill={colors[index]} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </>
          ) : (
            <Skeleton.Input
              style={{ width: 390, height: 340, marginTop: 24 }}
              active
              size="large"
            />
          )}
        </S.ColTitle>
      </S.CenterCol>
      <S.CustomSection xl={8} lg={24} md={24}>
        <S.Category>{t("kpis.market.segment.title")}</S.Category>
        <S.SelectGroup>
          <S.SelectSegment
            active={segment === "ENTRY"}
            onClick={() => handleSelectSegment()}
          >
            {t("kpis.market.segment.switch.entry")}
          </S.SelectSegment>
          <S.SelectSegment
            secondary
            active={segment === "MID"}
            onClick={() => handleSelectSegment()}
          >
            {t("kpis.market.segment.switch.mid")}
          </S.SelectSegment>
        </S.SelectGroup>
        {kpi.segment ? (
          <DoubleTable rowData={kpi.segment.data[segment]} />
        ) : (
          <Skeleton.Input
            style={{ width: 700, height: 340, marginTop: 20 }}
            active
            size="large"
          />
        )}
      </S.CustomSection>
    </Row>
  );
};

export default Market;
