import React, { FC, useContext } from "react";
import { useHistory } from "react-router-dom";

import { AppContext } from "providers";
import { Loader } from "components";
import routes from "router/routes";
import { useLocal } from "hooks";

const Redirect: FC = () => {
  const { setLang } = useLocal();
  const { config } = useContext(AppContext);
  const history = useHistory();
  React.useEffect(() => {
    setLang(config?.user?.lang);
    if (config?.authorizations?.pages?.homepage) history.push(routes.HOME);
    if (config?.authorizations?.pages?.welcomeMessage)
      history.push(routes.WELCOME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, history]);

  return <Loader wrap />;
};

export default Redirect;
