import { Col, Typography } from "antd";
import { styled } from "theme/theme";

const { Text, Title } = Typography;

type DotProps = {
  fill: string;
};

type SelectProps = {
  active: boolean;
  secondary?: boolean;
};

export const Select = styled.div`
  width: 110px;
  padding: 8px 0;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
`;

type BarProps = {
  width?: number;
};

export const ColTitle = styled(Text)`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.info};
`;

export const DotLegend = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin: 28px 0;
`;

export const Dot = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background-color: ${(color: DotProps) => color.fill};
  margin-right: 4px;
`;

export const LegendTitle = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  color: ${(props) => props.theme.color.info};
  text-transform: capitalize;
  margin-right: 28px;
`;

export const RowChart = styled.div`
  display: flex;
`;

export const Square = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Year = styled(Text)`
  font-weight: 300 !important;
  font-size: 16px;
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid ${(props) => props.theme.color.info};
`;

export const Value = styled.div`
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 200px;
  height: 50px;
  margin-bottom: 20px;
`;

export const Total = styled(Text)`
  font-weight: 700;
  text-align: center;
  font-size: 16px;
  color: ${(props) => props.theme.color.info}80;
`;

export const Percentage = styled.span`
  font-weight: 700;
  font-size: 20px;
  color: ${(props) => props.theme.color.info};
`;

export const Chart = styled.div`
  margin-right: 50px;
`;

export const CustomColumn = styled(Col)`
  position: relative;
  margin-bottom: 16px;

  &:after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #c1c1c1;
    position: absolute;
    top: 0;
    right: 32px;
  }
`;

export const CustomColumnTops = styled(Col)`
  padding: 34px 29px 29px 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const TopsHeaderGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TopsTitle = styled.div`
  color: ${(props) => props.theme.color.grey};
  font-size: 14px;
  font-weight: 600;
`;

export const SelectGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  & div {
    border: 1px solid ${(props) => props.theme.color.lightShade};
    :first-of-type {
      border-right-width: 0 !important;
      color: ${(props) => props.theme.color.white};
    }
    :last-of-type {
      border-left-width: 0 !important;
      color: ${(props) => props.theme.color.lightShade};
      background-color: ${(props) => props.theme.color.lightAccent};
    }
  }
`;

export const SelectSegment = styled(Select)`
  background-color: ${(is: SelectProps) =>
    // eslint-disable-next-line no-nested-ternary
    is.active
      ? (props) =>
          is.secondary ? props.theme.color.orange : props.theme.color.purple
      : (props) => props.theme.color.lightAccent} !important;
  color: ${(is: SelectProps) =>
    is.active
      ? (props) => props.theme.color.white
      : (props) => props.theme.color.info} !important;
  border-width: ${(is: SelectProps) => (is.active ? 0 : "1px")} !important;
`;

export const BarLine = styled.div`
  margin-bottom: 16px;
`;

export const BarContent = styled.div`
  width: calc(100% - 95px);
`;

export const Bar = styled.div`
  background-color: ${(props) => props.theme.color.grey};
  height: 20px;
  border-radius: 4px;
  position: relative;
  width: ${(is: BarProps) => `${is.width}%`};
`;

export const BarUnit = styled.span`
  position: absolute;
  right: -95px;
`;

export const OverlayTitle = styled(Title)`
  font-weight: 300 !important;
  text-align: center;
  max-width: 916px;
  margin-left: auto;
  margin-right: auto;
`;

export const MainList = styled.div`
  max-width: 746px;
  margin: auto;
  font-size: 16px;
`;

export const OverlayTitleBottom = styled(Title)`
  font-weight: 300 !important;
  text-align: center;
  max-width: 916px;
  color: ${(props) => props.theme.color.mainBrand} !important;
  margin: 30px auto 0 auto;
`;

export const OverlayListTitle = styled(Title)`
  font-weight: bold;
  font-size: 20px !important;
  margin-bottom: 0 !important;
`;

export const OverlayList = styled.ul`
  padding: 0 0 0 15px;
  list-style: none;
`;

export const OverlayListElement = styled.li`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 11px;
    left: -15px;
    width: 8px;
    height: 8px;
    background-color: #d92231;
  }
`;
