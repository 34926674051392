import React, { FC, useState, useContext, useEffect } from "react";
import { Radio, Skeleton } from "antd";
import { useTranslation } from "react-i18next";

import { AppContext } from "providers";
import * as S from "./style";

type FilterProps = {
  type: string;
  data: {
    name: string;
    drop: { value: string | number; label: string; disabled?: boolean }[];
  }[];
  displayResult?: boolean;
  useCheckbox?: boolean;
  inlineSelected?: boolean;
  spaced?: number;
  updateManager?: React.SetStateAction<any>;
  updatingFilters?: boolean;
  forceReset?: boolean;
  onRemove?: string | null;
  displayResetButton?: boolean;
};

// type State = { [key: string]: [key: string] };

const Filters: FC<FilterProps> = ({
  type,
  data,
  displayResult,
  useCheckbox,
  inlineSelected,
  spaced,
  updateManager,
  updatingFilters,
  forceReset,
  onRemove,
  displayResetButton,
}: FilterProps) => {
  const { t } = useTranslation();
  const { config, partner, updatePartner } = useContext(AppContext);
  const [state, setState] = useState<any>({});
  const [, setAllowed] = useState<{ [key: string]: boolean }>({});

  const removeStateValue = (key: string) => {
    delete state[key];
    setState({ ...state });
    return null;
  };

  useEffect(() => {
    if (forceReset) setState({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceReset]);

  useEffect(() => {
    if (updateManager) {
      updateManager(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, type]);

  useEffect(() => {
    if (type === "fdv" && onRemove) {
      removeStateValue(onRemove);
      updateManager(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRemove]);

  useEffect(() => {
    if (type === "br") setAllowed(config?.authorizations?.filters?.br);
    if (type === "fdv") setAllowed(config?.authorizations?.filters?.kpis);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, type]);

  const updateState = (key: string, value: any) => {
    if (state[key] && state[key].indexOf(value) > -1) {
      const updatedValues = state[key].filter(
        (current: any) => current !== value
      );
      if (updatedValues.length === 0) {
        delete state[key];
        setState({ ...state });
        return true;
      }
      setState({ ...state, [key]: updatedValues });
      return true;
    }
    if (useCheckbox) {
      setState({
        ...state,
        [key]: [...value],
      });
      return true;
    }
    if (typeof state[key] === "undefined" || state[key].indexOf(value) === -1) {
      let values = state[key];
      if (values && useCheckbox) values.push(value);
      else values = [value];
      setState({ ...state, [key]: values });
      return true;
    }
    return true;
  };

  const renderCheckbox = (name: string, options: any) => (
    <S.CheckWrapper>
      {name === "country" ? (
        <S.CheckboxGroup
          options={options}
          onChange={(e) => updateState(name, e)}
          value={state[name]}
        />
      ) : (
        <S.CheckboxGroup
          options={options}
          onChange={(e) => updateState(name, e)}
          value={state[name]}
        />
      )}
    </S.CheckWrapper>
  );

  const capitalized = (str: string | number) => {
    if (typeof str === "number") return str;
    const splitStr = str.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i += 1) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  };

  const removeSelectedFilter = (filter: string) => {
    updateState(filter, state[filter][0]);
    if (partner) updatePartner(null);
  };

  return (
    <>
      <S.FiltersGroup>
        {data.map((filter, index) =>
          filter ? (
            <S.SelectorGroup key={index.toString()}>
              <S.Selector
                loading={updatingFilters}
                disabled={filter.drop.length <= 0}
                spaced={spaced}
                dropdownMatchSelectWidth={
                  !(
                    filter.name === "cbm" ||
                    filter.name === "cgm" ||
                    filter.name === "partners" ||
                    filter.name === "country"
                  )
                }
                suffixIcon={<S.DropdownIcon />}
                // value={filter.name.toUpperCase()}
                value={
                  type === "fdv"
                    ? filter.name.toUpperCase()
                    : t(`business.filters.${filter.name}`)
                }
                onChange={(value) => updateState(filter.name, value)}
                dropdownRender={
                  useCheckbox
                    ? () => renderCheckbox(filter.name, filter.drop)
                    : false
                }
              >
                {filter.drop.map((option, i) => (
                  <S.Item
                    key={i.toString()}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {filter.name !== "country"
                      ? capitalized(option.label.toLowerCase())
                      : option.label.toUpperCase() === "SE"
                      ? t(`global.lang.SW`)
                      : t(`global.lang.${option.label.toUpperCase()}`)}
                  </S.Item>
                ))}
              </S.Selector>
              {displayResult && !inlineSelected && state[filter.name] && (
                <S.Selected onClick={() => removeSelectedFilter(filter.name)}>
                  <S.CloseIcon />
                  {filter.name !== "country"
                    ? filter.drop
                        .filter(
                          (column) =>
                            column.value === parseFloat(state[filter.name])
                        )[0]
                        ?.label.toLowerCase() || removeStateValue(filter.name)
                    : t(
                        `global.lang.${state[filter.name].join().toUpperCase()}`
                      )}
                </S.Selected>
              )}
            </S.SelectorGroup>
          ) : (
            <Skeleton.Input active size="large" />
          )
        )}
      </S.FiltersGroup>
      {displayResult && inlineSelected && (
        <S.InlineSelected>
          {Object.entries(state).map((entry: any) =>
            entry[1].map((filter: any, i: number) => (
              <S.Selected
                key={i.toString()}
                onClick={() => updateState(entry[0], filter.toString())}
              >
                <S.CloseIcon />
                {filter}
              </S.Selected>
            ))
          )}
          {displayResetButton && Object.keys(state).length > 0 && (
            <S.Reset onClick={() => setState({})}>Reset all filters</S.Reset>
          )}
        </S.InlineSelected>
      )}
    </>
  );
};

Filters.defaultProps = {
  displayResult: false,
  useCheckbox: false,
  inlineSelected: false,
  spaced: undefined,
  updateManager: undefined,
  updatingFilters: false,
  forceReset: false,
  onRemove: null,
  displayResetButton: false,
};

export const ClearFilters: FC = () => {
  const clearAllState = () => {};
  return <S.Reset onClick={clearAllState}>Reset all filters</S.Reset>;
};

type PartnersProps = {
  list: any;
  updateManager?: React.SetStateAction<any>;
};

export const Partners: FC<PartnersProps> = ({
  list,
  updateManager,
}: PartnersProps) => {
  const [value, setValue] = useState<any>(0);
  const [formattedValue, setFormattedValue] = useState<any>({});

  useEffect(() => {
    if (value && updateManager) {
      updateManager({ partners: [value] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const drop: any = [];
    if (list) {
      list.forEach((el: any) => {
        drop.push({ value: el.id, label: el.name });
      });
      setFormattedValue({ name: "partners", drop });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <S.RadioWrapper>
      <S.RadioTitle>Your partners</S.RadioTitle>
      <Radio.Group onChange={(e) => onChange(e)} value={value}>
        {formattedValue?.drop &&
          formattedValue?.drop.map((partner: any) => (
            <S.CustomRadio
              key={partner.value}
              style={radioStyle}
              value={partner.value}
            >
              {partner.label}
            </S.CustomRadio>
          ))}
      </Radio.Group>
    </S.RadioWrapper>
  );
};

Partners.defaultProps = {
  updateManager: undefined,
};

export default Filters;
