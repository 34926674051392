import { FC } from "react";
import { Typography, Table, Progress } from "antd";
import { TitleProps } from "antd/lib/typography/Title";
import { TextProps } from "antd/lib/typography/Text";
import { ProgressProps } from "antd/lib/progress";

import { styled } from "theme/theme";

const { Text, Title } = Typography;

interface TotalProps extends TitleProps {
  primary?: boolean;
}

interface PieCardProps extends TitleProps {
  primary?: boolean;
}

interface CountryProps extends TitleProps {
  small?: boolean;
}

interface ProgressValue extends TitleProps {
  value?: number;
  color?: string;
}

interface SelectProps {
  active: boolean;
  secondary?: boolean;
}

type DotProps = {
  fill: string;
};

/* === PERFORMANCE === */

export const Wrapper = styled.div`
  padding: 20px 50px;
`;

export const PanTitle: FC<TitleProps> = styled(Title)`
  font-weight: 300 !important;
`;

export const Category: FC<TextProps> = styled(Text)`
  font-size: 14px;
  margin-left: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.info};
`;

export const RowChart = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
`;

export const Square = styled.div`
  background-color: ${(props) => props.theme.color.lightShade};
  width: 150px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px;
`;

export const Year: FC<TextProps> = styled(Text)`
  font-weight: 300 !important;
  font-size: 18px;
  text-align: center;
  padding-bottom: 5px;
  border-bottom: 1px solid ${(props) => props.theme.color.info};
`;

export const Value = styled.div`
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 150px;
`;

export const Total: FC<TotalProps> = styled(Text)`
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  color: ${(is: TotalProps) =>
    is.primary
      ? (props) => props.theme.color.variant
      : (props) => props.theme.color.alternative};
`;

export const Percentage: FC<TextProps> = styled(Text)`
  font-weight: 700;
  font-size: 20px;
  color: ${(props) => props.theme.color.info};
`;

export const Country: FC<CountryProps> = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.color.lightShade};
  margin: 20px 15px;
  border-radius: 60px 0 0 60px;
  height: ${(is: CountryProps) => (is.small ? "50px" : "60px")};
  justify-content: ${(is: CountryProps) =>
    is.small ? "flex-start" : "space-between"};
  & .ant-typography {
    font-size: ${(is: CountryProps) => (is.small ? "11px" : "18px")};
  }
  & img {
    width: ${(is: CountryProps) => (is.small ? "50px" : "60px")};
    height: ${(is: CountryProps) => (is.small ? "50px" : "60px")};
  }
`;

export const Flag = styled.img`
  object-fit: fill;
  border-radius: 60px;
  background-color: ${(props) => props.theme.color.lightShade};
  color: ${(props) => props.theme.color.mainBrand};
  text-align: center;
`;

export const Info: FC<TextProps> = styled(Text)`
  font-weight: 300;
  margin-left: 10px;
  color: ${(props) => props.theme.color.info};
`;

export const Perf: FC<TextProps> = styled(Text)`
  font-weight: 700;
  font-size: 32px !important;
  height: 60px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  line-height: 1.9;
  padding: 0 50px;
  color: ${(props) => props.theme.color.lightAccent};
  background-color: ${(props) => props.theme.color.pulpe};
`;

/* === SUPPORT === */

export const SupportCard = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(is: PieCardProps) =>
    is.primary
      ? (props) => props.theme.color.orange
      : (props) => props.theme.color.lightAccent};
  > * {
    color: ${(is: PieCardProps) =>
      is.primary
        ? (props) => props.theme.color.white
        : (props) => props.theme.color.info} !important;
  }
`;

export const SupportCardRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  flex-flow: wrap;
`;

export const SupportCardTitle: FC<TextProps> = styled(Text)`
  font-weight: 700;
  padding: 35px 20px;
  color: ${(props) => props.theme.color.lightAccent};
  width: 100%;
  text-transform: uppercase;
`;

export const ProgressBarContent = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const ProgressBarTitle: FC<TextProps> = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  color: ${(props) => props.theme.color.info};
  text-align: left;
  transition: 300ms;
`;
export const ProgressBarValue: FC<ProgressValue> = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: ${(progress: ProgressValue) =>
    progress.color ? progress.color : (props) => props.theme.color.info};
  text-align: right;
  text-transform: uppercase;
  width: ${(progress: ProgressValue) =>
    progress.value && progress.value <= 0 ? 10 : progress.value}%;
  transition: 300ms;
`;

export const ProgressBar: FC<ProgressProps> = styled(Progress)`
  & .ant-progress-text {
    display: none;
  }
  .ant-progress-outer {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
`;

export const SupportContentCard: FC = styled.div`
  //background: aquamarine;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding: 0 20px;
`;

export const SupportCardValue: FC<TextProps> = styled(Text)`
  font-weight: 700;
  font-size: 24px;
  color: ${(props) => props.theme.color.info};
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  & span {
    font-weight: 400;
    font-size: 16px;
    padding-left: 6px;
    opacity: 0.9;
  }
`;

export const MinimalTable: FC<any> = styled(Table)`
  min-width: 328px !important;
  margin-top: 40px;
  & td {
    text-align: center !important;
    padding: 4px !important;
  }
  & th {
    text-align: center !important;
    padding: 8px 4px !important;
  }
`;

/* === PRODUCT === */

export const SelectGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  & div {
    border: 1px solid ${(props) => props.theme.color.lightShade};
    :first-of-type {
      border-right-width: 0 !important;
      background-color: #27800e;
      color: ${(props) => props.theme.color.white};
    }
    :last-of-type {
      border-left-width: 0 !important;
      color: ${(props) => props.theme.color.lightShade};
      background-color: ${(props) => props.theme.color.lightAccent};
    }
  }
`;

export const Select = styled.div`
  width: 110px;
  padding: 8px 0;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
`;

export const SelectPaper = styled(Select)`
  background-color: ${(is: SelectProps) =>
    // eslint-disable-next-line no-nested-ternary
    is.active
      ? is.secondary
        ? "#f67e00"
        : "#27800e"
      : (props) => props.theme.color.lightAccent}!important;
  color: ${(is: SelectProps) =>
    is.active
      ? (props) => props.theme.color.white
      : (props) => props.theme.color.lightShade}!important;
  border-width: ${(is: SelectProps) => (is.active ? 0 : "1px")}!important;
`;

export const SelectToner = styled(Select)`
  background-color: ${(is: SelectProps) =>
    // eslint-disable-next-line no-nested-ternary
    is.active
      ? is.secondary
        ? "#1a6fc5"
        : "#9b2583"
      : (props) => props.theme.color.lightAccent}!important;
  color: ${(is: SelectProps) =>
    is.active
      ? (props) => props.theme.color.white
      : (props) => props.theme.color.lightShade}!important;
  border-width: ${(is: SelectProps) => (is.active ? 0 : "1px")}!important;
`;

export const CompactTable: FC<any> = styled(Table)`
  margin-top: 30px;
`;

/* === MIF === */

export const RowProgress = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 28px;
  flex: 1;
  height: 100%;
`;

export const Speedometer: FC<ProgressProps> = styled(Progress)`
  & .ant-progress-text {
    font-weight: 700;
    font-size: 16px;
    color: ${(props) => props.theme.color.info};
  }
  .ant-progress-outer {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
`;

/* === ASF === */

export const Legend = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  height: 25%;
  :first-of-type {
    margin-top: 25%;
  }
`;

export const LegendTitle: FC<TextProps> = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  color: ${(props) => props.theme.color.info};
  text-align: left;
  text-transform: capitalize;
`;
export const LegendData = styled.div`
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  min-width: 130px;
  max-width: 100%;
`;

export const LegendHorizontal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LegendValue: FC<TextProps> = styled(Text)`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => props.theme.color.info};
  text-align: left;
  text-transform: uppercase;
`;

export const DotLegend = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin: 10px 0;
`;

export const Dot: FC<DotProps> = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background-color: ${(color: DotProps) => color.fill};
  margin-left: 10px;
  margin-right: 4px;
`;

export const ColChart: FC = styled.div`
  display: flex;
  flex-direction: column;
`;
