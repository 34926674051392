const routes = {
  ROOT: "/",
  REDIRECT: "/redirect",
  AUTH: "/authentification",
  HOME: "/home",
  WELCOME: "/welcome",
  PERFORMANCE: "/performance",
  BUSINESS_REVIEW: "/business-review",
  CHANGELOG: "/changelog",
  PORTAL: "/auth/saml/login",
  ADMIN: "/admin",
  RESET: "/reset",
  EOS: "/EOS",
};

export const access = {
  WELCOME: "welcomeMessage",
  PERFORMANCE: "kpis",
  BUSINESS_REVIEW: "br",
  HOME: "homepage",
  ADMIN: "admin",
  RESET: "reset",
};

export const navigation = [
  { name: "global.nav.admin", root: routes.ADMIN, authorization: "admin" },
  { name: "global.nav.home", root: routes.HOME, authorization: "homepage" },
  {
    name: "global.nav.welcome",
    root: routes.WELCOME,
    authorization: "welcomeMessage",
  },
  {
    name: "global.nav.performance",
    root: routes.PERFORMANCE,
    authorization: "kpis",
  },
  {
    name: "global.nav.business",
    root: routes.BUSINESS_REVIEW,
    authorization: "br",
  },
];

export default routes;
