import React, { FC, useContext, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";

import { AppContext } from "providers";
import * as S from "./style";

interface LoaderProps {
  wrap?: boolean;
}

// eslint-disable-next-line react/jsx-filename-extension
const antIcon = <LoadingOutlined />;

const Loader: FC<LoaderProps> = ({ wrap }: LoaderProps) => {
  const { mounted, mounting } = useContext(AppContext);

  useEffect(() => {
    try {
      mounting(false);
    } catch {
      // error(JSON.stringify(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  if (!wrap) return <S.Loader indicator={antIcon} />;
  return (
    <S.Wrapper>
      <S.Loader indicator={antIcon} />
    </S.Wrapper>
  );
};

Loader.defaultProps = {
  wrap: false,
};

export default Loader;
