import { FC } from "react";
import { Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";

import { styled } from "theme/theme";

const { Text } = Typography;

interface ProgressProps {
  value: number;
  color: string;
}

interface ValueProps extends TextProps {
  color: string;
}

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 55%;
`;

export const Icon = styled.img`
  height: 25%;
  object-fit: contain;
  text-align: center;
  width: 94px;
`;

export const Status: FC<TextProps> = styled(Text)`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.info};
`;

export const ProgressValue: FC<ValueProps> = styled(Text)`
  margin-top: 20px;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(active: ValueProps) => active.color};
`;

export const ProgressOuter = styled.div`
  width: 50%;
  height: 6px;
  background: red;
  border-radius: 6px;
  background: ${(props) => props.theme.color.lightShade};
`;

export const ProgressInner = styled.div`
  background: ${(progress: ProgressProps) => progress.color};
  width: ${(progress: ProgressProps) => progress.value}%;
  height: 100%;
  border-radius: 6px;
  transition: 1s cubic-bezier(0.34, 0.46, 0, 1);
`;

export const UnitsValue: FC<ValueProps> = styled(Text)`
  margin-top: 20px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.color.info};
  & span {
    margin-left: 20px;
    color: ${(active: ValueProps) => active.color};
  }
`;
