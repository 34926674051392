import React, { useContext, useEffect } from "react";
import { AppContext } from "providers";
import { proc } from "utils/api-common";
import { useTranslation } from "react-i18next";
import * as S from "../style";

type TConnectivityFilterProps = {
  selectedFilter: string | null;
  setSelectedFilter: (value: string | null) => void;
  loadingConnectivityData: boolean;
};

const ConnectivityFilters = ({
  selectedFilter,
  setSelectedFilter,
  loadingConnectivityData,
}: TConnectivityFilterProps) => {
  const { getConnectivityFilters, connectivityFilters, partner } = useContext(
    AppContext
  );

  const { t } = useTranslation();

  const handleChangeSelectedFilter = (value: string) => {
    setSelectedFilter(value);
  };

  const handleRemoveFilter = () => {
    setSelectedFilter(null);
  };

  useEffect(() => {
    getConnectivityFilters({
      route: `${proc.connectivity.filters.route}${
        partner?.queries ? `?userId=${partner.config.id}` : ""
      }`,
      name: proc.connectivity.filters.name,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner]);

  return (
    <S.Collapse>
      <S.Selector
        loading={!connectivityFilters || loadingConnectivityData}
        disabled={!connectivityFilters || loadingConnectivityData}
        suffixIcon={<S.DropdownIcon />}
        value={selectedFilter}
        placeholder={t("companies")}
        onChange={handleChangeSelectedFilter}
        dropdownMatchSelectWidth={false}
      >
        {connectivityFilters?.companies.length > 0 &&
          connectivityFilters.companies.map(
            (company: string, index: number) => (
              <S.SelectorOption key={index.toString()} value={company}>
                {company}
              </S.SelectorOption>
            )
          )}
      </S.Selector>
      {selectedFilter && (
        <S.Selected onClick={handleRemoveFilter}>
          <S.CloseIcon />
          {selectedFilter}
        </S.Selected>
      )}
    </S.Collapse>
  );
};

export default ConnectivityFilters;
