import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import UP from "assets/icons/arrow_up.svg";
import DOWN from "assets/icons/arrow_down.svg";
import { Wrapper, Container, Table } from "./style";

type Row = { [key: string]: any }[];

type TableProps = {
  rowData: { MARKET: Row; XEROX: Row };
};

const DoubleTable = ({ rowData }: TableProps): ReactElement => {
  const { t } = useTranslation();

  const renderValue = (value: number) => {
    if (value > 0) return `+${value}`;
    if (value === 0) return "0";
    return value;
  };

  return (
    <Wrapper>
      <Container>
        <Table>
          <tr>
            <td rowSpan={2} />
            <th colSpan={2} scope="colgroup">
              {t("kpis.market.segment.table.head.market.title")}
            </th>
            <div />
            <th colSpan={3} scope="colgroup">
              Xerox
            </th>
          </tr>
          <tr>
            <th scope="col">
              {t("kpis.market.segment.table.head.market.size")}
            </th>
            <th scope="col">
              {t("kpis.market.segment.table.head.market.growth")}
            </th>
            <div />
            <th scope="col">
              {t("kpis.market.segment.table.head.xerox.share")}
            </th>
            <th scope="col">
              {t("kpis.market.segment.table.head.xerox.contribution")}
            </th>
            <th scope="col">
              {t("kpis.market.segment.table.head.xerox.growth")}
            </th>
          </tr>
          {rowData.MARKET.map((data, index) => (
            <tr itemScope key={index.toString()}>
              <th scope="row">
                <b>{rowData.MARKET[index].PRODUCT}</b>
              </th>
              <td>{rowData.MARKET[index].UNIT}</td>
              <td>
                <img
                  src={rowData.MARKET[index].DIRECTION === "UP" ? UP : DOWN}
                  alt="arrow"
                />{" "}
                {renderValue(rowData.MARKET[index].EVOL)}%
              </td>
              <div color="white" />
              <td>{renderValue(rowData.XEROX[index].UNIT)}%</td>
              <td>{renderValue(rowData.XEROX[index].SHARE_EVOL)}%</td>
              <td>
                <img
                  src={rowData.XEROX[index].DIRECTION === "UP" ? UP : DOWN}
                  alt="arrow"
                />{" "}
                {renderValue(rowData.XEROX[index].EVOL)}%
              </td>
            </tr>
          ))}
        </Table>
      </Container>
    </Wrapper>
  );
};

export default DoubleTable;
