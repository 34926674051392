import { FC } from "react";
import { Button, Progress, Typography } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import { ProgressProps } from "antd/lib/progress";

import { styled } from "theme/theme";

type DotProps = {
  fill: string;
};

interface UnderlineProps {
  dimensions: { width: number; height: number };
}

type YearsProps = {
  months: number;
};

interface TooltipProps extends TextProps {
  fill: string;
}

const { Text } = Typography;

export const Category: FC<TextProps> = styled(Text)`
  font-size: 14px;
  margin-left: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.info};
`;

export const DotLegend = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  margin: 28px 0;
`;

export const Dot: FC<DotProps> = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 14px;
  background-color: ${(color: DotProps) => color.fill};
  margin-left: 10px;
  margin-right: 4px;
`;

export const ColChart: FC = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LegendTitle: FC<TextProps> = styled(Text)`
  font-weight: 300;
  font-size: 14px;
  color: ${(props) => props.theme.color.info};
  text-align: left;
  text-transform: capitalize;
`;

export const RowProgress = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 28px;
  flex: 1;
  height: 100%;
`;

export const SpeedometerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SpeedometerTitle: FC<TextProps> = styled(Text)`
  font-weight: bold;
  font-size: 14px;
  color: ${(props) => props.theme.color.info};
  text-align: center;
  margin-bottom: 20px;
`;

export const SpeedometerMessage: FC<TextProps> = styled(Text)`
  font-size: 14px;
  color: ${(props) => props.theme.color.info};
  text-align: justify;
  margin-bottom: 20px;
`;

export const Speedometer: FC<ProgressProps> = styled(Progress)`
  & .ant-progress-text {
    font-weight: 700;
    font-size: 16px;
    color: ${(props) => props.theme.color.info};
  }
  .ant-progress-outer {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }
`;

export const Underline: FC<UnderlineProps> = styled.div`
  width: ${(chart: UnderlineProps) => chart.dimensions.width - 88}px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-left: 14px;
  margin-top: -10px;
  cursor: default;
`;

export const Year: FC<YearsProps> = styled.div`
  width: ${(year: YearsProps) => year.months * 8}%;
  margin: 6px 10px;
  padding-top: 6px;
  border-top: 2px solid #3333336b;
  color: #111;
  text-align: center;
  opacity: 0.7;
`;

export const CustomTooltip = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.lightAccent};
  border: 1px solid ${(props) => props.theme.color.lightShade};
  border-radius: 2px;
  box-shadow: 1px 0 5px 0 rgba(0, 0, 0, 0.16);
`;

export const TooltipValue: FC<TooltipProps> = styled(Text)`
  font-weight: bold;
  color: ${(color: TooltipProps) => color.fill};
  padding: 5px 10px;
`;

export const RowChart = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
`;

export const Versus = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.color.darkShade};
`;

export const Value = styled(Text)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.info};
`;

export const Indicator = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 6px;
  object-fit: contain;
`;

export const Submit = styled(Button)`
  border: none;
  background: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.lightAccent}!important;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  margin-left: 30px;
  padding: ${(button: { idle: boolean }) => (button.idle ? "18px" : "auto")};
  ${(button: { idle: boolean }) => (button.idle ? "18px" : "auto")};
  & span {
    width: ${(button: { idle: boolean }) => (button.idle ? "250px" : "auto")};
    white-space: normal;
    font-size: 12px;
  }
  :hover {
    background: ${(props) => props.theme.color.mainBrand};
    border: none;
    color: white;
  }
  :focus {
    background: ${(props) => props.theme.color.primary};
    color: white;
  }
`;
