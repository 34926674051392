import React, { CSSProperties } from "react";
import { Typography, Drawer as SideNav, Button as Btn } from "antd";
import { TextProps } from "antd/lib/typography/Text";
import { DrawerProps } from "antd/lib/drawer";
import { ButtonProps } from "antd/lib/button";
import { BiUser } from "react-icons/bi";
import { FiMenu } from "react-icons/fi";
import { IconBaseProps } from "react-icons";

import { styled } from "theme/theme";

interface TextNewProps extends TextProps {
  onClick: () => void;
}

const { Text } = Typography;

export const UserIcon: React.FunctionComponent<IconBaseProps> = styled(BiUser)`
  color: ${(props) => props.theme.color.darkAccent};
`;

export const BurgerIcon: React.FunctionComponent<IconBaseProps> = styled(
  FiMenu
)`
  color: ${(props) => props.theme.color.darkAccent};
`;

export const Drawer: React.FunctionComponent<DrawerProps> = styled(SideNav)``;

export const Button: React.FunctionComponent<ButtonProps> = styled(Btn)`
  @media (min-width: 1240px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  // position: absolute;
  width: 100%;
  height: 92px;
  display: flex;
  justify-content: space-between;
  padding: 0 60px;
  align-items: center;
  background: ${(props) => props.theme.color.lightAccent};
  transition: 300ms;
  @media (max-width: 1240px) {
    height: 60px;
    padding: 10px;
  }
  @media (max-width: 768px) {
  }
`;

export const Nav = styled.div`
  height: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavItem: React.FunctionComponent<TextProps> = styled(Text)`
  height: 92px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.35px;
  text-align: center;
  padding: 0 4px;
  margin: 0 38px;
  color: ${(props) => props.theme.color.info};
  cursor: pointer;
  transition: 300ms;
  @media (max-width: 1240px) {
    height: 60px;
    padding: 0;
  }
  @media (max-width: 862px) {
    display: none;
  }
  :hover {
    margin-bottom: -6px;
    border-bottom: 6px solid ${(props) => props.theme.color.primary};
  }
`;

export const DrawerItem: React.FunctionComponent<TextProps> = styled(Text)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-transform: uppercase;
  letter-spacing: 0.35px;
  text-align: left;
  padding: 20px;
  margin: 0;
  cursor: pointer;
  transition: 300ms;
  :hover {
    background: ${(props) => props.theme.color.lightShade};
  }
`;

export const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const DrawerNav = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
`;

export const DrawerAccount = styled.div`
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Brand = styled.img`
  object-fit: contain;
  width: 214px;
  height: 44px;
  margin-right: 20px;
  align-self: center;
  @media (max-width: 1240px) {
    margin: 0;
    height: 24px;
    width: auto;
  }
`;

export const Account = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1240px) {
    display: none;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 20px;
`;

export const UserName: React.FunctionComponent<TextProps> = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.35px;
  text-align: left;
  transition: 300ms;
  color: ${(props) => props.theme.color.info};
`;

export const ActionText: React.FunctionComponent<TextNewProps> = styled(Text)`
  text-transform: uppercase;
  letter-spacing: 0.35px;
  text-align: left;
  cursor: pointer;
  transition: 300ms;
  color: ${(props) => props.theme.color.info};
  :hover {
    letter-spacing: 0;
  }
`;

export const Switch = styled.span`
  margin-left: 10px;
  color: ${(props) => props.theme.color.mainBrand};
`;

export const Row = styled.div`
  display: flex;
`;

export const active: CSSProperties = {
  marginBottom: -6,
  borderBottom: "6px solid #d92231",
};
