import axios, { AxiosInstance } from "axios";

export const dev: AxiosInstance = axios.create({
  baseURL: "http://srv-dev:1080/",
  headers: {
    "Accept-Encoding": "gzip",
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "http://xerox-justforyou.localhost",
  },
});

export const prod: AxiosInstance = axios.create({
  baseURL: "/api/",
  headers: { "Content-type": "application/json" },
});

export const admin: AxiosInstance = axios.create({
  baseURL: "/api/admin/",
  headers: {
    "Content-type": "application/json",
    "X-API-TOKEN": `${process.env.REACT_APP_API_TOKEN}`,
  },
});

const api = prod;

type ProcType = {
  performance: {
    [key: string]:
      | {
          route: string;
          name: string;
          type: string;
        }
      | any;
  };
  digitalPerformance: {
    [key: string]: {
      route: string;
      name: string;
      type: string;
    };
  };
  welcome: { [key: string]: { route: string; name: string; type: string } };
  support: { [key: string]: { route: string; name: string; type: string } };
  mix: {
    [key: string]:
      | {
          route: string;
          name: string;
          type: string;
        }
      | any;
  };
  mif: { [key: string]: { route: string; name: string; type: string } };
  eos: { [key: string]: { route: string; name: string; type: string } };
  connectivity: {
    [key: string]: { route: string; name: string; type: string };
  };
  market: { [key: string]: { route: string; name: string; type: string } };
  BR: { [key: string]: { route: string; name: string; type: string } };
  filters: { route: string; name: string; type: string };
  admin: { [key: string]: { route: string; name: string; type: string } };
  dashboard: { route: string; name: string; type: string };
};

export const proc: ProcType = {
  performance: {
    sales: {
      type: "POST",
      route: "kpis/compute/PROC_1_1",
      name: "sales",
    },
    equipments: {
      type: "POST",
      route: "kpis/compute/PROC_1_3",
      name: "equipments",
    },
    country: {
      sales: {
        type: "POST",
        route: "kpis/compute/PROC_1_2",
        name: "countrySales",
      },
      equipments: {
        type: "POST",
        route: "kpis/compute/PROC_1_4",
        name: "countryEquipments",
      },
    },
  },
  support: {
    standard: {
      type: "POST",
      route: "kpis/compute/PROC_2_1",
      name: "standard",
    },
    promotion: {
      type: "POST",
      route: "kpis/compute/PROC_2_2",
      name: "promotion",
    },
    bids: {
      type: "POST",
      route: "kpis/compute/PROC_2_3",
      name: "bids",
    },
    top: {
      type: "POST",
      route: "kpis/compute/PROC_2_4",
      name: "top",
    },
  },
  mix: {
    family: {
      type: "POST",
      route: "kpis/compute/PROC_3_1",
      name: "family",
    },
    total: {
      type: "POST",
      route: "kpis/compute/PROC_3_2",
      name: "total",
    },
    top: {
      type: "POST",
      route: "kpis/compute/PROC_3_3",
      name: "product",
    },
    toner: {
      type: "POST",
      route: "kpis/compute/PROC_3_4",
      name: "toner",
    },
    country: {
      product: {
        type: "POST",
        route: "kpis/compute/PROC_3_5",
        name: "countryProduct",
      },
      toner: {
        type: "POST",
        route: "kpis/compute/PROC_3_6",
        name: "countryToner",
      },
    },
  },
  market: {
    segment: {
      type: "POST",
      route: "kpis/compute/PROC_8_1",
      name: "segment",
    },
    color: {
      type: "POST",
      route: "kpis/compute/PROC_8_2",
      name: "color",
    },
    prod: {
      type: "POST",
      route: "kpis/compute/PROC_8_3",
      name: "prod",
    },
  },
  mif: {
    base: {
      type: "POST",
      route: "kpis/compute/PROC_6_1",
      name: "base",
    },
    asp: {
      type: "POST",
      route: "kpis/compute/PROC_6_2",
      name: "asp",
    },
    trends: {
      type: "POST",
      route: "kpis/compute/PROC_6_4",
      name: "trends",
    },
    xpps: {
      type: "POST",
      route: "kpis/compute/PROC_6_3",
      name: "xpps",
    },
    versus: {
      type: "POST",
      route: "kpis/compute/PROC_6_5",
      name: "versus",
    },
    export: {
      type: "GET",
      route: "mif/export",
      name: "export",
    },
  },
  eos: {
    values: {
      type: "POST",
      route: "kpis/compute/PROC_7_1",
      name: "eos",
    },
    percent: {
      type: "POST",
      route: "kpis/compute/PROC_7_2",
      name: "percent",
    },
    filters: {
      type: "GET",
      route: "eos/filters",
      name: "filters",
    },
    export: {
      type: "GET",
      route: "eos/export",
      name: "export",
    },
  },
  connectivity: {
    values: {
      type: "GET",
      route: "kpis/compute/PROC_10_1",
      name: "connectivity",
    },
    percent: {
      type: "POST",
      route: "kpis/compute/PROC_10_2",
      name: "connectivityPercent",
    },
    filters: {
      type: "GET",
      route: "connectivity/filters",
      name: "filters",
    },
    export: {
      type: "GET",
      route: "connectivity/export",
      name: "export",
    },
  },
  BR: {
    collection: {
      type: "GET",
      route: "business-review",
      name: "collection",
    },
    getPDF: {
      type: "POST",
      route: "business-review/download",
      name: "getPDF",
    },
    filters: {
      type: "GET",
      route: "business-review/filters",
      name: "filters",
    },
  },
  welcome: {
    picture: {
      type: "GET",
      route: "welcome-message/director/picture",
      name: "picture",
    },
    signature: {
      type: "GET",
      route: "welcome-message/director/signature",
      name: "signature",
    },
  },
  filters: {
    type: "GET",
    route: "kpis/filters",
    name: "filters",
  },
  admin: {
    requestPeriod: {
      type: "GET",
      route: "business-review/retention-period",
      name: "request",
    },
    updatePeriod: {
      type: "PATCH",
      route: "business-review/retention-period",
      name: "update",
    },
    importMessage: {
      type: "POST",
      route: "welcome-message/import",
      name: "import",
    },
    exportMessage: {
      type: "GET",
      route: "welcome-message/export",
      name: "export",
    },
  },
  digitalPerformance: {
    revenue: {
      type: "POST",
      route: "kpis/compute/PROC_9_1",
      name: "revenue",
    },
    licenses: {
      type: "POST",
      route: "kpis/compute/PROC_9_2",
      name: "licenses",
    },
    topsApps: {
      type: "POST",
      route: "kpis/compute/PROC_9_3",
      name: "topsApps",
    },
    topsSoftwares: {
      type: "POST",
      route: "kpis/compute/PROC_9_4",
      name: "topsSoftwares",
    },
  },

  dashboard: {
    type: "GET",
    route: "kpis/dashboard",
    name: "dashboard",
  },
};

export default api;
