import { styled } from "theme/theme";

export const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
`;

export const Container = styled.td`
  width: 654px;
  display: flex;
  margin-left: 14px;
`;

export const Table = styled.table`
  width: 654px;
  text-align: center;
  & tr:nth-child(odd) {
    background: #ebebeb;
  }
  & tr:nth-child(1) {
    background: white;
  }
  & tr:nth-child(2) {
    border: 1px solid #ebebeb;
    border-top: none;
    & th:nth-child(2) {
      border-right: 1px solid #ebebeb;
    }
    & th:nth-child(3) {
      border-left: 1px solid #ebebeb;
    }
  }
  & tr div {
    width: 10px;
    height: 80px;
    background: white;
    border-right: 1px solid #ebebeb;
    margin-right: -14px;
  }
  & tr:first-child div {
    height: 50px;
    margin-right: -4px;
  }
  & th[scope="col"] {
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: ${(props) => props.theme.color.info};
    width: 100px;
    padding: 20px;
    :nth-of-type(4) {
      width: 120px;
    }
  }
  & th[scope="colgroup"] {
    border: 1px solid #ebebeb;
    border-bottom: none;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: ${(props) => props.theme.color.info};
    :last-of-type {
      border-left: none;
    }
  }
  & tr[itemScope] div {
    height: 44px;
    border-left: 1px solid #ebebeb;
  }
  & tr[itemScope]:nth-child(odd) {
    & div {
      background: #ebebeb;
    }
  }
  & tr[itemScope]:last-child {
    & td {
      border-bottom: 1px solid #ebebeb;
    }
    & div {
      border-bottom: none;
    }
    & th[scope="row"] {
      border-bottom: none;
    }
  }
  & th[scope="row"] {
    text-align: left;
    font-size: 12px;
    padding-left: 16px;
    border-right: 1px solid #ebebeb;
    span {
      font-size: 12px;
      display: block;
      font-weight: 400;
    }
  }
  & td:last-child {
    border-right: 1px solid #ebebeb;
  }
`;
