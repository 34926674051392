import React, { FC, useMemo, useContext, useState, useEffect } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { KpiProps } from "types/kpi";
import { AppContext } from "providers";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

import { useFormatter } from "hooks";
import * as S from "./style";

type ChartProps = {
  UNIT?: string;
  DIRECTION?: string;
  PERIOD?: string | null;
  EVOL?: number;
  YEAR: number;
  REVENUE_N1?: string;
  REVENUE_N1_PERCENT?: string;
  REVENUE_N?: string;
  REVENUE_N_PERCENT?: string;
  TOTAL?: React.ReactText;
  TYPE?: string;
  lost?: React.ReactText;
  data?: Record<string, unknown>[];
};
type ChartFormattedProps = {
  YEAR: number;
  DETAILS: {
    NAME: string;
    VALUE: number;
    TOTAL: number;
    UNIT?: string;
  }[];
  TOTAL?: number;
  UNIT?: string;
  DIRECTION?: string;
  EVOLUTION?: number;
};

type BarChartsTopsProps = {
  LABEL: string;
  VALUE: string;
  RANK: string;
};
type BarChartsTopsFormattedProps = {
  name: string;
  value: number;
  rank: number;
};

const COLORS: { name: string; color: string }[] = [
  {
    name: "Apps",
    color: "#fecc7a",
  },
  {
    name: "Software",
    color: "#74bae7",
  },
];
const formattedTopsData = (value: BarChartsTopsProps[]) => {
  return value
    .reduce(
      (acc: BarChartsTopsFormattedProps[], element: BarChartsTopsProps) => {
        acc.push({
          value: Math.round(Number(element.VALUE)),
          name: element.LABEL,
          rank: Number(element.RANK),
        });
        return acc;
      },
      []
    )
    .sort(
      (a: BarChartsTopsFormattedProps, b: BarChartsTopsFormattedProps) =>
        a.rank - b.rank
    );
};

const formatValueData = (data: ChartProps[], type: "REVENUE" | "LICENSES") => {
  // data are update every 1st of February
  const todayIsBeforeFebruary = new Date().getMonth() < 1;
  const previousYearRevenue: ChartFormattedProps = {
    YEAR: todayIsBeforeFebruary
      ? new Date().getFullYear() - 2
      : new Date().getFullYear() - 1,
    DETAILS: [],
  };
  const currentYearRevenue: ChartFormattedProps = {
    YEAR: todayIsBeforeFebruary
      ? new Date().getFullYear() - 1
      : new Date().getFullYear(),
    DETAILS: [],
  };
  if (data) {
    data.forEach((val: any) => {
      const revenue = ({ YEAR }: { YEAR: string }) => {
        const totalData = `${type}_${YEAR}`;
        return typeof val[totalData] === "string"
          ? Math.round(Number(val[totalData] as string))
          : 0;
      };

      if (val.TYPE === "TOTAL") {
        previousYearRevenue.TOTAL = revenue({ YEAR: "N1" });
        previousYearRevenue.UNIT = val.UNIT;

        currentYearRevenue.TOTAL = revenue({ YEAR: "N" });
        currentYearRevenue.UNIT = val.UNIT;
        currentYearRevenue.EVOLUTION = Number(val.EVOL);
        currentYearRevenue.DIRECTION = val.DIRECTION;
      }
      if (val.TYPE === "APPS") {
        previousYearRevenue.DETAILS.push({
          NAME: "Apps",
          VALUE: revenue({ YEAR: "N1_PERCENT" }),
          TOTAL: revenue({ YEAR: "N1" }),
          UNIT: val.UNIT,
        });
        currentYearRevenue.DETAILS.push({
          NAME: "Apps",
          VALUE: revenue({ YEAR: "N_PERCENT" }),
          TOTAL: revenue({ YEAR: "N" }),
          UNIT: val.UNIT,
        });
      }
      if (val.TYPE === "SOFT") {
        previousYearRevenue.DETAILS.push({
          NAME: "Software",
          VALUE: revenue({ YEAR: "N1_PERCENT" }),
          TOTAL: revenue({ YEAR: "N1" }),
          UNIT: val.UNIT,
        });
        currentYearRevenue.DETAILS.push({
          NAME: "Software",
          VALUE: revenue({ YEAR: "N_PERCENT" }),
          TOTAL: revenue({ YEAR: "N" }),
          UNIT: val.UNIT,
        });
      }
    });
  }
  return [previousYearRevenue, currentYearRevenue];
};

type BarLineProps = {
  bar: BarChartsTopsFormattedProps;
  widthPercent: number;
};

const BarLine: FC<BarLineProps> = ({ bar, widthPercent }: BarLineProps) => {
  const { formatValue } = useFormatter();
  const { t } = useTranslation();
  const hasTop = !!bar.value && !!bar.name;
  return (
    <>
      {hasTop && (
        <S.BarLine>
          {bar.name}
          <S.BarContent>
            <S.Bar width={widthPercent} className="bar">
              <S.BarUnit>
                {formatValue(bar.value)}{" "}
                {t("kpis.digitalPerformance.licenses.titleSingularPlural")}
              </S.BarUnit>
            </S.Bar>
          </S.BarContent>
        </S.BarLine>
      )}
    </>
  );
};

const DigitalPerformance: FC = () => {
  const { t } = useTranslation();
  const { kpi }: KpiProps = useContext(AppContext);
  const { partner, config } = useContext(AppContext);
  const { formatValue } = useFormatter();
  const [topSegment, setTopSegment] = useState("APPS");
  const [topOneAppsValue, setTopOneAppsValue] = useState<number>(0);
  const [topOneSoftwaresValue, setTopOneSoftwaresValue] = useState<number>(0);

  const enabledDigitalPerformance =
    config?.user?.digitalPerformance || partner?.config?.digitalPerformance;

  const revenueDataChart: ChartFormattedProps[] = useMemo(() => {
    if (kpi.revenue) {
      return formatValueData(kpi.revenue.data, "REVENUE").sort(
        (a: ChartProps, b: ChartProps) => b.YEAR - a.YEAR
      );
    }
    return [];
  }, [kpi]);

  const licensesDataChart: ChartFormattedProps[] = useMemo(() => {
    if (kpi.licenses) {
      return formatValueData(kpi.licenses.data, "LICENSES").sort(
        (a: ChartProps, b: ChartProps) => b.YEAR - a.YEAR
      );
    }
    return [];
  }, [kpi]);

  const topsFiveAppsBarChart: BarChartsTopsFormattedProps[] = useMemo(() => {
    if (kpi.topsApps?.data) {
      return formattedTopsData(kpi.topsApps.data);
    }
    return [];
  }, [kpi]);
  const topsFiveSoftwaresBarChart: BarChartsTopsFormattedProps[] = useMemo(() => {
    if (kpi.topsSoftwares?.data) {
      return formattedTopsData(kpi.topsSoftwares.data);
    }
    return [];
  }, [kpi]);

  useEffect(() => {
    if (topsFiveAppsBarChart) {
      const topOne = topsFiveAppsBarChart.find((top) => top.rank === 1);
      if (topOne) {
        setTopOneAppsValue(topOne.value);
      }
    }
    if (topsFiveSoftwaresBarChart) {
      const topOne = topsFiveSoftwaresBarChart.find((top) => top.rank === 1);
      if (topOne) {
        setTopOneSoftwaresValue(topOne.value);
      }
    }
  }, [topsFiveAppsBarChart, topsFiveSoftwaresBarChart]);

  const SOFTWARES = "SOFTWARES";
  const APPS = "APPS";

  const issuesList: string[] = t(
    "kpis.digitalPerformance.overlay.issues.list",
    {
      returnObjects: true,
    }
  );
  const advantagesList: string[] = t(
    "kpis.digitalPerformance.overlay.advantages.list",
    {
      returnObjects: true,
    }
  );
  return (
    <>
      {enabledDigitalPerformance && (
        <Row>
          <S.CustomColumn xxl={9} lg={12} md={24}>
            <S.ColTitle>
              {t("kpis.digitalPerformance.revenue.title")}
            </S.ColTitle>
            <S.DotLegend>
              <S.Dot fill={COLORS[0].color} />
              <S.LegendTitle>Apps</S.LegendTitle>
              <S.Dot fill={COLORS[1].color} />
              <S.LegendTitle>Software</S.LegendTitle>
            </S.DotLegend>

            <S.RowChart>
              {kpi?.revenue?.data &&
                revenueDataChart.map(
                  (data: ChartFormattedProps, index: number) => {
                    return (
                      <S.Chart key={index.toString()}>
                        <S.Square>
                          <S.Year>{data.YEAR}</S.Year>
                          <S.Value>
                            <S.Total>
                              {typeof data?.TOTAL === "number" &&
                                formatValue(data.TOTAL)}{" "}
                              {data.UNIT}
                            </S.Total>
                            {data.EVOLUTION !== undefined && (
                              <S.Percentage>
                                {Math.round(data.EVOLUTION) > 0 && "+"}
                                {`${Math.round(data.EVOLUTION)}%`}
                              </S.Percentage>
                            )}
                          </S.Value>
                        </S.Square>
                        <PieChart width={208} height={208}>
                          <Pie
                            data={data.DETAILS}
                            cx="50%"
                            cy="50%"
                            dataKey="VALUE"
                            nameKey="NAME"
                            outerRadius={100}
                            paddingAngle={0}
                          >
                            {data.DETAILS.map((entry, i) => {
                              const indexColor: number = COLORS.findIndex(
                                (color) => color.name === entry.NAME
                              );
                              return (
                                <Cell
                                  key={`cell-${i}`}
                                  fill={COLORS[indexColor].color}
                                />
                              );
                            })}
                          </Pie>
                          <Tooltip
                            cursor={false}
                            contentStyle={{
                              fontWeight: "bold",
                              borderRadius: 3,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            labelStyle={{ marginRight: 5 }}
                            labelFormatter={(name: any) => name.toLowerCase()}
                            formatter={(
                              value: any,
                              name: any,
                              props: any
                            ): (string | number)[] => {
                              // eslint-disable-next-line react/prop-types
                              const { TOTAL, UNIT } = props.payload;
                              return [
                                `${name}: ${Math.round(value)}% - ${formatValue(
                                  TOTAL
                                )} ${UNIT}`,
                                parseFloat(name),
                              ];
                            }}
                            viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
                          />
                        </PieChart>
                      </S.Chart>
                    );
                  }
                )}
            </S.RowChart>
          </S.CustomColumn>
          <Col xxl={9} lg={12} md={24}>
            <S.ColTitle>
              {t("kpis.digitalPerformance.licenses.title")}
            </S.ColTitle>
            <S.DotLegend>
              <S.Dot fill={COLORS[0].color} />
              <S.LegendTitle>Apps</S.LegendTitle>
              <S.Dot fill={COLORS[1].color} />
              <S.LegendTitle>Software</S.LegendTitle>
            </S.DotLegend>

            <S.RowChart>
              {kpi?.licenses?.data &&
                licensesDataChart.map(
                  (data: ChartFormattedProps, index: number) => {
                    return (
                      <S.Chart key={index.toString()}>
                        <S.Square>
                          <S.Year>{data.YEAR}</S.Year>
                          <S.Value>
                            <S.Total>
                              {typeof data?.TOTAL === "number" &&
                                formatValue(data.TOTAL)}{" "}
                              {t(
                                "kpis.digitalPerformance.licenses.titleSingularPlural"
                              ).toUpperCase()}
                            </S.Total>
                            {data.EVOLUTION !== undefined && (
                              <S.Percentage>
                                {Math.round(data.EVOLUTION) > 0 && "+"}
                                {`${Math.round(data.EVOLUTION)}%`}
                              </S.Percentage>
                            )}
                          </S.Value>
                        </S.Square>
                        <PieChart width={208} height={208}>
                          <Pie
                            data={data.DETAILS}
                            cx="50%"
                            cy="50%"
                            dataKey="VALUE"
                            nameKey="NAME"
                            outerRadius={100}
                            paddingAngle={0}
                          >
                            {data.DETAILS.map((entry, i) => {
                              const indexColor: number = COLORS.findIndex(
                                (color) => color.name === entry.NAME
                              );
                              return (
                                <Cell
                                  key={`cell-${i}`}
                                  fill={COLORS[indexColor].color}
                                />
                              );
                            })}
                          </Pie>
                          <Tooltip
                            cursor={false}
                            contentStyle={{
                              fontWeight: "bold",
                              borderRadius: 3,
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                            labelStyle={{ marginRight: 5 }}
                            labelFormatter={(name: any) => name.toLowerCase()}
                            formatter={(
                              value: any,
                              name: any,
                              props: any
                            ): (string | number)[] => {
                              // eslint-disable-next-line react/prop-types
                              const { TOTAL } = props.payload;
                              return [
                                `${name}: ${Math.round(value)}% - ${formatValue(
                                  TOTAL
                                )} ${t(
                                  "kpis.digitalPerformance.licenses.titleSingularPlural"
                                ).toUpperCase()}`,
                                parseFloat(name),
                              ];
                            }}
                            viewBox={{ x: 0, y: 0, width: 400, height: 400 }}
                          />
                        </PieChart>
                      </S.Chart>
                    );
                  }
                )}
            </S.RowChart>
          </Col>
          <S.CustomColumnTops xxl={6} lg={12} md={24}>
            <S.ColTitle>{t("kpis.digitalPerformance.tops.title")}</S.ColTitle>

            <S.TopsHeaderGroup>
              <S.TopsTitle>
                {t("kpis.digitalPerformance.licenses.title")}
              </S.TopsTitle>
              <S.SelectGroup>
                <S.SelectSegment
                  active={topSegment === APPS}
                  onClick={() => setTopSegment(APPS)}
                >
                  {t("kpis.digitalPerformance.tops.apps")}
                </S.SelectSegment>
                <S.SelectSegment
                  secondary
                  active={topSegment === SOFTWARES}
                  onClick={() => setTopSegment(SOFTWARES)}
                >
                  {t("kpis.digitalPerformance.tops.software")}
                </S.SelectSegment>
              </S.SelectGroup>
            </S.TopsHeaderGroup>

            {kpi?.topsApps && topSegment === APPS && (
              <>
                {topsFiveAppsBarChart.map(
                  (bar: BarChartsTopsFormattedProps) => {
                    const widthPercent: number = Math.round(
                      (bar.value * 100) / topOneAppsValue
                    );
                    return <BarLine bar={bar} widthPercent={widthPercent} />;
                  }
                )}
              </>
            )}
            {kpi?.topsSoftwares && topSegment === SOFTWARES && (
              <>
                {topsFiveSoftwaresBarChart.map(
                  (bar: BarChartsTopsFormattedProps) => {
                    const widthPercent: number = Math.round(
                      (bar.value * 100) / topOneSoftwaresValue
                    );
                    return <BarLine bar={bar} widthPercent={widthPercent} />;
                  }
                )}
              </>
            )}
          </S.CustomColumnTops>
        </Row>
      )}
      {!enabledDigitalPerformance && (
        <Row>
          <Col xl={24}>
            <S.OverlayTitle>
              {t("kpis.digitalPerformance.overlay.title")}
            </S.OverlayTitle>

            <S.MainList>
              <S.OverlayListTitle level={3}>
                {t("kpis.digitalPerformance.overlay.issues.title")}
              </S.OverlayListTitle>
              <S.OverlayList>
                {issuesList.map((issue) => (
                  <S.OverlayListElement>{issue}</S.OverlayListElement>
                ))}
              </S.OverlayList>
            </S.MainList>
            <S.MainList>
              <S.OverlayListTitle level={3}>
                {t("kpis.digitalPerformance.overlay.advantages.title")}
              </S.OverlayListTitle>
              <S.OverlayList>
                {advantagesList.map((advantage) => (
                  <S.OverlayListElement>{advantage}</S.OverlayListElement>
                ))}
              </S.OverlayList>
            </S.MainList>

            <S.OverlayTitleBottom level={2}>
              {t("kpis.digitalPerformance.overlay.bottom")}
            </S.OverlayTitleBottom>
          </Col>
        </Row>
      )}
    </>
  );
};

export default DigitalPerformance;
