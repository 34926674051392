import React, { FC } from "react";

type LegendProps = {
  fill: string;
};

const Icon: FC<LegendProps> = ({ fill }: LegendProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47.353"
    height="14"
    viewBox="0 0 47.353 14"
  >
    <g stroke={fill} strokeWidth="2px">
      <path
        fill="none"
        strokeLinecap="round"
        d="M0 0L45.353 0"
        transform="translate(1 -.237) translate(0 7.795)"
      />
      <g fill="#fff" transform="translate(1 -.237) translate(16.247 .237)">
        <circle cx="7" cy="7" r="7" stroke="none" />
        <circle cx="7" cy="7" r="6" fill="none" />
      </g>
    </g>
  </svg>
);

export default Icon;
